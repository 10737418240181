import React, { useEffect, useContext } from "react";
// import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";

import { CheckAuthorization } from "shared/util/util";

import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

import "./EmployeeItem.css";
import "react-datepicker/dist/react-datepicker.css";
import TabPanel from "shared/components/UIElements/TabPanel";
import Shift from "./Shift";
import ShiftNew from "./ShiftNew";
// import TransferNewExcel from "./TransferNewExcel";
// import TransferRegion from "./TransferRegion";
// import TransferReport from "./TransferReport";

const ShiftManagement = (props) => {
  const auth = useContext(AuthContext);
  const currentUserId = auth.userId;
  const store = useContext(StoreContext);
  const Authorization = CheckAuthorization(auth.userId);

  //const [isLoading, setIsLoading] = React.useState(true);
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    //store.UpdateContracts();
    //store.UpdateAccountingUtils();
    // setIsLoading(false);
    if (currentUserId === "3" || currentUserId === "1000") {
      // store.UpdateContracts();
    }
  }, [currentUserId]);

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-personel--${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  if (!store.storeReady) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  // if (!Authorization.accessToTransfers) {
  //   return <div className="center">Transfer uygulaması erişiminiz bulunmuyor.</div>;
  // }

  // const Item = styled(Paper)(({ theme }) => ({
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   marginTop: 20,
  //   minHeight: 80,
  //   alignItems: "center",
  //   justifyContent: "center",
  //   color: theme.palette.text.secondary,
  // }));

  return (
    <React.Fragment>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Employee Tabs"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label="Gelecek Vardiyalar" {...a11yProps(0)} />
          <Tab label="Geçmiş Vardiyalar" {...a11yProps(1)} />
          {/* <Tab label="Yeni Vardiya" {...a11yProps(2)} /> */}
          {/* <Tab label="Excel'den Yeni Transfer" {...a11yProps(3)} /> */}
          {/* <Tab label="Raporlama" {...a11yProps(3)} /> */}
          {/* <Tab label="Bölgeler" {...a11yProps(5)} /> */}
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Shift future={true}></Shift>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Shift future={false}></Shift>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ShiftNew></ShiftNew>
        </TabPanel>
        {/* <TabPanel value={tabValue} index={3}>
          <TransferNewExcel></TransferNewExcel>
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <TransferReport></TransferReport>
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <TransferRegion></TransferRegion>
        </TabPanel> */}
      </Box>
    </React.Fragment>
  );
};

export default ShiftManagement;
