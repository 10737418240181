import React, { useCallback, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import UsersList from "components/user/UsersList";
import UserItem from "components/user/UserItem";
import NewUserItem from "components/user/NewUserItem";
import Button from "shared/components/FormElements/Button";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
import { AuthContext } from "shared/context/auth-context";

import { CheckAuthorization } from "shared/util/util";
import "./Admin.css";

const UsersWebsite = () => {
  const { t } = useTranslation();
  const store = useContext(StoreContext);
  const auth = useContext(AuthContext);

  const { usertypestoshow_website } = CheckAuthorization(auth.userId);

  const websiteuUserTypeValues = usertypestoshow_website.map((item) => item.value);
  const pageItems = store.users.filter((item) => websiteuUserTypeValues.includes(item.usertype));
  const ItemType = "user";

  const { isLoading, error, clearError } = useHttpClient();
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemShow, setSelectedItemShow] = useState(false);
  const [newItemShow, setNewItemShow] = useState(false);

  const ItemSelectHandler = useCallback((itemid) => {
    setSelectedItemShow(true);
    setNewItemShow(false);
    setSelectedItemId(itemid);
  }, []);

  const ItemUpdateHandler = useCallback((itemid) => {
    setNewItemShow(false);
    setSelectedItemId(itemid);
  }, []);

  const newItemHandler = useCallback(() => {
    setSelectedItemShow(false);
    setNewItemShow(true);
  }, []);

  const closeNewItemHandler = useCallback(() => {
    setNewItemShow(false);
    setSelectedItemShow(false);
    setSelectedItemId(null);
  }, []);

  if (isLoading || !store.storeReady) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div className="adminNavigationHeader">
        <Button danger size="small" onClick={newItemHandler}>
          {t(`${ItemType}.createNewItem`)}
        </Button>
      </div>
      <div className="adminpageContainer">
        {!isLoading && pageItems && (
          <div className="adminListContainer">
            <UsersList
              usertypes={usertypestoshow_website}
              items={pageItems}
              onItemSelect={ItemSelectHandler}
              selectedId={selectedItemId}
            />
          </div>
        )}
        {selectedItemId && selectedItemShow && (
          <div className="adminUpdateContainer">
            <UserItem
              usertypes={usertypestoshow_website}
              itemid={selectedItemId}
              onItemUpdate={ItemUpdateHandler}
              onClose={() => setSelectedItemShow(false)}
            ></UserItem>
          </div>
        )}
        {newItemShow && (
          <div className="adminUpdateContainer">
            <NewUserItem
              usertypes={usertypestoshow_website}
              gotoItem={ItemSelectHandler}
              onClose={closeNewItemHandler}
            ></NewUserItem>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default UsersWebsite;
