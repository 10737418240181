import React, { useEffect, useContext } from "react";
// import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

// #region Components

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
// #endregion Components

// #region Utils
import { CheckAuthorization } from "shared/util/util";
// #endregion Utils

// #region Hooks
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./AccountingItem.css";
import "react-datepicker/dist/react-datepicker.css";
import TabPanel from "shared/components/UIElements/TabPanel";

import Contract from "./Contract";
import ServiceTemplate from "./ServiceTemplate";
import EducationYear from "./EducationYear";
import Discount from "./Discount";
import Receipt from "./Receipt";
import Withdraw from "./Withdraw";
import Invoice from "./Invoice";
import Expense from "./Expense";
import Report from "./Report";
import VehiclePayment from "./VehiclePayment";

const Accounting = (props) => {
  const auth = useContext(AuthContext);
  const currentUserId = auth.userId;
  const store = useContext(StoreContext);
  const Authorization = CheckAuthorization(auth.userId);

  //const [isLoading, setIsLoading] = React.useState(true);
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    //store.UpdateContracts();
    //store.UpdateAccountingUtils();
    // setIsLoading(false);
    if (currentUserId === "3" || currentUserId === "1000") {
      // store.UpdateContracts();
    }
  }, [currentUserId]);

  if (
    !store.parentOptions ||
    !store.studentOptions ||
    !store.vehicleRoutes ||
    !store.contracts ||
    !store.receipts ||
    !Authorization
  ) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!Authorization.accessToAccounting) {
    return <div className="center">Muhasebe uygulaması erişiminiz bulunmuyor.</div>;
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  // const Item = styled(Paper)(({ theme }) => ({
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   marginTop: 20,
  //   minHeight: 80,
  //   alignItems: "center",
  //   justifyContent: "center",
  //   color: theme.palette.text.secondary,
  // }));

  return (
    <React.Fragment>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Accounting Tabs"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label="Sözleşmeler" {...a11yProps(0)} />
          <Tab label="Tahsilatlar" {...a11yProps(1)} />
          <Tab label="Faturalar" {...a11yProps(2)} />
          <Tab label="İadeler" {...a11yProps(3)} />
          <Tab label="Araç Hakedişleri" {...a11yProps(4)} />
          <Tab label="Raporlar" {...a11yProps(5)} />
          <Tab label="Diğer Giderler" {...a11yProps(6)} />
          <Tab label="Hizmet Şablonları" {...a11yProps(7)} />
          <Tab label="Standart İndirimler" {...a11yProps(8)} />
          <Tab label="Eğitim-Öğretim Dönemleri" {...a11yProps(9)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Contract></Contract>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Receipt></Receipt>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Invoice></Invoice>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <Withdraw></Withdraw>
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <VehiclePayment></VehiclePayment>
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <Report></Report>
        </TabPanel>
        <TabPanel value={tabValue} index={6}>
          <Expense></Expense>
        </TabPanel>
        <TabPanel value={tabValue} index={7}>
          <ServiceTemplate></ServiceTemplate>
        </TabPanel>
        <TabPanel value={tabValue} index={8}>
          <Discount></Discount>
        </TabPanel>
        <TabPanel value={tabValue} index={9}>
          <EducationYear></EducationYear>
        </TabPanel>
      </Box>

      {/* <Tabs value={tabValue} onChange={handleTabChange} orientation="vertical" aria-label="Accounting Tabs">
          <Tab label=""  />
          <Tab label=""  />
          <Tab label="" />
          <Tab label="Raporlar"  />
        </Tabs>
        <TabPanel value={tabValue} index={0}>



      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        Item Three
      </TabPanel> */}
    </React.Fragment>
  );
};

export default Accounting;
