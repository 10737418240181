import React, { useState, useContext, useCallback } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

import { StoreContext } from "shared/context/store-context";
import TabPanel from "shared/components/UIElements/TabPanel";

import ExpenseNew from "./ExpenseNew";
import ExpenseList from "./ExpenseList";
import ExpenseItem from "./ExpenseItem";

import "./AccountingItem.css";

const Expense = (props) => {
  const store = useContext(StoreContext);
  const pageItems = store.expenses;
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemShow, setSelectedItemShow] = useState(false);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const ItemSelectHandler = useCallback((itemid) => {
    setSelectedItemShow(true);
    setSelectedItemId(itemid);
  }, []);

  const ItemUpdateHandler = useCallback((itemid) => {
    setSelectedItemId(itemid);
  }, []);

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `expense-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  return (
    <React.Fragment>
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Giderler" {...a11yProps(0)} />
          <Tab label="Yeni Gider" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <div className="adminpageContainer">
          {pageItems && (
            <div className="adminListContainer">
              <ExpenseList items={pageItems} onItemSelect={ItemSelectHandler} />
            </div>
          )}
          {selectedItemId && selectedItemShow && (
            <div className="adminUpdateContainer">
              <ExpenseItem
                itemid={selectedItemId}
                onItemUpdate={ItemUpdateHandler}
                onClose={() => setSelectedItemShow(false)}
              ></ExpenseItem>
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ExpenseNew></ExpenseNew>
      </TabPanel>
    </React.Fragment>
  );
};

export default Expense;
