import React, { useContext, useState, Suspense } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Dropdown } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import "flag-icon-css/css/flag-icons.min.css";

import MainHeader from "./MainHeader";
import NavLinks from "./NavLinks";
import SideDrawer from "./SideDrawer";
import Backdrop from "shared/components/UIElements/Backdrop";
import Button from "shared/components/FormElements/Button";
import "./MainNavigation.css";
import { languages } from "shared/util/types";
import { AuthContext } from "shared/context/auth-context";

const MainNavigation = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  return (
    <Suspense fallback="loading">
      <React.Fragment>
        {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
        <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
          <nav className="main-navigation__drawer-nav">{auth.userLoaded && <NavLinks />}</nav>
        </SideDrawer>

        <MainHeader>
          <button className="main-navigation__menu-btn" onClick={openDrawerHandler}>
            <span />
            <span />
            <span />
          </button>
          <h3 className="main-navigation__title">
            <Link to="/">{t("leftTopTitle")}</Link>
          </h3>
          <nav className="main-navigation__header-nav">
            <NavLinks />
          </nav>

          <div className="language-area">
            <div className="header-top-right">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {t("settings")} <FaUserCircle className="user-circle" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {auth.userName}
                  {/* <Button>{t("settings")}</Button> */}

                  <Button onClick={auth.logout}>{t("logout")}</Button>

                  {languages.map(({ code, name, country_code }) => (
                    <Dropdown.Item
                      eventKey={name}
                      key={country_code}
                      to="/"
                      onClick={() => {
                        i18next.changeLanguage(code);
                        localStorage.setItem("i18nextLng", code);
                        auth.UpdateTheme(code);
                      }}
                    >
                      <span className={`flag-icon flag-icon-${country_code}`} style={{ marginBottom: "15px" }}></span>{" "}
                      {name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </MainHeader>
      </React.Fragment>
    </Suspense>
  );
};

export default MainNavigation;
