import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AuthContext } from "shared/context/auth-context";
import "./NavLinks.css";

const NavLinks = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);

  const [showAccounting, setShowAccounting] = useState(true);
  const [showAnalysis, setShowAnalysis] = useState(true);
  const [showVehicleRoutes, setShowVehicleRoutes] = useState(true);
  const [showIkarus, setShowIkarus] = useState(true);
  const [showShifts, setShowShifts] = useState(false);
  const [showStops, setShowStops] = useState(false);
  const [showStudents, setShowStudents] = useState(true);
  const [showNotifications, setShowNotifications] = useState(true);
  const [showTransfers, setShowTransfers] = useState(true);
  const [showLive, setShowLive] = useState(true);
  // eslint-disable-next-line
  const [showUsersWebsite, setShowUsersWebsite] = useState(true); // Kontrol yok
  const [showStandardPersonelServices, setShowStandardPersonelServices] = useState(false);
  const [showShiftPersonelServices, setShowShiftPersonelServices] = useState(false);

  let studentsLinkHeader = "...";
  let schoolsLinkHeader = "...";

  if (auth.schoolType === "1") {
    studentsLinkHeader = t("students");
    schoolsLinkHeader = t("schools");
  } else if (auth.schoolType === "2") {
    studentsLinkHeader = t("employeeAddresses");
    schoolsLinkHeader = t("clients");
  }

  if (auth.schoolType === "1") {
  } else if (auth.schoolType === "2") {
  }

  useEffect(() => {
    if (auth?.school === "659395e1e75c530018b90a55" || auth.school === "66f55187b9b71500155143e7") {
      //Burasi kullanici companyisine gore olacak Veri tabanindan cekilecek
      setShowAccounting(false);
      setShowAnalysis(false);
      setShowIkarus(false);
      setShowShifts(true);
      setShowStops(true);
      setShowStudents(false);
      setShowNotifications(false);
      setShowTransfers(false);
      setShowStandardPersonelServices(true);
      setShowShiftPersonelServices(true);
      setShowVehicleRoutes(false);
      setShowLive(false);
    }
    if (auth?.school === "66078db18bf1bd0015add40c") {
      //Burasi kullanici companyisine gore olacak Veri tabanindan cekilecek
      setShowAccounting(false);
      setShowAnalysis(false);
      setShowIkarus(false);
      setShowShifts(false);
      setShowStops(false);
      setShowStudents(false);
      setShowNotifications(false);
      setShowTransfers(false);
      setShowStandardPersonelServices(true);
      setShowShiftPersonelServices(false);
      setShowVehicleRoutes(false);
      setShowLive(false);
    }
  }, [auth.school]);

  return (
    <ul className="nav-links">
      {auth.isLoggedIn && (
        <li>
          <NavLink to="/users" exact>
            {t("users")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && showStops && (
        <li>
          <NavLink to="/stops" exact>
            {t("stops")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && showStudents && (
        <li>
          <NavLink to="/students" exact>
            {studentsLinkHeader}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && showNotifications && (
        <li>
          <NavLink to="/notifications" exact>
            {t("notifications")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && showVehicleRoutes && (
        <li>
          <NavLink to="/vehicleroutes" exact>
            {t("vehicleRoutes")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && showStandardPersonelServices && (
        <li>
          <NavLink to="/standardpersonelservices" exact>
            {t("standardServices")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && showShiftPersonelServices && (
        <li>
          <NavLink to="/shiftpersonelservices" exact>
            {t("shiftServices")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && showTransfers && (
        <li>
          <NavLink to="/transfermanagement" exact>
            {t("transfers")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && showIkarus && (
        <li>
          <NavLink to="/ikarus" exact>
            {t("ikarus")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && showShifts && (
        <li>
          <NavLink to="/shifts" exact>
            {t("shifts")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && showShiftPersonelServices && (
        <li>
          <NavLink to="/shiftmanagement" exact>
            {t("shiftmanagement")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && showLive && (
        <li>
          <NavLink to="/live" exact>
            {t("watchVehicle")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && showAccounting && (
        <li>
          <NavLink to="/accounting" exact>
            {t("accounting")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && showAnalysis && (
        <li>
          <NavLink to="/analysis" exact>
            {t("analysis")}
          </NavLink>
        </li>
      )}

      {auth.isLoggedIn && (
        <li>
          <NavLink to="/schools">{t(schoolsLinkHeader)}</NavLink>
        </li>
      )}

      {auth.isLoggedIn && showUsersWebsite && (
        <li>
          <NavLink to="/userswebsite" exact>
            {t("userswebsite")}
          </NavLink>
        </li>
      )}

      {!auth.isLoggedIn && (
        <li>
          <NavLink to="/login">{t("login")}</NavLink>
        </li>
      )}
      {auth.isLoggedIn && (
        <li>
          <button onClick={auth.logout}>{t("logout")}</button>
        </li>
      )}
    </ul>
  );
};

export default NavLinks;
