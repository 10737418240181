import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import Marker from "shared/components/UIElements/Marker";
import DatePicker from "shared/components/FormElements/DatePicker";
import { formatDate } from "shared/util/time";
import TransferRouteItem from "./TransferRouteItem";
import ShiftAnalyzer from "./shiftanalyzer/ShiftAnalyzer";

// #endregion Components
// #region Utils
import { transfertypes, transferStatus, days, days_en } from "shared/util/types";
import { VALIDATOR_REQUIRE, VALIDATOR_TIME } from "shared/util/validators";
import { parseComplexDate, getShiftDate } from "shared/util/util";
import { GetStartStopTime, CheckTimeString } from "shared/util/time";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./Item.css";
import { set } from "lodash";

const ShiftItem = (props) => {
  const { t } = useTranslation();
  const language = (i18next?.language || window?.localStorage?.i18nextLng || "en").substring(0, 2);
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const {
    users,
    shiftOptions,
    shifts,
    driverOptions,
    schoolOptions,
    UpdateShift,
    DeleteShift,
    shiftPersonelServiceOptions,
    shiftPersonelServices,
    userOptions,
    UpdatePersonelService,
    shiftUserOptions,
  } = useContext(StoreContext);

  // #region states
  const [currentShiftServicesGETON, setCurrentShiftServicesGETON] = useState([]);
  const [currentShiftServicesGETOFF, setCurrentShiftServicesGETOFF] = useState([]);

  const [currentShiftServicesGETON_Options, setCurrentShiftServicesGETON_Options] = useState([]);
  const [currentShiftServicesGETOFF_Options, setCurrentShiftServicesGETOFF_Options] = useState([]);

  const [currentPersonels, setCurrentPersonels] = useState([]);

  const [currentVehicleRoutesDetailed, setCurrentVehicleRoutesDetailed] = useState([]);
  const [vehicleRouteOptions, setVehicleRouteOptions] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showNewRouteAddition, setShowNewRouteAddition] = useState(false);

  const [itemstate, setItemState] = useState();
  const [currentShift, setCurrentShift] = useState();
  const [currentSmses, setCurrentSmses] = useState([]);

  const [shiftDate, setShiftDate] = useState(getShiftDate("---"));
  const [shiftDisplayName, setShiftDisplayName] = useState("---");

  const [view, setView] = useState(1);
  const alertMessageRef = useRef("");

  const itemId = props.itemid;
  const currentTime = props.currentTime;
  const updateVehicleRoutes = store.UpdateVehicleRoutes;

  const ItemTypeTR = "Vardiya";
  const ItemType = "shift";
  const ItemEndPointType = "shifts";
  // #endregion

  // #region Standard Interface functions
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  // #endregion Standard Interface functions

  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: true },
      day: { value: "", isValid: true },
      starttime: { value: "", isValid: true },
      endtime: { value: "", isValid: true },
      users: { value: [], isValid: true },
      personelservices: { value: [], isValid: true },
      school: { value: "", isValid: true },
    },
    true
  );

  const [formStatePersonel, inputHandlerPersonel] = useForm(
    {
      personelUnique: { value: "", isValid: true },
    },
    true
  );

  const [formStateNewRoute, inputHandlerNewRoute] = useForm(
    {
      newvehicleroutename: { value: "", isValid: false },
      vehicleroutestarttime: { value: "", isValid: false },
      vehicleroutestoptime: { value: "", isValid: false },
      newvehicleroutedriver: { value: [], isValid: false },
      newvehiclerouteplate: { value: "", isValid: false },
      newvehicletransferregion: { value: "", isValid: false },
    },
    false
  );

  const addUniquePersonel = async () => {
    try {
      if (!currentShift.users.includes(formStatePersonel.inputs.personelUnique.value)) {
        const updatedShift = {
          ...currentShift,
          users: [...currentShift.users, formStatePersonel.inputs.personelUnique.value],
        };
        setCurrentShift(updatedShift);
        UpdateShift(updatedShift);
      }
    } catch (err) {}
  };

  const togglenewRouteAddition = () => {
    setShowNewRouteAddition(!showNewRouteAddition);
  };

  const addVehicleRoute = async () => {
    // if (
    //   !CheckTimeString(formStateNewRoute.inputs.vehicleroutestoptime.value) ||
    //   !CheckTimeString(formStateNewRoute.inputs.vehicleroutestarttime.value)
    // ) {
    //   alertMessageRef.current = "Saat bilgisini **:** formatında giriniz. Örnek: 07:00 ya da 18:35";
    //   setShowAlert(true);
    //   return;
    // }
    // //check driver array has at least 1 element
    // if (formStateNewRoute.inputs.newvehicleroutedriver.value.length < 1) {
    //   alertMessageRef.current = "Servis için en az 1 sürücü seçiniz.";
    //   setShowAlert(true);
    //   return;
    // }
    // togglenewRouteAddition();
    // try {
    //   const vehicleRouteData = await sendRequest(
    //     `${process.env.REACT_APP_BACKEND_URL}/vehicleroutes`,
    //     "POST",
    //     JSON.stringify({
    //       name: formStateNewRoute.inputs.newvehicleroutename.value,
    //       type: formState.inputs.type.value,
    //       school: formState.inputs.school.value,
    //       driver: formStateNewRoute.inputs.newvehicleroutedriver.value,
    //       plate: formStateNewRoute.inputs.newvehiclerouteplate.value,
    //       starttime: formStateNewRoute.inputs.vehicleroutestarttime.value,
    //       stoptime: formStateNewRoute.inputs.vehicleroutestoptime.value,
    //       student: [],
    //       status: 1,
    //       location: { lat: -1.1, lng: -1.1 },
    //       routelocations: [
    //         {
    //           student: "2",
    //           transfertime: "1",
    //           transferregion: formStateNewRoute.inputs.newvehicletransferregion.value,
    //         },
    //       ],
    //     }),
    //     {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + auth.token,
    //     }
    //   );
    //   const newVehicleRouteId = vehicleRouteData.vehicleRoute._id.toString();
    //   //update transfer with new vehicle route id
    //   const responseData = await sendRequest(
    //     `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
    //     "PATCH",
    //     JSON.stringify({
    //       vehicleRoutes: [...currentVehicleRoutes, newVehicleRouteId],
    //     }),
    //     {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + auth.token,
    //     }
    //   );
    //   UpdateShift(responseData[ItemType]);
    // } catch (err) {}
    // // updateVehicleRoutes();
    // await new Promise((resolve) => setTimeout(resolve, 7000));
    // setItemState(Date.now());
  };

  const RemovePersonel = (personelId) => {
    try {
      const newUsersIntheShift = currentShift.users.filter((personel) => personel !== personelId);
      const updatedShift = {
        ...currentShift,
        users: newUsersIntheShift,
      };
      setCurrentShift(updatedShift);
      UpdateShift(updatedShift);
    } catch (err) {}
  };

  const RemoveVehicleRoute = async (vehicleRouteId) => {
    try {
      //Update transfer with new vehicle route id
      // await sendRequest(
      //   `${process.env.REACT_APP_BACKEND_URL}/transfers/${itemId}`,
      //   "PATCH",
      //   JSON.stringify({
      //     vehicleRoutes: currentVehicleRoutes.filter((vehicleRoute) => vehicleRoute !== vehicleRouteId),
      //   }),
      //   {
      //     "Content-Type": "application/json",
      //     Authorization: "Bearer " + auth.token,
      //   }
      // );
      // //updateVehicleRoutes();
      // //updateStoreItems();
      // setItemState(Date.now());
    } catch (err) {}
  };

  const getPhoneNumberofStudent = (studentId) => {
    try {
      const foundRelation = store.relations.find((relation) => relation.student.toString() === studentId.toString());
      if (foundRelation) {
        return store.users.find((user) => user.id.toString() === foundRelation.parent.toString()).phonenumber;
      } else {
        return "";
      }
    } catch (err) {
      return "";
    }
  };

  const SendSMS = async (studentId) => {
    const message = document.getElementById("text" + studentId).value.trim();

    const foundRelation = store.relations.find((relation) => relation.student.toString() === studentId.toString());

    if (!foundRelation) {
      alert("Kişi için bildirim tanımlanmamış.");
      return;
    }

    const foundPhone = users.find((user) => user.id.toString() === foundRelation.parent.toString()).phonenumber;

    const user = foundRelation.parent.toString();
    const student = studentId.toString();
    const transfer = itemId.toString();
    const phone = foundPhone;
    const text = message;
    const date = formatDate(new Date());
    const status = 1;

    const smsData = { user, student, phone, text, date, status, transfer };

    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/dfhldfjhdrgda`, "POST", JSON.stringify(smsData), {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      });
    } catch (err) {}

    // await loadSMS();
  };

  const SendSMSToDriver = async (driver) => {
    const message = document.getElementById("text" + driver.id).value.trim();

    const foundPhone = driver.phonenumber;

    const user = driver.id.toString();
    const student = "619c64be5d123200169418c8";
    const transfer = itemId.toString();
    const phone = foundPhone;
    const text = message;
    const date = formatDate(new Date());
    const status = 1;

    const smsData = { user, student, phone, text, date, status, transfer };

    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/dfhldfjhdrgda`, "POST", JSON.stringify(smsData), {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      });
    } catch (err) {}

    // await loadSMS();
  };

  // const loadSMS = useCallback(async () => {
  //   try {
  //     const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/dfhldfjhdrgda/${itemId}`, "POST");
  //     setCurrentSmses(responseData.smses);
  //   } catch (err) {}
  // }, [sendRequest, itemId]);

  // #region Standard API calls

  const fetchItem = useCallback(async () => {
    try {
      const currentShift = shifts.find((shift) => shift.id === itemId);
      if (currentShift) {
        setCurrentShift(currentShift);
        setShiftDate(getShiftDate(currentShift));
        setShiftDisplayName(shiftOptions.find((option) => option.value === currentShift.id).label);

        const extractedServicesGETON = shiftPersonelServices.filter(
          (service) => service.shift === itemId && service.type === "100"
        );
        const extractedServicesGETOFF = shiftPersonelServices.filter(
          (service) => service.shift === itemId && service.type === "200"
        );

        setCurrentShiftServicesGETON(extractedServicesGETON);
        setCurrentShiftServicesGETOFF(extractedServicesGETOFF);

        setCurrentShiftServicesGETON_Options(
          extractedServicesGETON.map((service) => {
            return {
              value: service.id,
              label: service.name,
            };
          }) || []
        );

        setCurrentShiftServicesGETOFF_Options(
          extractedServicesGETOFF.map((service) => {
            return {
              value: service.id,
              label: service.name,
            };
          }) || []
        );
      }

      // loadSMS();

      //setItemState(Date.now());
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line
  }, [itemId, shiftPersonelServices, shifts, users]);

  useEffect(() => {
    fetchItem();
  }, [itemId, itemstate, currentTime, fetchItem]);

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`, "DELETE", null, {
        "Content-Type": "application/json",
      });
      setItemState(Date.now());
      //  updateStoreItems();
      setCurrentShift(null);
    } catch (err) {}
  };
  // #endregion Standard API calls

  useEffect(() => {}, [formState.inputs.personel]);

  //save all personel to a state
  const SaveAllPersonelToShiftAndPersonelServices = async () => {
    const finalizedUsers = [];

    // for (let i = 0; i < currentShift.users.length; i++) {
    //   const personelId = currentShift.users[i];
    //   const currentPersonel = users.find((user) => user.id === personelId);

    //   const currentPersonelTransferTime = document.getElementById("personeltransfertime" + personelId).value;
    //   const vehicleText = document.getElementById("personelservice" + personelId).textContent;
    //   const currentPersonelVehicleId = shiftPersonelServiceOptions.find((option) => option.label === vehicleText).value;

    //   finalizedUsers.push({
    //     personelId: currentPersonel.id,
    //     serviceId: currentPersonelVehicleId,
    //     transfertime: currentPersonelTransferTime,
    //   });
    // }

    // const tempCurrentVehicleRoutesDetailed = [...currentVehicleRoutesDetailed];
    // //add vehicleStudentsTimes to currentVehicleRoutesDetailed
    // for (let i = 0; i < tempCurrentVehicleRoutesDetailed.length; i++) {
    //   const currentTransferRegion = tempCurrentVehicleRoutesDetailed[i].routelocations[0].transferregion;
    //   tempCurrentVehicleRoutesDetailed[i].student = [];
    //   tempCurrentVehicleRoutesDetailed[i].routelocations = [
    //     { student: "2", transfertime: "1", transferregion: currentTransferRegion },
    //   ];
    //   for (let j = 0; j < vehicleStudentsTimes.length; j++) {
    //     if (tempCurrentVehicleRoutesDetailed[i].id === vehicleStudentsTimes[j].vehicleId) {
    //       tempCurrentVehicleRoutesDetailed[i].student.push(vehicleStudentsTimes[j].personelId);
    //       tempCurrentVehicleRoutesDetailed[i].routelocations.push({
    //         student: vehicleStudentsTimes[j].personelId,
    //         transfertime: vehicleStudentsTimes[j].transfertime,
    //         transferregion: currentTransferRegion,
    //       });
    //     }
    //   }

    //   try {
    //     await sendRequest(
    //       `${process.env.REACT_APP_BACKEND_URL}/vehicleroutes/${tempCurrentVehicleRoutesDetailed[i].id}`,
    //       "PATCH",
    //       JSON.stringify({
    //         student: tempCurrentVehicleRoutesDetailed[i].student,
    //         routelocations: tempCurrentVehicleRoutesDetailed[i].routelocations,
    //       }),
    //       {
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + auth.token,
    //       }
    //     );
    //   } catch (err) {}
    // }

    // setCurrentVehicleRoutesDetailed(tempCurrentVehicleRoutesDetailed);
  };

  const GetGETONTransferTimeofUser = (userId) => {
    const foundService = currentShiftServicesGETON.find((service) => service?.users?.includes(userId));
    if (foundService) {
      return foundService?.usersdetails?.find((user) => user.id === userId)?.transfertime || "";
    }
    return "";
  };

  const GetGETONSMSofUser = (userId) => {
    const foundService = GetGETONServiceOfUser(userId);
    if (foundService) {
      return foundService?.usersdetails?.find((user) => user.id === userId)?.smses || [];
    }
    return [];
  };

  const getUser = (userId) => {
    return users.find((user) => user.id === userId);
  };

  const GetGETONServiceOfUser = (userId) => {
    const foundService = currentShiftServicesGETON.find((service) => service?.users?.includes(userId));
    if (foundService) {
      return foundService;
    }
    return null;
  };

  const saveAllGETON = async () => {
    const finalizedUsers = [];

    for (let i = 0; i < currentShift.users.length; i++) {
      const personelId = currentShift.users[i];
      const currentPersonel = users.find((user) => user.id === personelId);
      const currentPersonelTransferTime = document.getElementById("personeltransfertime" + personelId)?.value;
      const vehicleText = document.getElementById("personelservice" + personelId).textContent;
      const currentServiceId = shiftPersonelServiceOptions.find((option) => option.label === vehicleText)?.value;
      const currentService = currentShiftServicesGETON.find((service) => service.id === currentServiceId);

      try {
        const infoToBeSent = {
          ...currentService,
          starttime: GetStartStopTime(currentService.starttime),
          stoptime: GetStartStopTime(currentService.stoptime),
          usersdetails: [
            ...currentService.usersdetails.filter((item) => item.user !== personelId),
            { user: personelId, transfertime: currentPersonelTransferTime || undefined },
          ],
        };

        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/personelservices/${currentService.id}`,
          "PATCH",
          JSON.stringify(infoToBeSent),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );

        if (responseData?.[ItemType]) {
          UpdatePersonelService(responseData[ItemType]);
          // setLoadedItem({ ...responseData[ItemType] });
          setItemState(Date.now());
        }
      } catch (err) {}
    }
  };

  const itemUpdateSubmitHandler = useCallback(
    async (event) => {
      event.preventDefault();

      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
          "PATCH",
          JSON.stringify({
            name: formState.inputs.name.value,
            day: formState.inputs.day.value,
            starttime: formState.inputs.starttime.value,
            endtime: formState.inputs.endtime.value,
            users: formState.inputs.users.value,
            personelservices: formState.inputs.personelservices.value,
            school: formState.inputs.school.value,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );

        if (responseData?.[ItemType]) {
          UpdateShift(responseData[ItemType]);
          setCurrentShift(responseData[ItemType]);
          setItemState(Date.now());
        }
      } catch (err) {}
    },
    [sendRequest, auth.token, formState, itemId]
  );

  const GotoView = (view) => {
    setView(view);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!currentShift && !error) {
    return (
      <div className="center">
        <Card>
          <h2>{ItemTypeTR} bulunamadı.</h2>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Emin misiniz?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              Hayır
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>{ItemTypeTR} silme işlemini onaylıyor musunuz? Bu işlem geri alınamaz.</p>
      </Modal>

      <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          {alertMessageRef.current}
        </MuiAlert>
      </Snackbar>

      {!isLoading && currentShift && (
        <div>
          <div className="header-buttons-container">
            <Button type="button" danger style={{ marginRight: "20px" }} onClick={props.onClose}>
              &#60; Geri
            </Button>

            {shiftDate + " " + shiftDisplayName}

            <div style={{ display: "flex", flexDirection: "row", columnGap: "20px" }}>
              <Button type="button" primary danger={view === 1} onClick={() => GotoView(1)}>
                Vardiya Bilgileri
              </Button>

              <Button type="button" danger={view === 2} primary onClick={() => GotoView(2)}>
                Vardiya Girişi
              </Button>

              <Button type="button" primary danger={view === 3} onClick={() => GotoView(3)}>
                Vardiya Çıkışı
              </Button>

              {/* <Button type="button" primary onClick={() => GotoView(3)}>
                Vardiya Çıkışı
              </Button> */}
              {/* 
              <Button type="button" primary onClick={() => GotoView(2)}>
                Personel Bilgileri
              </Button>

              <Button type="button" primary onClick={() => GotoView(3)}>
                Servis Bilgileri
              </Button> */}
            </div>
          </div>

          {view === 1 && (
            <form key={itemstate} className="item-form" onSubmit={itemUpdateSubmitHandler}>
              <CustomSelect
                options={schoolOptions}
                id="school"
                onInput={inputHandler}
                label={t("client")}
                initialValue={schoolOptions.find((option) => option.value === currentShift.school.toString())}
                initialValid={true}
                disabled={true}
              ></CustomSelect>

              <CustomSelect
                options={language === "en" ? days_en : days}
                id="day"
                onInput={inputHandler}
                label={t("day")}
                initialValue={days.find((day) => day.value.toString() === currentShift.day.toString())}
                initialValid={true}
                disabled={true}
              ></CustomSelect>
              <Input
                id="name"
                element="input"
                type="text"
                label={t("name")}
                validators={[VALIDATOR_REQUIRE()]}
                errorText={t("requireField", { item: t("name") })}
                initialValue={currentShift.name}
                initialValid={true}
                onInput={inputHandler}
                disabled={true}
              />

              <Input
                id="starttime"
                element="input"
                type="text"
                label={t("startTime")}
                validators={[VALIDATOR_TIME(formState.inputs.starttime.value)]}
                initialValue={currentShift.starttime}
                initialValid={true}
                errorText={t("invalidTimeField", { item: t("startTime") })}
                onInput={inputHandler}
                disabled={true}
              />
              <Input
                id="endtime"
                element="input"
                type="text"
                label={t("endTime")}
                validators={[VALIDATOR_TIME(formState.inputs.endtime.value)]}
                initialValue={currentShift.endtime}
                initialValid={true}
                errorText={t("invalidTimeField", { item: t("endTime") })}
                onInput={inputHandler}
                disabled={true}
              />

              <CustomSelect
                options={shiftUserOptions}
                id="users"
                isMulti={true}
                onInput={inputHandler}
                label="Vardiyadaki Kişiler"
                initialValue={shiftUserOptions.filter((option) => currentShift?.users?.includes(option.value))}
                initialValid={true}
              ></CustomSelect>

              <CustomSelect
                options={shiftPersonelServiceOptions}
                id="personelservices"
                isMulti={true}
                onInput={inputHandler}
                label="Vardiyaya Ayrılan Servisler"
                initialValue={shiftPersonelServiceOptions.filter((option) =>
                  currentShift?.personelservices?.includes(option.value)
                )}
                initialValid={true}
              ></CustomSelect>

              <div className="button-container">
                <Button type="submit" disabled={!formState.isValid}>
                  Güncelle
                </Button>

                <Button type="button" danger onClick={showDeleteWarningHandler}>
                  SİL
                </Button>
              </div>
            </form>
          )}

          {view === 2 && (
            <ShiftAnalyzer shift={currentShift} shiftDate={shiftDate} shiftDisplayName={shiftDisplayName} type="100" />
          )}

          {/* {view === 2 && (
            <div className="item-form">
              <CustomSelect
                options={userOptions}
                id="personelUnique"
                isMulti={false}
                label="Personel"
                initialValid={true}
                onInput={inputHandlerPersonel}
              ></CustomSelect>

              <Button type="button" danger onClick={addUniquePersonel.bind(this, "apply")}>
                Personel Ekle
              </Button>

              <TableContainer
                component={Paper}
                sx={{
                  minWidth: 650,
                  marginTop: "20px",
                  paddingBottom: "50px",
                  marginBottom: "20px",
                }}
              >
                <Table
                  sx={{
                    minWidth: 650,
                    width: "100%",
                    justifyContent: "center",
                    margin: "auto",
                    zIndex: -1,
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell width="5%"></TableCell>
                      <TableCell width="15%">
                        <b>Personel</b>
                      </TableCell>
                      <TableCell width="20%">
                        <b>Servis</b>
                      </TableCell>
                      <TableCell width="10%">
                        <b>Alınış Saati</b>
                      </TableCell>
                      <TableCell width="25%">
                        <b>Giden Mesajlar</b>
                      </TableCell>
                      <TableCell width="25%" align="right">
                        <b>Yeni Mesaj (HER MESAJ EN FAZLA 155 KARAKTER)</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentShift.users.map((personelId) => (
                      <TableRow
                        key={"PersonelRow" + personelId}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <Button type="button" danger onClick={() => RemovePersonel(personelId)}>
                            Sil
                          </Button>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {getUser(personelId).name} <br /> {getUser(personelId).phonenumber}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <CustomSelect
                            id={"personelservice" + personelId}
                            options={currentShiftServicesGETON_Options}
                            isMulti={false}
                            onInput={inputHandlerPersonel}
                            initialValid={false}
                            initialValue={currentShiftServicesGETON_Options.find(
                              (option) => option.value === GetGETONServiceOfUser(personelId)?.id
                            )}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Personel için servis seçiniz."
                          />
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <Input
                            id={"personeltransfertime" + personelId}
                            element="input"
                            type="text"
                            onInput={inputHandlerPersonel}
                            initialValid={true}
                            // initialValue={GetGETONTransferTimeofUser(personelId)}
                            initialValue="15.35"
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                          />
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {GetGETONSMSofUser(personelId).length > 0 ? (
                            <div>
                              {GetGETONSMSofUser(personelId).map((sms) => (
                                <div key={sms.id}>
                                  {sms.text}
                                  <br />
                                  <i>{sms.date}</i>
                                  <br />
                                  <br />
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>Mesaj Yok</div>
                          )}
                        </TableCell>

                        <TableCell scope="row" align="right">
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <textarea
                              style={{ margin: "16px" }}
                              key={"SMS" + personelId}
                              id={"text" + personelId.toString().trim()}
                              cols="30"
                              rows="7"
                              maxLength="155"
                              defaultValue={`${GetGETONServiceOfUser(personelId)?.date} tarihli ${
                                currentShift.name
                              } vardiyası için servis saatiniz yaklaşık 15:35'tir. İletişim için 05498003931.`}
                            ></textarea>
                            <div width="60px" style={{ marginRight: "16px" }}>
                              <Button type="button" danger onClick={() => SendSMS(personelId.toString().trim())}>
                                SMS Gönder
                              </Button>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {currentShift.users.length > 0 && (
                <div className="map-container">
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
                      language: "tr",
                      region: "tr",
                      libraries: ["drawing"],
                    }}
                    options={function (maps) {
                      return {
                        mapTypeControl: true,
                        mapTypeId: "satellite",
                        mapTypeControlOptions: {
                          mapTypeIds: ["satellite", "roadmap"],
                          style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                          position: maps.ControlPosition.TOP_LEFT,
                        },
                      };
                    }}
                    defaultCenter={{ lat: 36.8969, lng: 30.7133 }}
                    // center={
                    //   currentPersonels[0]
                    //     ? currentPersonels[0].location
                    //     : { lat: 36.8969, lng: 30.7133 }
                    // }
                    defaultZoom={11}
                  >
                    {currentShift.users.map((userId) => {
                      return (
                        <Marker
                          Icon={RoomIcon}
                          lat={getUser(userId).location.lat}
                          lng={getUser(userId).location.lng}
                          key={userId}
                          info={getUser(userId).name}
                        />
                      );
                    })}
                  </GoogleMapReact>
                </div>
              )}

              <Button disabled type="button" danger onClick={saveAllGETON.bind(this)}>
                Kaydet
              </Button>
            </div>
          )} */}
          {/* 
          {view === 3 && (
            <div className="item-form">
              <Button type="button" danger o onClick={togglenewRouteAddition}>
                {showNewRouteAddition ? "Rota Eklemeyi Kapat" : "Yeni Rota Ekle"}
              </Button>

              {showNewRouteAddition && (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <div className="groupContainer">
                    <Input
                      id="newvehicleroutename"
                      element="input"
                      type="text"
                      label="Şoför Ekranında Görünecek Servis İsmi"
                      validators={[VALIDATOR_REQUIRE()]}
                      errorText="Servis ismi boş bırakılamaz."
                      onInput={inputHandlerNewRoute}
                      // initialValue={GetStartStopTime(currentShift.stoptime)}
                      initialValid={false}
                    />

                    <CustomSelect
                      options={store.transferregionOptions}
                      id="newvehicletransferregion"
                      isMulti={false}
                      onInput={inputHandlerNewRoute}
                      label="Transfer Bölgesi"
                      initialValid={true}
                    ></CustomSelect>

                    <CustomSelect
                      options={store.driverOptions}
                      id="newvehicleroutedriver"
                      isMulti={true}
                      onInput={inputHandlerNewRoute}
                      label="Rotaya Yetkisi Olan Sürücüler"
                      errorText="En az 1 sürücü seçiniz."
                      // initialValue={store.driverOptions.filter((option) => currentShift.driver.includes(option.value))}
                      initialValid={false}
                      validators={[VALIDATOR_REQUIRE()]}
                    ></CustomSelect>

                    <Input
                      id="newvehiclerouteplate"
                      element="input"
                      type="text"
                      label="Servis plakası"
                      validators={[VALIDATOR_REQUIRE()]}
                      errorText="Servis plakasını giriniz."
                      onInput={inputHandlerNewRoute}
                      // initialValue={currentShift.plate}
                      initialValid={false}
                    />

                    <Input
                      id="vehicleroutestarttime"
                      element="input"
                      type="text"
                      label="Servis İzleme Başlama Saati"
                      validators={[VALIDATOR_REQUIRE()]}
                      errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                      onInput={inputHandlerNewRoute}
                      // initialValue={GetStartStopTime(currentShift.starttime)}
                      initialValid={false}
                    />
                    <Input
                      id="vehicleroutestoptime"
                      element="input"
                      type="text"
                      label="Servis İzleme Bitiş Saati"
                      validators={[VALIDATOR_REQUIRE()]}
                      errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                      onInput={inputHandlerNewRoute}
                      // initialValue={GetStartStopTime(currentShift.stoptime)}
                      initialValid={false}
                    />

                    <Button
                      type="button"
                      danger
                      onClick={addVehicleRoute.bind(this, "apply")}
                      disabled={!formStateNewRoute.isValid}
                    >
                      Operasyona Servis Ekle
                    </Button>
                  </div>
                </div>
              )}

              <div style={{ borderBottom: "2px solid red", width: "100%", marginTop: "30px" }}></div>

              <TableContainer
                component={Paper}
                sx={{
                  minWidth: 650,
                  marginTop: "20px",
                  paddingBottom: "50px",
                  marginBottom: "20px",
                }}
              >
                <Table
                  sx={{
                    minWidth: 650,
                    width: "100%",
                    justifyContent: "center",
                    margin: "auto",
                    zIndex: -1,
                  }}
                  aria-label="simple table"
                >
                  <TableBody>
                    {currentVehicleRoutesDetailed.map((vehicleRoute) => (
                      <React.Fragment key={"FragmentList" + vehicleRoute.id}>
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <TransferRouteItem
                              itemid={vehicleRoute.id}
                              onDelete={() => RemoveVehicleRoute(vehicleRoute.id)}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          key={"DriverRow" + vehicleRoute.driver[0].id}
                        >
                          <TableCell component="th" scope="row">
                            <b>Sürücü İletişim</b>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {vehicleRoute.driver[0].name} <br /> {vehicleRoute.driver[0].phonenumber}
                          </TableCell>

                          {/* <TableCell component="th" scope="row">
                          <Input
                            id={'driverfirstpersonetime' + driver.id}
                            element="input"
                            type="text"
                            onInput={inputHandlerPersonel}
                            initialValid={true}
                            initialValue={GetTransferTimeofStudent(personelId)}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                          />
                        </TableCell> 

                          <TableCell component="th" scope="row">
                            {currentSmses.filter(
                              (sms) => sms.user.id.toString() === vehicleRoute.driver[0].id.toString()
                            ).length > 0 ? (
                              <div>
                                {currentSmses
                                  .filter((sms) => sms.user.id.toString() === vehicleRoute.driver[0].id.toString())
                                  .map((sms) => (
                                    <div key={sms.id}>
                                      {sms.text}
                                      <br />
                                      <i>{sms.date}</i>
                                      <br />
                                      <br />
                                    </div>
                                  ))}
                              </div>
                            ) : (
                              <div>Mesaj Yok</div>
                            )}
                          </TableCell>

                          <TableCell scope="row" align="right" width="30%">
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <textarea
                                style={{ margin: "16px" }}
                                key={"SMS" + vehicleRoute.driver[0].id}
                                id={"text" + vehicleRoute.driver[0].id.toString().trim()}
                                cols="30"
                                rows="7"
                                maxLength="155"
                                defaultValue={`Yeni servis operasyonu.\nServis: ${vehicleRoute.name}\nTarih: ${
                                  currentShift.transferdate
                                }\nSaat: ${GetStartStopTime(vehicleRoute.starttime)}\nOperasyon Tipi: ${
                                  currentShift.type === 100 ? "Toplama" : "Dağıtma"
                                }`}
                              ></textarea>
                              <div width="60px">
                                <Button type="button" danger onClick={() => SendSMSToDriver(vehicleRoute.driver[0])}>
                                  SMS Gönder
                                </Button>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th" scope="row" colSpan={6}>
                  
                            <div style={{ borderBottom: "2px solid red", width: "100%" }}></div>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )} */}
        </div>
      )}
    </React.Fragment>
  );
};

export default ShiftItem;
