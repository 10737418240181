import React, { useState, useContext, useCallback } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

import CustomSelect from "shared/components/FormElements/Select";

import { CheckAuthorization, isWithdrawIntheSchool } from "shared/util/util";

import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

import WithdrawNew from "./WithdrawNew";
import WithdrawList from "./WithdrawList";
import WithdrawItem from "./WithdrawItem";

import { CURRENT_EDUCATION_YEAR_ID } from "shared/util/constants";

import "./AccountingItem.css";

import "react-datepicker/dist/react-datepicker.css";
import TabPanel from "shared/components/UIElements/TabPanel";

const Withdraw = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const [pageItems, setPageItems] = useState(store.withdraws);
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemShow, setSelectedItemShow] = useState(false);
  const [educationYear, setEducationYear] = useState(CURRENT_EDUCATION_YEAR_ID);

  const Authorization = CheckAuthorization(auth.userId);
  if (Authorization.isSchoolResponsible) {
    //pageItems = pageItems.filter(item => isWithdrawIntheSchool(item, Authorization.currentSchoolId, [...store.contracts]));
    const currentYearContracts = store.contracts.filter((item) => item.year === CURRENT_EDUCATION_YEAR_ID);
    const currentpageItems = store.withdraws.filter((item) =>
      isWithdrawIntheSchool(item, Authorization.currentSchoolId, [...currentYearContracts])
    );
    setPageItems(currentpageItems);
  }

  const yearChangeHandler = (id, value) => {
    setEducationYear(value);
    const currentYearContracts = store.contracts.filter((item) => item.year === value);
    const currentYearContractIds = currentYearContracts.map((item) => item.id);
    // let currentpageItems = [...store.receipts.filter((item) => item.year === value)];
    let currentpageItems = [];
    if (Authorization.isSchoolResponsible || !Authorization.superUsers.includes(auth.userId)) {
      currentpageItems = store.withdraws.filter((item) =>
        isWithdrawIntheSchool(item, Authorization.currentSchoolId, [...currentYearContracts])
      );
    } else {
      currentpageItems = store.withdraws.filter((item) => currentYearContractIds.includes(item.contract.toString()));
    }
    setPageItems(currentpageItems);

    // setEducationYear(value);
    // const currentYearContracts = store.contracts.filter((item) => item.year === value )
    // const currentpageItems =  store.withdraws.filter(item => isWithdrawIntheSchool(item, Authorization.currentSchoolId, [...currentYearContracts]));
    // setPageItems(currentpageItems);
  };

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const ItemSelectHandler = useCallback((itemid) => {
    // selectedItemId.current = itemid;
    setSelectedItemShow(true);
    setSelectedItemId(itemid);
  }, []);

  const ItemUpdateHandler = useCallback((itemid) => {
    setSelectedItemId(itemid);
  }, []);

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `withdraw-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  return (
    <React.Fragment>
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="İadeler" {...a11yProps(0)} />
          <Tab label="Yeni İade" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <div style={{ width: "300px" }}>
        <CustomSelect
          options={store.educationYearsOptions}
          id="year"
          onInput={(id, value) => yearChangeHandler(id, value)}
          label=""
          initialValid={true}
          initialValue={store.educationYearsOptions.find((item) => item.value.toString() === educationYear)}
        ></CustomSelect>
      </div>
      <TabPanel value={tabValue} index={0}>
        <div className="adminpageContainer">
          {pageItems && (
            <div className="adminListContainer">
              <WithdrawList items={pageItems} onItemSelect={ItemSelectHandler} />
            </div>
          )}
          {selectedItemId && selectedItemShow && (
            <div className="adminUpdateContainer">
              <WithdrawItem
                itemid={selectedItemId}
                onItemUpdate={ItemUpdateHandler}
                onClose={() => setSelectedItemShow(false)}
              ></WithdrawItem>
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <WithdrawNew></WithdrawNew>
      </TabPanel>
    </React.Fragment>
  );
};

export default Withdraw;
