import React from "react";
import {
  DataGrid,
  trTR,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import "./AccountingList.css";
import { trTR as coretrTR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { discountTypes } from "shared/util/types";

const DiscountList = (props) => {
  //const store = useContext(StoreContext);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    trTR,
    coretrTR,
    {
      GridCsvExportOptions: { fileName: "gh" },
    }
  );

  const columns = [
    { field: "name", headerName: "İndirim Adı", width: 300 },
    {
      field: "type",
      headerName: "İndirim Türü",
      width: 200,
      valueGetter: (params) => discountTypes.find((item) => item.value.toString() === params.row.type.toString()).label,
    },
    { field: "amount", headerName: "İndirim Miktarı", width: 200 },
  ];

  const OnSelectionChangeHandler = (event) => {
    props.onItemSelect(event[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="itemListContainer">
      <ThemeProvider theme={theme}>
        <DataGrid
          key={props.items}
          density="compact"
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={props.items}
          columns={columns}
          pageSize={100}
          getRowId={(r) => r.id}
          onSelectionModelChange={OnSelectionChangeHandler}
        />
      </ThemeProvider>
    </div>
  );
};

export default DiscountList;
