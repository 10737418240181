import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import { sortArrayByLabel } from "shared/util/string";

import "./Select.css";

const CustomSelect = (props) => {
  const [selection, setSelection] = useState(props.initialValue);

  useEffect(() => {
    if (props.initialValue) {
      handleChange(props.initialValue);
    }
    //eslint-disable-next-line
  }, []);

  //eslint-disable-next-line
  const handleChange = useCallback((selectedoption) => {
    if (!props.isMulti) {
      setSelection(selectedoption);
      props.onInput(props.id, selectedoption.value, true);

      if (props.fireChange) {
        props.fireChange(selectedoption.value);
      }
    } else {
      if (selectedoption) {
        // debugger;
        const sortedSelections = sortArrayByLabel(selectedoption);
        setSelection(sortedSelections);
        props.onInput(
          props.id,
          sortedSelections.map((item) => item.value),
          true
        );
        if (props.fireChange) {
          props.fireChange(selectedoption.map((item) => item.value));
        }
      } else {
        props.onInput(props.id, [], false);
        setSelection([]);
      }
    }
  });

  return (
    <div className="form-control">
      <label htmlFor={props.id}>{props.label}</label>

      <Select
        id={props.id}
        isDisabled={props.disabled}
        isSearchable={true}
        value={selection}
        isMulti={props.isMulti}
        onChange={handleChange}
        options={props.options}
        placeholder="Seçiniz"
      />
    </div>
  );
};

export default CustomSelect;
