import React, { useEffect, useState, useContext, useCallback } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// #region Components
import Button from "shared/components/FormElements/Button";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
// #endregion Components

// #region Hooks
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./AccountingItem.css";

const ReceiptItem = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);

  const [loadedItem, setLoadedItem] = useState();

  const contractId = props.itemid;
  const [receipts, setReceipts] = useState();
  const [totalPayed, setTotalPayed] = useState();
  const [calculatedInvoices, setCalculatedInvoices] = useState();

  const ItemTypeTR = "Fatura";
  const ItemType = "contract";
  const ItemEndPointType = "contracts";

  const { isLoading, error, sendRequest } = useHttpClient();

  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${contractId}`);
      setLoadedItem(responseData[ItemType]);

      const allreceipts = store.receipts.filter((item) => item.contract.toString() === contractId);
      setReceipts(allreceipts);

      //sum all receipts amount
      let totalPayed = 0;
      allreceipts.forEach((item) => {
        totalPayed += item.amount;
      });
      setTotalPayed(totalPayed);

      const totalAmount = responseData[ItemType].finalAmount;

      // const toBeInvoiced = totalAmount - totalPayed;
      const toBeInvoiced = totalAmount;

      const response = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/faturas/${contractId}`);

      const faturas = response.fatura;

      //get current month
      const months = ["Eylül", "Ekim", "Kasım", "Aralık", "Ocak", "Şubat", "Mart", "Nisan", "Mayıs"];

      let monthAmounts = [];

      for (let index = 0; index < months.length; index++) {
        if (months[index] === "Eylül") {
          monthAmounts.push(faturas.september);
        }
        if (months[index] === "Ekim") {
          monthAmounts.push(faturas.october);
        }
        if (months[index] === "Kasım") {
          monthAmounts.push(faturas.november);
        }
        if (months[index] === "Aralık") {
          monthAmounts.push(faturas.december);
        }
        if (months[index] === "Ocak") {
          monthAmounts.push(faturas.january);
        }
        if (months[index] === "Şubat") {
          monthAmounts.push(faturas.february);
        }
        if (months[index] === "Mart") {
          monthAmounts.push(faturas.march);
        }
        if (months[index] === "Nisan") {
          monthAmounts.push(faturas.april);
        }
        if (months[index] === "Mayıs") {
          monthAmounts.push(faturas.may);
        }
      }

      if (toBeInvoiced > 0) {
        let calculatedInvoices = [];
        for (let index = 0; index < months.length; index++) {
          const invoice = {
            month: months[index],
            // amount: faturas.find(fatura => fatura.)(toBeInvoiced / months.length).toFixed(1),
            amount: monthAmounts[index].toFixed(1),
            id: months[index],
          };
          calculatedInvoices.push(invoice);
        }
        setCalculatedInvoices(calculatedInvoices);
      }
    } catch (err) {}
  }, [sendRequest, contractId, store.receipts]);

  useEffect(() => {
    fetchItem();

    //setCalculatedInvoices();
  }, [contractId, fetchItem]);

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  const updateContractFinalAmount = async (event) => {
    event.preventDefault();

    let newAmount = null;
    try {
      newAmount = parseInt(document.getElementById("totalAmountToCalculate").value.trim());
    } catch (err) {
      //Alert user
      alert("Girilen tutar geçerli bir sayı değil. Güncelleme işlemi yapılmadı." + err.toString());
      return;
    }

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${contractId}`,
        "PATCH",
        JSON.stringify({
          finalAmount: newAmount,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      fetchItem();
    } catch (err) {}
  };

  const updateEverythingVisually = async () => {
    try {
      const allreceipts = store.receipts.filter((item) => item.contract.toString() === contractId);
      setReceipts(allreceipts);
      //sum all receipts amount
      let totalPayed = 0;
      allreceipts.forEach((item) => {
        totalPayed += item.amount;
      });
      setTotalPayed(totalPayed);

      const totalAmount = document.getElementById("totalAmountToCalculate").value.trim();

      // const toBeInvoiced = totalAmount - totalPayed;
      const toBeInvoiced = parseFloat(totalAmount);

      //get current month

      const response = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/faturas/${contractId}`);

      const faturas = response.fatura;

      const months = ["Eylül", "Ekim", "Kasım", "Aralık", "Ocak", "Şubat", "Mart", "Nisan", "Mayıs"];

      let monthAmounts = [];

      for (let index1 = 0; index1 < months.length; index1++) {
        if (months[index1] === "Eylül") {
          monthAmounts.push(faturas.september);
        }
        if (months[index1] === "Ekim") {
          monthAmounts.push(faturas.october);
        }
        if (months[index1] === "Kasım") {
          monthAmounts.push(faturas.november);
        }
        if (months[index1] === "Aralık") {
          monthAmounts.push(faturas.december);
        }
        if (months[index1] === "Ocak") {
          monthAmounts.push(faturas.january);
        }
        if (months[index1] === "Şubat") {
          monthAmounts.push(faturas.february);
        }
        if (months[index1] === "Mart") {
          monthAmounts.push(faturas.march);
        }
        if (months[index1] === "Nisan") {
          monthAmounts.push(faturas.april);
        }
        if (months[index1] === "Mayıs") {
          monthAmounts.push(faturas.may);
        }
      }

      if (toBeInvoiced > 0) {
        let calculatedInvoices = [];
        for (let index = 0; index < months.length; index++) {
          const invoice = {
            month: months[index],
            // amount: (toBeInvoiced / (months.length-1)).toFixed(1),
            amount: monthAmounts[index].toFixed(1),
            id: months[index],
          };

          calculatedInvoices.push(invoice);
        }

        setCalculatedInvoices(calculatedInvoices);
      }
    } catch (err) {}
  };

  if (!loadedItem && !error) {
    return (
      <div className="center">
        <Card>
          <h2>{ItemTypeTR} bulunamadı.</h2>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      {!isLoading && loadedItem && receipts && calculatedInvoices && (
        <React.Fragment>
          <div className="item-form">
            <div className="button-container">
              <Button type="button" danger onClick={props.onClose}>
                &#60; Geri
              </Button>
            </div>
            <br />
            <br />
            <b>Toplam Sözleşme Tutarı: </b>
            {loadedItem.finalAmount} TL
            <br />
            <br />
            <b>Bugüne Kadar Ödenen: </b>
            {totalPayed} TL
            <br />
            <br />
            <b>Kalan Ödeme: </b>
            {loadedItem.finalAmount - totalPayed} TL
            <br />
            <br />
            Yeni Sözleşme Tutarı:
            <input
              style={{ margin: "16px", height: "30px" }}
              key={"totalAmountToCalculate"}
              id={"totalAmountToCalculate"}
              defaultValue={loadedItem.finalAmount}
            ></input>
            <Button type="button" danger onClick={updateEverythingVisually}>
              Test İçin Güncelle
            </Button>
            <Button type="button" danger onClick={updateContractFinalAmount}>
              Yeni Tutarı Onayla
            </Button>
            <h4>Faturalar</h4>
            <TableContainer component={Paper} sx={{ minWidth: 650 }}>
              <Table
                sx={{ minWidth: 650, width: "30%", justifyContent: "center", margin: "auto" }}
                stickyHeader
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Tarih</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>Fatura Bedeli</b>
                    </TableCell>
                    {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calculatedInvoices.map((row) => (
                    <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {row.month}
                      </TableCell>
                      <TableCell align="right">{row.amount}</TableCell>
                      {/* <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <h4>Tahsilatlar</h4>
            <TableContainer component={Paper} sx={{ minWidth: 650 }}>
              <Table
                sx={{ minWidth: 650, width: "30%", justifyContent: "center", margin: "auto" }}
                stickyHeader
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Tarih</TableCell>
                    <TableCell align="right">Ödenen</TableCell>
                    {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {receipts.map((row) => (
                    <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        {row.date}
                      </TableCell>
                      <TableCell align="right">{row.amount}</TableCell>
                      {/* <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ReceiptItem;
