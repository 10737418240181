import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  userId: null,
  userName: null,
  token: null,
  school: null,
  schoolType: null,
  userLoaded: false,
  userType: null,
  company: null,
  login: () => {},
  logout: () => {},
  theme: null,
  UpdateTheme: () => {},
});
