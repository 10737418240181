import React, { useContext, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
// #endregion Components

// #region Utils
import { CheckTimeString } from "shared/util/time";
import { personelServiceRouteTypes } from "shared/util/types";
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const NewPersonelServiceItem = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);

  const { schoolOptions, InsertPersonelService, driverOptions, userOptions } = useContext(StoreContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [itemGenerated, setItemGenerated] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const alertMessageRef = useRef("");

  const refGeneratedServiceId = useRef();
  const ItemType = "personelservice";
  const APIEndPoint = "personelservices";

  const itemSubmitHandler = async (event) => {
    event.preventDefault();

    if (!CheckTimeString(formState.inputs.starttime.value) || !CheckTimeString(formState.inputs.stoptime.value)) {
      alertMessageRef.current = "Saat bilgisini **:** formatında giriniz. Örnek: 07:00 ya da 18:35";
      setShowAlert(true);
      return;
    }

    try {
      const infoToBeSent = {
        school: formState.inputs.school.value,
        type: formState.inputs.type.value,
        name: formState.inputs.name.value,
        drivers: formState.inputs.drivers.value,
        plate: formState.inputs.plate.value,
        starttime: formState.inputs.starttime.value,
        stoptime: formState.inputs.stoptime.value,
        isshift: false,
        status: 1,
        users: formState.inputs.users.value,
      };

      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify(infoToBeSent),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      if (responseData[ItemType]) {
        InsertPersonelService(responseData[ItemType]);
        refGeneratedServiceId.current = responseData[ItemType].id;
        setItemGenerated(true);
      }
    } catch (err) {}
  };

  const [formState, inputHandler] = useForm(
    {
      school: { value: null, isValid: false },
      type: { value: null, isValid: false },
      name: { value: null, isValid: false },
      drivers: { value: [], isValid: true },
      plate: { value: null, isValid: true },
      starttime: { value: "00:01", isValid: true },
      stoptime: { value: "23:59", isValid: true },
      users: { value: [], isValid: true },
    },
    false
  );

  const goToItem = () => {
    props.gotoItem(refGeneratedServiceId.current);
  };

  const close = () => {
    setItemGenerated(false);
    props.onClose();
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  if (itemGenerated) {
    return (
      <div className="center generate-success">
        <h4>{t(`standart${ItemType}.itemAddedSuccessfully`)}</h4>
        <Button onClick={goToItem}> {t(`standart${ItemType}.goToNewItem`)}</Button>{" "}
        <Button danger onClick={() => setItemGenerated(false)}>
          {t(`standart${ItemType}.createNewItem`)}
        </Button>{" "}
        <Button danger onClick={close}>
          {t("close")}
        </Button>{" "}
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          {alertMessageRef.current}
        </MuiAlert>
      </Snackbar>

      <form className="item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <div className="new-button-container">
          <h3 style={{ margin: 0 }}>{t(`standart${ItemType}.createNewItem`)}</h3>
          <Button type="button" size="small" danger onClick={props.onClose}>
            &#60; {t("close")}
          </Button>
        </div>

        <CustomSelect options={schoolOptions} id="school" onInput={inputHandler} label={t("client")}></CustomSelect>

        <CustomSelect
          options={personelServiceRouteTypes}
          id="type"
          onInput={inputHandler}
          label="Rota Tipi"
        ></CustomSelect>

        <Input
          id="name"
          element="input"
          type="text"
          label={t("name")}
          validators={[VALIDATOR_REQUIRE()]}
          errorText={t("requireField", { item: t("name") })}
          onInput={inputHandler}
        />

        <CustomSelect
          options={driverOptions}
          id="drivers"
          isMulti={true}
          onInput={inputHandler}
          label="Rotaya Yetkisi Olan Sürücüler"
        ></CustomSelect>

        <Input
          id="plate"
          element="input"
          type="text"
          label="Güzergaha tanımlı aracın plakası"
          validators={[VALIDATOR_NONE()]}
          errorText="Zorunlu alan"
          initialValid={true}
          onInput={inputHandler}
        />

        <Input
          id="starttime"
          element="input"
          type="text"
          label="Başlama Saati"
          validators={[VALIDATOR_REQUIRE()]}
          initialValid={true}
          initialValue={formState.inputs.starttime.value}
          errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
          onInput={inputHandler}
        />
        <Input
          id="stoptime"
          element="input"
          type="text"
          label="Bitiş Saati"
          validators={[VALIDATOR_REQUIRE()]}
          initialValid={true}
          initialValue={formState.inputs.stoptime.value}
          errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
          onInput={inputHandler}
        />

        <CustomSelect
          options={userOptions}
          id="users"
          isMulti={true}
          onInput={inputHandler}
          label="Servisteki Kullanıcılar"
        ></CustomSelect>

        <div className="button-container">
          <Button type="submit" disabled={!formState.isValid}>
            {t(`standart${ItemType}.createNewItem`)}
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default NewPersonelServiceItem;
