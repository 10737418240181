import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
// #endregion Components

// #region Utils

import { notificationtypes, notificationmethods, booleanstatus, durations } from "shared/util/types";

import { VALIDATOR_MAX, VALIDATOR_MIN } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const RelationItem = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);

  let studentHeader = "...";

  if (auth.schoolType === "1") {
    studentHeader = "Öğrenci";
  }
  if (auth.schoolType === "2") {
    studentHeader = "Kullanıcı Servisi";
  }

  const [itemstate, setItemState] = useState();
  const [loadedItem, setLoadedItem] = useState();

  const itemId = props.itemid;
  const updateStoreItems = store.UpdateRelations;

  const ItemTypeTR = "Bildirim";
  const ItemType = "relation";
  const ItemEndPointType = "relations";

  // #region Standard Interface functions
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  // #endregion Standard Interface functions

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);
      setLoadedItem(responseData[ItemType]);
      setItemState(Date.now());
    } catch (err) {}
  }, [sendRequest, itemId]);

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`, "DELETE", null, {
        "Content-Type": "application/json",
      });
      setItemState(Date.now());
      updateStoreItems();
      setLoadedItem(null);
      navigate("/notifications");
    } catch (err) {}
  };
  // #endregion Standard API calls

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  const [formState, inputHandler] = useForm(
    {
      student: { value: "", isValid: true },
      parent: { value: "", isValid: true },
      notificationtype: { value: [], isValid: true },
      notificationmethod: { value: [], isValid: true },
      isenabled: { value: [], isValid: true },
      approachtogeton: { value: [], isValid: true },
      approachtogetoff: { value: [], isValid: true },
      arrivedschool: { value: [], isValid: true },
      leavedschool: { value: [], isValid: true },
      studentgeton: { value: [], isValid: true },
      studentnotgeton: { value: [], isValid: true },
      distancegeton: { value: [], isValid: false },
      distancegetoff: { value: [], isValid: false },
      durationgeton: { value: [], isValid: false },
      durationgetoff: { value: [], isValid: false },
    },
    true
  );

  const itemUpdateSubmitHandler = useCallback(
    async (event) => {
      event.preventDefault();
      let responseData;
      try {
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
          "PATCH",
          JSON.stringify({
            student: formState.inputs.student.value,
            parent: formState.inputs.parent.value,
            notificationtype: formState.inputs.notificationtype.value,
            notificationmethod: formState.inputs.notificationmethod.value,
            isenabled: formState.inputs.isenabled.value,
            approachtogeton: formState.inputs.approachtogeton.value,
            approachtogetoff: formState.inputs.approachtogetoff.value,
            arrivedschool: formState.inputs.arrivedschool.value,
            leavedschool: formState.inputs.leavedschool.value,
            studentgeton: formState.inputs.studentgeton.value,
            studentnotgeton: formState.inputs.studentnotgeton.value,
            distancegeton: formState.inputs.distancegeton.value,
            distancegetoff: formState.inputs.distancegetoff.value,
            durationgeton: formState.inputs.durationgeton.value,
            durationgetoff: formState.inputs.durationgetoff.value,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );

        setLoadedItem(responseData[ItemType]);
        setItemState(Date.now());
        updateStoreItems();

        navigate("/notifications");
      } catch (err) {}
    },
    [auth.token, sendRequest, formState, itemId, updateStoreItems]
  );

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return (
      <div className="center">
        <Card>
          <h2>{ItemTypeTR} bulunamadı.</h2>
        </Card>
      </div>
    );
  }
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Emin misiniz?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              Hayır
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>{ItemTypeTR} silme işlemini onaylıyor musunuz? Bu işlem geri alınamaz.</p>
      </Modal>

      {!isLoading && loadedItem && (
        <form key={itemstate} className="item-form" onSubmit={itemUpdateSubmitHandler}>
          <div className="button-container">
            <Button type="button" danger onClick={props.onClose}>
              &#60; Geri
            </Button>
          </div>

          <CustomSelect
            options={store.parentOptions}
            id="parent"
            onInput={inputHandler}
            label="Bildirim Sahibi"
            initialValid={true}
            initialValue={store.parentOptions.find((option) => loadedItem.parent === option.value)}
          ></CustomSelect>

          <CustomSelect
            options={store.studentOptions}
            id="student"
            onInput={inputHandler}
            label={studentHeader}
            initialValid={true}
            initialValue={store.studentOptions.find((option) => loadedItem.student === option.value)}
          ></CustomSelect>

          <CustomSelect
            options={notificationtypes}
            id="notificationtype"
            onInput={inputHandler}
            label="Bildirim Türü"
            initialValid={true}
            initialValue={notificationtypes.find((option) => loadedItem.notificationtype === option.value)}
          ></CustomSelect>

          <CustomSelect
            options={notificationmethods}
            id="notificationmethod"
            onInput={inputHandler}
            label="Bildirim Yöntemi"
            initialValid={true}
            initialValue={notificationmethods.find((option) => loadedItem.notificationmethod === option.value)}
          ></CustomSelect>

          <CustomSelect
            options={booleanstatus}
            id="isenabled"
            onInput={inputHandler}
            label="Genel Bildirim Durumu"
            initialValid={true}
            initialValue={booleanstatus.find((option) => loadedItem.isenabled === option.value)}
          ></CustomSelect>

          <CustomSelect
            options={booleanstatus}
            id="approachtogeton"
            onInput={inputHandler}
            label="Almaya Yaklaşınca"
            initialValid={true}
            initialValue={booleanstatus.find(
              (option) => loadedItem.approachtogeton.toString() === option.value.toString()
            )}
          ></CustomSelect>

          <CustomSelect
            options={booleanstatus}
            id="approachtogetoff"
            onInput={inputHandler}
            label="Bırakmaya Yaklaşınca"
            initialValid={true}
            initialValue={booleanstatus.find(
              (option) => loadedItem.approachtogetoff.toString() === option.value.toString()
            )}
          ></CustomSelect>

          <CustomSelect
            options={booleanstatus}
            id="arrivedschool"
            onInput={inputHandler}
            label="Okula Varınca"
            initialValid={true}
            initialValue={booleanstatus.find(
              (option) => loadedItem.arrivedschool.toString() === option.value.toString()
            )}
          ></CustomSelect>

          <CustomSelect
            options={booleanstatus}
            id="leavedschool"
            onInput={inputHandler}
            label="Okuldan Çıkınca"
            initialValid={true}
            initialValue={booleanstatus.find(
              (option) => loadedItem.leavedschool.toString() === option.value.toString()
            )}
          ></CustomSelect>

          <CustomSelect
            options={booleanstatus}
            id="studentgeton"
            onInput={inputHandler}
            label="Servise Binince"
            initialValid={true}
            initialValue={booleanstatus.find(
              (option) => loadedItem.studentgeton.toString() === option.value.toString()
            )}
          ></CustomSelect>

          <CustomSelect
            options={booleanstatus}
            id="studentnotgeton"
            onInput={inputHandler}
            label="Servise Binmeyince"
            initialValid={true}
            initialValue={booleanstatus.find(
              (option) => loadedItem.studentnotgeton.toString() === option.value.toString()
            )}
          ></CustomSelect>

          <CustomSelect
            options={durations}
            id="durationgeton"
            onInput={inputHandler}
            label="Biniş Öncesi Süre"
            initialValid={true}
            initialValue={durations.find((option) => loadedItem.durationgeton.toString() === option.value.toString())}
          ></CustomSelect>

          <CustomSelect
            options={durations}
            id="durationgetoff"
            onInput={inputHandler}
            label="İniş Öncesi Süre"
            initialValid={true}
            initialValue={durations.find((option) => loadedItem.durationgetoff.toString() === option.value.toString())}
          ></CustomSelect>

          <Input
            id="distancegeton"
            element="input"
            type="text"
            label="Biniş Öncesi Bildirim Mesafesi"
            validators={[VALIDATOR_MAX(5), VALIDATOR_MIN(0.1)]}
            initialValue={loadedItem.distancegeton}
            initialValid={true}
            onInput={inputHandler}
            errorText="Mesafe 0.1 ve 5 arasında bir sayı olmalıdır. Km'yi ifade eder."
          />

          <Input
            id="distancegetoff"
            element="input"
            type="text"
            label="İniş Öncesi Bildirim Mesafesi"
            validators={[VALIDATOR_MAX(5), VALIDATOR_MIN(0.1)]}
            initialValue={loadedItem.distancegetoff}
            initialValid={true}
            onInput={inputHandler}
            errorText="Mesafe 0.1 ve 5 arasında bir sayı olmalıdır. Km'yi ifade eder."
          />

          <div className="button-container">
            <Button type="submit" disabled={!formState.isValid}>
              Güncelle
            </Button>

            <Button type="button" danger onClick={showDeleteWarningHandler}>
              SİL
            </Button>
          </div>
        </form>
      )}
    </React.Fragment>
  );
};

export default RelationItem;
