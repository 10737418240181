import React, { useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
// #endregion Components

// #region Utils
import { vehicleroutetypes } from "shared/util/types";
import { CheckTimeString } from "shared/util/time";
import { VALIDATOR_REQUIRE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const NewVehicleRouteItem = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();

  const updateStoreItems = store.UpdateVehicleRoutes;
  const ItemTypeTR = "Rota";
  const ItemEndPointType = "vehicleroutes";
  const APIEndPoint = "vehicleroutes";

  const [showAlert, setShowAlert] = useState(false);
  const alertMessageRef = useRef("");

  const itemSubmitHandler = async (event) => {
    event.preventDefault();

    if (!CheckTimeString(formState.inputs.starttime.value) || !CheckTimeString(formState.inputs.stoptime.value)) {
      alertMessageRef.current = "Saat bilgisini **:** formatında giriniz. Örnek: 07:00 ya da 18:35";
      setShowAlert(true);
      return;
    }

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify({
          name: formState.inputs.name.value,
          type: formState.inputs.type.value,
          school: formState.inputs.school.value,
          driver: formState.inputs.driver.value,
          plate: formState.inputs.plate.value,
          starttime: formState.inputs.starttime.value,
          stoptime: formState.inputs.stoptime.value,
          student: formState.inputs.student.value,
          status: 1,
          location: { lat: -1.1, lng: -1.1 },
          routelocations: [],
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      updateStoreItems();
      navigate(`/${ItemEndPointType}`);
    } catch (err) {}
  };

  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: false },
      type: { value: "", isValid: false },
      school: { value: null, isValid: false },
      driver: { value: [], isValid: false },
      plate: { value: "", isValid: false },
      starttime: { value: "", isValid: false },
      stoptime: { value: "", isValid: false },
      student: { value: [], isValid: false },
    },
    false
  );

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          {alertMessageRef.current}
        </MuiAlert>
      </Snackbar>

      <form className="new-item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <h3>{ItemTypeTR} Ekle</h3>
        <CustomSelect options={store.schoolOptions} id="school" onInput={inputHandler} label="Kurum"></CustomSelect>
        <Input
          id="name"
          element="input"
          type="text"
          label="İsim"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="İsim giriniz."
          onInput={inputHandler}
        />
        <CustomSelect options={vehicleroutetypes} id="type" onInput={inputHandler} label="Rota Tipi"></CustomSelect>
        <CustomSelect
          options={store.driverOptions}
          id="driver"
          isMulti={true}
          onInput={inputHandler}
          label="Rotaya Yetkisi Olan Sürücüler"
        ></CustomSelect>
        <Input
          id="plate"
          element="input"
          type="text"
          label="Güzergaha tanımlı aracın plakası"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Zorunlu alan"
          onInput={inputHandler}
        />

        <Input
          id="starttime"
          element="input"
          type="text"
          label="Başlama Saati"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
          onInput={inputHandler}
        />
        <Input
          id="stoptime"
          element="input"
          type="text"
          label="Bitiş Saati"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
          onInput={inputHandler}
        />
        <CustomSelect
          options={store.studentOptions}
          id="student"
          isMulti={true}
          onInput={inputHandler}
          label="Servisteki Kullanıcılar"
        ></CustomSelect>
        <Button type="submit" disabled={!formState.isValid}>
          Yeni {ItemTypeTR} Yarat
        </Button>
      </form>
    </React.Fragment>
  );
};

export default NewVehicleRouteItem;
