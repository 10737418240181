import React, { useContext } from "react";

import Layout from "./shared/Layout";
import ServiceList from "../components/personel-service/PersonelServicesList";
import ServiceItem from "../components/personel-service/PersonelServiceItem";
import NewServiceItem from "../components/personel-service/NewPersonelServiceItem";
import { StoreContext } from "shared/context/store-context";
import { vehicleroutetypes } from "shared/util/types";

const StandardPersonelServices = () => {
  const { standartPersonelServices, schoolOptions, driverOptions } = useContext(StoreContext);
  const itemType = "standartpersonelservice";
  const listName = "standartpersonelservices";

  const listColumns = [
    {
      field: "school",
      headerName: "Kurum",
      width: 200,
      valueGetter: (params) =>
        schoolOptions.find((item) => item?.value?.toString() == params.row.school.toString())?.label,
    },
    { field: "name", headerName: "İsim", width: 300 },
    {
      field: "type",
      headerName: "Türü",
      width: 100,
      valueGetter: (params) =>
        vehicleroutetypes.find((item) => item?.value?.toString() == params.row.type.toString())?.label,
    },
    {
      field: "drivers",
      headerName: "Ana Sürücü",
      width: 300,
      valueGetter: (params) => {
        try {
          return driverOptions.find((item) => item.value.toString() === params?.row?.drivers[0]?.toString()).label;
        } catch (e) {
          return "";
        }
      },
    },
    { field: "plate", headerName: "Plaka", width: 100 },
  ];

  return (
    <Layout
      itemType={itemType}
      items={standartPersonelServices}
      listName={listName}
      listColumns={listColumns}
      ListItemComponent={ServiceList}
      SelectedItemComponent={ServiceItem}
      NewItemComponent={NewServiceItem}
    />
  );
};

export default StandardPersonelServices;
