/*eslint-disable eqeqeq*/
import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import SchoolIcon from "@mui/icons-material/School";

import DirectionsBus from "@mui/icons-material/DirectionsBus";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

// #region Components
import Button from "shared/components/FormElements/Button";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Marker from "shared/components/UIElements/Marker";
import Player from "shared/components/UIElements/player";
import { PlayerStates } from "shared/util/types";

import {
  TRAVEL_ACTION_TYPE,
  TRAVEL_ACTION_TYPE_EXPLANATIONS,
  APPROACH_TO_HOME_TYPE_EXPLANATIONS,
  STUDENT_GETON_INFO_TYPE_EXPLANATIONS,
  ARRIVED_SCHOOL_TYPE_EXPLANATIONS,
  ARRIVED_SCHOOL_TYPE,
} from "shared/util/types";

// #endregion Components

// #region Hooks
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const LiveItem = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const [currentStudents, setCurrentStudents] = useState([]);

  // eslint-disable-next-line
  const [itemstate, setItemState] = useState();
  const [loadedItem, setLoadedItem] = useState();
  const [travels, setTravels] = useState([]);
  const [travelLocations, setTravelLocations] = useState();
  const [travelActions, setTravelActions] = useState([]);

  const locationsREF = useRef(null);

  const mapREF = useRef(null);
  const mapsREF = useRef(null);
  const [school, setSchool] = useState();

  const [index, setIndex] = useState(0);

  const indexREF = useRef(0);
  const playerStateREF = useRef(PlayerStates.NOTSTARTED);

  const itemId = props.itemid;

  const ItemTypeTR = "Rota";
  const ItemType = "vehicleRoute";
  const ItemEndPointType = "vehicleroutes";

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const fetchTravelLocations = useCallback(
    async (travelid) => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/adminlive/${travelid}`);

        setTravelLocations(responseData.travelLocations);
        locationsREF.current = responseData.travelLocations;

        //return responseData;
        //setLoadedItem(responseData[ItemType]);
        //setMarkerLocation(responseData[ItemType].location);
        //setItemState(Date.now());
      } catch (err) {}
    },
    [sendRequest]
  );

  const fetchTravels = useCallback(
    async (routeid) => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/adminlive`,
          "POST",
          JSON.stringify({
            routeid,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        return responseData;
        //setLoadedItem(responseData[ItemType]);
        //setMarkerLocation(responseData[ItemType].location);
        //setItemState(Date.now());
      } catch (err) {}
    },
    [sendRequest, auth.token]
  );

  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);

      const currentSchool = store.schools.find(
        (school) => school.id.toString() === responseData[ItemType].school.toString()
      );
      setSchool(currentSchool);

      setLoadedItem(responseData[ItemType]);
      setCurrentStudents(
        store.students
          .filter((item) => responseData[ItemType].student.includes(item.id))
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
              location: responseData[ItemType].type === 100 ? item.locationgeton : item.locationgetoff,
              identifier: item.identifier,
              phonenumber: item.phonenumber,
              parent: store.users.find((user) =>
                store.relations.find((relation) => relation.student.toString() === item.id.toString())
                  ? user.id.toString() ===
                    store.relations
                      .find((relation) => relation.student.toString() === item.id.toString())
                      .parent.toString()
                  : null
              ),
            };
          })
      );

      const routeid = responseData[ItemType].id;
      const fetchedTravels = await fetchTravels(routeid);

      setTravels(fetchedTravels.travels);

      setItemState(Date.now());
    } catch (err) {
      console.log(err);
    }
  }, [store.students, store.schools, store.users, store.relations, sendRequest, itemId, fetchTravels]);

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  const play = useCallback(() => {
    if (playerStateREF.current === PlayerStates.PLAYING) {
      if (indexREF.current === locationsREF.current.length - 1) {
        onIndexChangeHandler(0);
      } else {
        onIndexChangeHandler(indexREF.current + 1);
      }
    }
  }, []);

  useEffect(() => {
    const playerInterval = setInterval(() => {
      play();
    }, 500);

    return () => {
      clearInterval(playerInterval);
    };
  }, [play]);

  // function getVehicleHeading(currentCoord, NextCoord) {
  //   const northOrSouth = currentCoord.lat < NextCoord.lat ? "N" : "S";
  //   const eastOrWest = currentCoord.lng < NextCoord.lng ? "E" : "W";
  //   const latDiff = currentCoord.lat - NextCoord.lat;
  //   const lngDif = currentCoord.lng - NextCoord.lng;
  //   const angleRadians = Math.atan(latDiff / lngDif);
  //   const angleDegrees = (angleRadians * 180) / Math.PI;
  //   return angleDegrees;
  // }

  // const vehicleMapHandler = (map, location, nextLocation) => {
  //   let degrees = 0;

  //   if (nextLocation) {
  //     degrees = 90 - getVehicleHeading(location, nextLocation);
  //   }

  //   const myIcon = {
  //     path: "M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759 c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z       M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713      v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336      h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805z",
  //     strokeColor: "red",
  //     scale: 10,
  //     fillColor: "green",
  //     fillOpacity: 1,
  //     anchor: new window.google.maps.Point(20, 30),
  //     rotation: degrees,
  //   };

  //   if (vehicleREF.current) {
  //     vehicleREF.current.setPosition(location);

  //     // vehicleREF.current.setMap(null);
  //   } else {
  //     vehicleREF.current = new window.google.maps.Marker({
  //       position: location,
  //       title: "Vehicle",
  //       map: map,
  //       icon: myIcon,
  //     });
  //   }

  //   // vehicleREF.current.set(id, "VehicleMarkerId")

  //   // if(document.getElementById(VehicleMarkerId))
  //   // {
  //   // var innerArrow = document.getElementsByTagName("Car");
  //   // innerArrow.setAttribute("transform", "rotate(45)");
  //   // }

  //   //setVehicle(carMarker);
  // };

  // const updateCurrentStudents = () => {
  //   // setCurrentStudents(store.students
  //   //     .filter((item) => formState.inputs.student.value.includes(item.id))
  //   //     .map((item) => {
  //   //       return {
  //   //         id: item.id,
  //   //         name: item.name,
  //   //         location:
  //   //           loadedItem.type === 100 ? item.locationgeton : item.locationgetoff,
  //   //         identifier: item.identifier,
  //   //         phonenumber: item.phonenumber,
  //   //       };
  //   //     }));
  // };

  const fetchTravelActions = useCallback(
    async (travelid) => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/dailyaction/${travelid}`);

        let currentActions = responseData.travelActions;

        currentActions = currentActions.sort((a, b) => {
          if (a.date < b.date) {
            return -1;
          }
          if (a.date > b.date) {
            return 1;
          }
          return 0;
        });

        setTravelActions(currentActions);

        await fetchTravelLocations(travelid);

        //return responseData;
        //setLoadedItem(responseData[ItemType]);
        //setMarkerLocation(responseData[ItemType].location);
        //setItemState(Date.now());
      } catch (err) {}
    },
    [fetchTravelLocations, sendRequest]
  );

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  //#region Map Related
  const handleApiLoaded = (map, maps) => {
    mapREF.current = map;
    mapsREF.current = maps;

    // const triangleCoords = [
    //   { lat: 25.774, lng: -80.19 },
    //   { lat: 18.466, lng: -66.118 },
    //   { lat: 32.321, lng: -64.757 },
    //   { lat: 25.774, lng: -80.19 }
    // ];
    const currentlocations = travelLocations.map((item) => item.location);

    var bermudaTriangle = new maps.Polyline({
      path: currentlocations,
      strokeColor: "#FF0000",
      strokeOpacity: 1,
      strokeWeight: 2,
      // fillColor: "#FF0000",
      fillOpacity: 0,
    });
    bermudaTriangle.setMap(map);
  };
  //#endregion Map Related

  const onIndexChangeHandler = (currentIndex) => {
    //vehicleMapHandler(mapREF,locationsREF.current[currentIndex].location,locationsREF.current[currentIndex+1].location);
    setIndex((prevIndex) => currentIndex);
    indexREF.current = currentIndex;
  };

  const onPlayerStateChangeHandler = (currentState) => {
    playerStateREF.current = currentState;
  };

  const onFullScreenChangeHandler = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const getMapOptions = (maps) => {
    return {
      streetViewControl: false,
      scaleControl: true,

      fullscreenControl: true,
      styles: [
        {
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
      ],
      gestureHandling: "greedy",
      disableDoubleClickZoom: false,
      minZoom: 11,
      maxZoom: 25,

      mapTypeControl: false,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE, maps.MapTypeId.HYBRID],
      },

      zoomControl: false,
      clickableIcons: false,
    };
  };

  // #region Loading
  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return (
      <div className="center">
        <Card>
          <h2>{ItemTypeTR} bulunamadı.</h2>
        </Card>
      </div>
    );
  }
  // #endregion Loading

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      {!isLoading && loadedItem && currentStudents.length != 0 && travels.length != 0 && (
        <div>
          <div className="button-container">
            <Button type="button" danger onClick={props.onClose}>
              &#60; Geri
            </Button>
          </div>

          <div className="livepagecontainer">
            <Card className="liveheadercontainer">
              <h4>{store.schoolOptions.find((school) => school.value === loadedItem.school.toString()).label}</h4>
              <span>
                <strong>Servis:</strong> {loadedItem.name} ( {loadedItem.type === 100 ? "Sabah" : "Akşam"} )
              </span>
              <br />
              <span>
                <strong>Sürücü: </strong>
                {store.driverOptions.find((driver) => driver.value === loadedItem.driver[0].toString()).label}
              </span>
              <br />
              <span>
                <strong>Telefon: </strong>
                {store.users.find((user) => user.id.toString() === loadedItem.driver[0].toString()).phonenumber}
              </span>

              <br />
              <h4>Hareket Tarihi Seçiniz</h4>
              <div>
                {travels.map((travel) => {
                  return (
                    <div className="liveheaderdatecontainer">
                      <Button type="button" onClick={fetchTravelActions.bind(this, travel.id)}>
                        {travel.day}
                      </Button>
                    </div>
                  );
                })}
              </div>
            </Card>

            {travelActions.length != 0 && (
              <div className="routeInfoContainer">
                <div className="routeInfoMapContainer">
                  <div className="routeInfoMap">
                    {travelLocations && (
                      <Player
                        onPlayerStateChange={onPlayerStateChangeHandler}
                        onPlayerIndexChange={onIndexChangeHandler}
                        onFullScreenChange={onFullScreenChangeHandler}
                        min={0}
                        max={travelLocations.length - 1}
                        value={index}
                      />
                    )}

                    {/* <ReactSlider
                        className="horizontal-slider"
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        renderTrack={(props, state) => <div {...props} />} //custom track
                      /> */}

                    <GoogleMapReact
                      options={getMapOptions}
                      bootstrapURLKeys={{
                        key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
                      }}
                      yesIWantToUseGoogleMapApiInternals //this is important!
                      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                      //defaultCenter={markerLocationMorning}
                      center={currentStudents[0].location}
                      defaultZoom={16}
                    >
                      {locationsREF.current && (
                        <Marker
                          Icon={DirectionsBus}
                          lat={locationsREF.current[index].location.lat}
                          lng={locationsREF.current[index].location.lng}
                          key={"asdasggh"}
                          info={
                            locationsREF.current[index].date.split(" ")[1] +
                            " - " +
                            locationsREF.current[index].speed * 3.6 +
                            " km/saat"
                          }
                          alwaysShowMarker={true}
                        />
                      )}

                      <Marker
                        Icon={SchoolIcon}
                        lat={school.location.lat}
                        lng={school.location.lng}
                        key={"Okul"}
                        info={school.name}
                        alwaysShowMarker={true}
                      />

                      {currentStudents.map((student) => {
                        return (
                          <Marker
                            Icon={RoomIcon}
                            lat={student.location.lat}
                            lng={student.location.lng}
                            key={student.id}
                            info={student.name}
                          />
                        );
                      })}
                    </GoogleMapReact>
                  </div>

                  {/* <div className="routeInfoStudents">
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={5}>
                        {currentStudents.map((student) => {
                          return (
                            <Grid item xs={4}>
                              <StudentLiveCard
                                student={student}
                                parent={student.parent}
                                actions={travelActions}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Box>
                  </div> */}
                </div>{" "}
                {/* Route Info Map Ending */}
                <div className="routeInfoDetails">
                  {travelActions.length != 0 && (
                    <div>
                      <br />
                      Güzergah Hareketleri
                      <br />
                      <br />
                      <Box sx={{ flexGrow: 1 }}>
                        {travelLocations && locationsREF.current && (
                          <Grid container spacing={2}>
                            {travelActions.map((travelaction) => {
                              return (
                                <Grid item xs={2}>
                                  <Item
                                    style={{
                                      background:
                                        locationsREF.current[index].date.split(" ")[1] < travelaction.date.split(" ")[1]
                                          ? "gray"
                                          : "green",
                                    }}
                                  >
                                    {travelaction.date.split(" ")[1]} {" - "}
                                    {(travelaction.actiontype === TRAVEL_ACTION_TYPE.LEAVED_SCHOOL ||
                                      travelaction.actiontype === TRAVEL_ACTION_TYPE.STARTED ||
                                      travelaction.actiontype === TRAVEL_ACTION_TYPE.FINISHED ||
                                      travelaction.actiontype === TRAVEL_ACTION_TYPE.QR_READED) &&
                                      TRAVEL_ACTION_TYPE_EXPLANATIONS.find(
                                        (exp) => exp.value === travelaction.actiontype
                                      ).label}
                                    {(travelaction.actiontype === TRAVEL_ACTION_TYPE.APPROACH_TO_HOME ||
                                      travelaction.actiontype === TRAVEL_ACTION_TYPE.STUDENT_GETON_INFO) &&
                                      currentStudents.find((student) => student.id == travelaction.student)?.name}
                                    {travelaction.actiontype === TRAVEL_ACTION_TYPE.ARRIVED_SCHOOL &&
                                      travelaction.action != ARRIVED_SCHOOL_TYPE.BUS_ARRIVED &&
                                      currentStudents.find((student) => student.id == travelaction.student)?.name}
                                    {travelaction.actiontype === TRAVEL_ACTION_TYPE.ARRIVED_SCHOOL &&
                                      travelaction.action != ARRIVED_SCHOOL_TYPE.BUS_ARRIVED && <br />}
                                    {travelaction.actiontype === TRAVEL_ACTION_TYPE.ARRIVED_SCHOOL &&
                                      ARRIVED_SCHOOL_TYPE_EXPLANATIONS.find(
                                        (type) => type.value === travelaction.action
                                      ).label}
                                    {(travelaction.actiontype === TRAVEL_ACTION_TYPE.APPROACH_TO_HOME ||
                                      travelaction.actiontype === TRAVEL_ACTION_TYPE.STUDENT_GETON_INFO) && <br />}
                                    {travelaction.actiontype === TRAVEL_ACTION_TYPE.APPROACH_TO_HOME &&
                                      APPROACH_TO_HOME_TYPE_EXPLANATIONS.find(
                                        (type) => type.value === travelaction.action
                                      ).label}
                                    {travelaction.actiontype === TRAVEL_ACTION_TYPE.STUDENT_GETON_INFO &&
                                      STUDENT_GETON_INFO_TYPE_EXPLANATIONS.find(
                                        (type) => type.value === travelaction.action
                                      ).label}
                                  </Item>
                                </Grid>
                              );
                            })}
                          </Grid>
                        )}
                      </Box>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default LiveItem;
