import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import NotFound from "shared/components/UIElements/NotFound";
import Marker from "shared/components/UIElements/Marker";
// #endregion Components

// #region Utils
import { LocationFromAddress, AddressFromLocation } from "shared/util/location";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_PHONENUMBER,
  VALIDATOR_NONE,
  VALIDATOR_MAX,
  VALIDATOR_MIN,
} from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import { yesNoOptions } from "shared/util/types";
// #endregion Hooks

const UserItem = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { schoolOptions, UpdateUser, DeleteUser, stopOptions, schools } = useContext(StoreContext);
  const navigate = useNavigate();
  // const store = useContext(StoreContext);

  const [showOptionalField, setShowOptionalField] = useState(false);
  const [itemstate, setItemState] = useState();
  const [loadedItem, setLoadedItem] = useState();

  const [markerLocation, setMarkerLocation] = useState();
  const [mapCenter, setMapCenter] = useState();
  const [userAddress, setUserAddress] = useState();
  const [openError, setOpenError] = useState(false);

  let usertypestoshow = props.usertypes;

  // if (isAdmin === false) {
  //   usertypestoshow.pop();
  // }

  const itemId = props.itemid;
  // const updateStoreItems = store.UpdateUsers;
  const ItemType = "user";
  const ItemEndPointType = "users";

  // #region Standard Interface functions
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };
  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  // #endregion Standard Interface functions

  const [formStateOptional, inputHandlerOptional, setFormDataOptional] = useForm(
    {
      cardId: { value: null, isValid: true },
      getonstop: { value: null, isValid: true },
      getoffstop: { value: null, isValid: true },
      distancegeton: { value: null, isValid: true },
      approachtogetonnotification: { value: null, isValid: true },
      shiftchangenotification: { value: null, isValid: true },
    },
    true
  );

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);
      setLoadedItem(responseData[ItemType]);

      setMarkerLocation(responseData[ItemType]?.location);
      setMapCenter(responseData[ItemType]?.location);
      setUserAddress(responseData[ItemType]?.address);

      setFormDataOptional(
        {
          cardId: { value: null, isValid: true },
          getonstop: { value: null, isValid: true },
          getoffstop: { value: null, isValid: true },
          distancegeton: { value: null, isValid: true },
          approachtogetonnotification: { value: null, isValid: true },
          shiftchangenotification: { value: null, isValid: true },
        },
        true
      );

      setItemState(Date.now());
    } catch (err) {
      if (err?.code === 401) {
        auth.logout();
        navigate("/login");
      }
    }
  }, [sendRequest, itemId, auth, setFormDataOptional]);

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      const deleteResult = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
        "DELETE",
        null,
        {
          "Content-Type": "application/json",
        }
      );

      if (deleteResult?.message === "Deleted") {
        DeleteUser(itemId);
        props.onClose();
      }
    } catch (err) {}
  };
  // #endregion Standard API calls

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: true },
      isshift: { value: false, isValid: true },
      phonenumber: { value: "", isValid: true },
      usertype: { value: null, isValid: true },
      email: { value: null, isValid: true },
      address: { value: null, isValid: true },
      identity: { value: "", isValid: true },
      school: { value: null, isValid: true },
    },
    true
  );

  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const infoToBeSent = {
        name: formState.inputs.name.value,
        phonenumber: formState.inputs.phonenumber.value,
        cardId: formStateOptional.inputs.cardId.value,
        distancegeton: formStateOptional.inputs.distancegeton.value,
        isshift: formState.inputs.isshift.value,
        getonstop: formStateOptional.inputs.getonstop.value,
        getoffstop: formStateOptional.inputs.getoffstop.value,
        approachtogetonnotification: formStateOptional.inputs.approachtogetonnotification.value,
        shiftchangenotification: formStateOptional.inputs.shiftchangenotification.value,
        usertype: formState.inputs.usertype.value,
        email: formState.inputs.email.value,
        address: formState.inputs.address.value,
        identity: formState.inputs.identity.value,
        school: formState.inputs.school.value,
      };

      if (markerLocation) {
        infoToBeSent.location = { lat: markerLocation?.lat || 1.1, lng: markerLocation?.lng || 1.1 };
      }

      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
        "PATCH",
        JSON.stringify(infoToBeSent),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      if (responseData?.[ItemType]) {
        UpdateUser(responseData[ItemType]);
        setLoadedItem(responseData[ItemType]);
        setItemState(Date.now());
      }
    } catch (err) {}
  };

  useEffect(() => {
    const currentSchool = schools.find((school) => school.id.toString() === formState.inputs.school?.value?.toString());

    if (currentSchool?.usemagneticcard) {
      setFormDataOptional(
        {
          cardId: { value: null, isValid: true },
          distancegeton: { value: null, isValid: true },
          getonstop: { value: null, isValid: true },
          getoffstop: { value: null, isValid: true },
          approachtogetonnotification: { value: null, isValid: true },
          shiftchangenotification: { value: null, isValid: true },
        },
        true
      );
      setShowOptionalField(true);
    } else {
      setShowOptionalField(false);
      setFormDataOptional(
        {
          cardId: { value: undefined, isValid: true },
          distancegeton: { value: null, isValid: true },
          getonstop: { value: null, isValid: true },
          getoffstop: { value: null, isValid: true },
          approachtogetonnotification: { value: null, isValid: true },
          shiftchangenotification: { value: null, isValid: true },
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.inputs.school?.value]);

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return <NotFound item={ItemType}></NotFound>;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const mapClickHandler = async (event) => {
    const currentLocation = { lat: event.lat, lng: event.lng };
    let address = null;

    try {
      address = await AddressFromLocation(currentLocation);
    } catch (error) {
      setOpenError(true);
    }
    if (address) {
      setMarkerLocation(currentLocation);
      setUserAddress(address);
      inputHandler("address", address, true);
    } else {
      setOpenError(true);
      setUserAddress("-");
      inputHandler("address", "-", false);
    }
  };

  const SetLocationFromAddress = async () => {
    let location;

    try {
      location = await LocationFromAddress(formState.inputs.address.value);
    } catch (error) {
      setMarkerLocation(null);
      setOpenError(true);
      return;
    }
    setMarkerLocation(location);
    setMapCenter(location);
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header={t("areYouSure")}
        footerClass="place-item__modal-actions"
        footer={
          <div className="modalbuttonscontainer">
            <Button inverse onClick={cancelDeleteHandler}>
              {t("no")}
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              {t("yes")}
            </Button>
          </div>
        }
      >
        <p>{t(`${ItemType}.deleteItemWarning`)}</p>
      </Modal>

      <Snackbar
        open={openError}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Adres-Konum eşleşmesi bulunamadı.
        </MuiAlert>
      </Snackbar>

      {!isLoading && loadedItem && (
        <form key={itemstate} className="item-form" onSubmit={itemUpdateSubmitHandler}>
          <div className="button-container">
            <Button type="button" size="small" danger onClick={props.onClose}>
              &#60; {t("close")}
            </Button>
          </div>

          <CustomSelect
            options={schoolOptions}
            id="school"
            onInput={inputHandler}
            label={t("client")}
            initialValue={schoolOptions.find((option) => option.value === loadedItem.school.toString())}
            initialValid={true}
          ></CustomSelect>

          <Input
            id="name"
            element="input"
            type="text"
            label={t("name")}
            validators={[VALIDATOR_REQUIRE()]}
            errorText={t("requireField", { item: t("name") })}
            initialValue={loadedItem.name}
            initialValid={true}
            onInput={inputHandler}
          />
          <Input
            id="phonenumber"
            element="input"
            type="text"
            label={t("phone")}
            validators={[VALIDATOR_PHONENUMBER(10)]}
            errorText={t("requireField", { item: t("phone") })}
            initialValue={loadedItem.phonenumber}
            initialValid={true}
            onInput={inputHandler}
          />
          <CustomSelect
            options={usertypestoshow}
            id="usertype"
            onInput={inputHandler}
            label={t("userType")}
            initialValue={usertypestoshow.find((option) => option.value.toString() === loadedItem.usertype.toString())}
            initialValid={true}
          ></CustomSelect>

          {showOptionalField && (
            <div>
              <CustomSelect
                options={yesNoOptions}
                id="isshift"
                onInput={inputHandler}
                initialValue={yesNoOptions.find((option) => option.value === loadedItem?.isshift)}
                label={t("isshift")}
              ></CustomSelect>

              <CustomSelect
                options={stopOptions}
                id="getonstop"
                onInput={inputHandlerOptional}
                label={t("getonstop")}
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
                initialValue={stopOptions.find((option) => option?.value === loadedItem?.getonstop?.toString())}
              ></CustomSelect>

              <CustomSelect
                options={stopOptions}
                id="getoffstop"
                onInput={inputHandlerOptional}
                label={t("getoffstop")}
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
                initialValue={stopOptions.find((option) => option?.value === loadedItem?.getoffstop?.toString())}
              ></CustomSelect>

              <Input
                id="cardId"
                element="input"
                type="text"
                label={t("cardId")}
                validators={[VALIDATOR_NONE()]}
                initialValue={loadedItem.cardId}
                initialValid={true}
                onInput={inputHandlerOptional}
              />

              <CustomSelect
                options={yesNoOptions}
                id="approachtogetonnotification"
                onInput={inputHandlerOptional}
                initialValue={yesNoOptions.find((option) => option.value === loadedItem?.approachtogetonnotification)}
                label={t("approachtogetonnotification")}
              ></CustomSelect>

              <CustomSelect
                options={yesNoOptions}
                id="shiftchangenotification"
                onInput={inputHandlerOptional}
                initialValue={yesNoOptions.find((option) => option.value === loadedItem?.shiftchangenotification)}
                label={t("shiftchangenotification")}
              ></CustomSelect>

              <Input
                id="distancegeton"
                element="input"
                type="text"
                label="Biniş Öncesi Bildirim Mesafesi"
                validators={[VALIDATOR_MAX(5), VALIDATOR_MIN(0.1)]}
                initialValue={loadedItem?.distancegeton}
                initialValid={true}
                onInput={inputHandlerOptional}
                errorText="Mesafe 0.1 ve 5 arasında bir sayı olmalıdır. Km'yi ifade eder."
              />
            </div>
          )}
          <Input
            id="identity"
            element="input"
            type="text"
            label={t("identityNumber")}
            validators={[VALIDATOR_NONE()]}
            errorText={t("requireField", { item: t("identityNumber") })}
            initialValue={loadedItem.identity}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="email"
            element="input"
            type="text"
            label={t("email")}
            validators={[VALIDATOR_REQUIRE()]}
            errorText={t("requireField", { item: t("email") })}
            onInput={inputHandler}
            initialValue={loadedItem.email}
            initialValid={true}
          />
          <Input
            key={userAddress}
            id="address"
            element="input"
            type="text"
            label={t("address")}
            validators={[VALIDATOR_REQUIRE()]}
            errorText={t("requireField", { item: t("address") })}
            initialValue={userAddress}
            initialValid={true}
            onInput={inputHandler}
          />

          <Button type="button" danger o onClick={SetLocationFromAddress.bind(this)}>
            Adresten Konumu Getir
          </Button>

          {markerLocation && (
            <div className="map-container">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
                }}
                options={function (maps) {
                  return {
                    mapTypeControl: true,
                    mapTypeId: "roadmap",
                    mapTypeControlOptions: {
                      mapTypeIds: ["satellite", "roadmap"],
                      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                      position: maps.ControlPosition.TOP_LEFT,
                    },
                  };
                }}
                center={mapCenter}
                defaultZoom={16}
                onClick={mapClickHandler.bind(this)}
              >
                <Marker Icon={RoomIcon} lat={markerLocation.lat} lng={markerLocation.lng} />
              </GoogleMapReact>
            </div>
          )}

          <div className="button-container">
            <Button type="submit" disabled={!formState.isValid}>
              {t("update")}
            </Button>

            <Button type="button" danger onClick={showDeleteWarningHandler}>
              {t("delete")}
            </Button>
          </div>
        </form>
      )}
    </React.Fragment>
  );
};

export default UserItem;
