import React, { useState, useContext, useRef } from "react";
import Button from "shared/components/FormElements/Button";
import Input from "shared/components/FormElements/Input";
import { AIRPORT_ID, tsp } from "shared/util/location";
import { CheckTimeString } from "shared/util/time";
import { sendFreeSMS } from "shared/util/util";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import Modal from "shared/components/UIElements/Modal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import CustomSelect from "shared/components/FormElements/Select";
import { useForm } from "shared/hooks/form-hook";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ServiceItem from "./ServiceItem";
import { DndProvider } from "react-dnd";
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";

const Personel = (props) => {
  const {
    users,
    shiftOptions,
    shifts,
    drivers,
    driverOptions,
    schoolOptions,
    UpdateShift,
    DeleteShift,
    shiftPersonelServiceOptions,
    shiftPersonelServices,
    userOptions,
    UpdatePersonelService,
    InsertPersonelService,
  } = useContext(StoreContext);

  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [showNewServiceAddition, setShowNewServiceAddition] = useState(false);

  const [formState, inputHandler] = useForm(
    {
      name: { value: null, isValid: false },
      drivers: { value: null, isValid: true },
      plate: { value: null, isValid: true },
      starttime: { value: "00:01", isValid: true },
      stoptime: { value: "23:59", isValid: true },
    },
    false
  );

  const [showAlert, setShowAlert] = useState(false);
  const alertMessageRef = useRef("");

  const [formStatePersonel, inputHandlerPersonel] = useForm(
    {
      personelUnique: { value: "", isValid: true },
    },
    true
  );

  const togglenewServiceAddition = () => {
    setShowNewServiceAddition(!showNewServiceAddition);
  };

  const getUserService = (userId) => {
    const foundService = props.services.find((service) => service?.users?.includes(userId));
    if (foundService) {
      return foundService;
    }
    return null;
  };

  const getUserSmses = (userId) => {
    const foundService = getUserService(userId);
    if (foundService) {
      return foundService?.usersdetails?.find((user) => user.id === userId)?.smses || [];
    }
    return [];
  };

  const addNewService = async () => {
    if (!CheckTimeString(formState.inputs.starttime.value) || !CheckTimeString(formState.inputs.starttime.value)) {
      alertMessageRef.current = "Saat bilgisini **:** formatında giriniz. Örnek: 07:00 ya da 18:35";
      setShowAlert(true);
      return;
    }

    togglenewServiceAddition();

    props.addService(
      formState.inputs.name.value,
      formState.inputs.drivers.value,
      formState.inputs.plate.value,
      formState.inputs.starttime.value,
      formState.inputs.stoptime.value
    );
  };

  const SendSMS = async (user) => {
    const text = document.getElementById("text" + user.id.toString().trim()).value;
    const phone = user.phone;
    // const date = new Date().toLocaleString();
    // const user_id = user.id;
    // const service = getUserService(user.id);
    const response = sendFreeSMS("5355802151", "test");
  };

  const createServices = async () => {
    props.createServices();
  };

  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
      {/* <Modal
        show={showModal}
        onCancel={() => setShowModal(false)}
        header="Vardiya servisi."
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={() => setShowModal(false)}>
              Tamam
            </Button>
          </React.Fragment>
        }
      >
        <p>{errorMessage}</p>
      </Modal> */}

      <div className="item-form">
        <Button type="button" danger o onClick={togglenewServiceAddition}>
          {showNewServiceAddition ? "Servis Eklemeyi Kapat" : "Yeni Servis Ekle"}
        </Button>

        {showNewServiceAddition && (
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <div className="groupContainer">
              <Input
                id="name"
                element="input"
                type="text"
                label="İsim"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Zorunlu alan"
                onInput={inputHandler}
              />

              <CustomSelect
                options={driverOptions}
                id="drivers"
                isMulti={true}
                onInput={inputHandler}
                label="Service Yetkisi Olan Sürücüler"
              ></CustomSelect>

              <Input
                id="plate"
                element="input"
                type="text"
                label="Service tanımlı ana aracın plakası"
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
                errorText="Zorunlu alan"
                onInput={inputHandler}
              />

              <Input
                id="starttime"
                element="input"
                type="text"
                label="Başlama Saati"
                validators={[VALIDATOR_REQUIRE()]}
                initialValid={true}
                initialValue={formState.inputs.starttime.value}
                errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                onInput={inputHandler}
              />
              <Input
                id="stoptime"
                element="input"
                type="text"
                label="Bitiş Saati"
                validators={[VALIDATOR_REQUIRE()]}
                initialValid={true}
                initialValue={formState.inputs.stoptime.value}
                errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                onInput={inputHandler}
              />

              <Button type="button" danger onClick={addNewService} disabled={!formState.isValid}>
                Vardiya {props.type === "100" ? "Girişine" : "Çıkışına"} Servis Ekle
              </Button>
            </div>
          </div>
        )}

        <div style={{ borderBottom: "2px solid red", width: "100%", marginTop: "30px" }}></div>

        <TableContainer
          component={Paper}
          sx={{
            minWidth: 650,
            marginTop: "20px",
            paddingBottom: "50px",
            marginBottom: "20px",
          }}
        >
          <Table
            sx={{
              minWidth: 650,
              width: "100%",
              justifyContent: "center",
              margin: "auto",
              zIndex: -1,
            }}
            aria-label="simple table"
          >
            <TableBody>
              {props.services.map((service) => (
                <React.Fragment key={"FragmentList" + service.id}>
                  <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                      <ServiceItem
                        key={"ServiceItem" + service.id}
                        school={props.school}
                        service={service}
                        users={props.users.filter((user) => service.users.includes(user.id))}
                        deleteService={props.deleteService}
                        // onDelete={() => RemoveService(service.id)}
                      />
                    </TableCell>
                  </TableRow>

                  {/* <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <b>Sürücü İletişim</b>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {service?.driver?.[0].name || "Sürücü seçilmemiş"} <br /> {service.driver?.[0].phonenumber}
                    </TableCell> */}

                  {/* <TableCell component="th" scope="row">
                      {currentSmses.filter((sms) => sms.user.id.toString() === vehicleRoute.driver[0].id.toString())
                        .length > 0 ? (
                        <div>
                          {currentSmses
                            .filter((sms) => sms.user.id.toString() === vehicleRoute.driver[0].id.toString())
                            .map((sms) => (
                              <div key={sms.id}>
                                {sms.text}
                                <br />
                                <i>{sms.date}</i>
                                <br />
                                <br />
                              </div>
                            ))}
                        </div>
                      ) : (
                        <div>Mesaj Yok</div>
                      )}
                    </TableCell> */}

                  {/* <TableCell scope="row" align="right" width="30%">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <textarea
                          style={{ margin: "16px" }}
                          key={"SMS" + vehicleRoute.driver[0].id}
                          id={"text" + vehicleRoute.driver[0].id.toString().trim()}
                          cols="30"
                          rows="7"
                          maxLength="155"
                          defaultValue={`Yeni servis operasyonu.\nServis: ${vehicleRoute.name}\nTarih: ${
                            loadedItem.transferdate
                          }\nSaat: ${GetStartStopTime(vehicleRoute.starttime)}\nOperasyon Tipi: ${
                            loadedItem.type === 100 ? "Toplama" : "Dağıtma"
                          }`}
                        ></textarea>
                        <div width="60px">
                          <Button type="button" danger onClick={() => SendSMSToDriver(vehicleRoute.driver[0])}>
                            SMS Gönder
                          </Button>
                        </div>
                      </div>
                    </TableCell> */}
                  {/* </TableRow> */}

                  <TableRow>
                    <TableCell component="th" scope="row" colSpan={6}>
                      {/* div with border bottom */}
                      <div style={{ borderBottom: "2px solid red", width: "100%" }}></div>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Personel;
