import React, { useContext, useState, useCallback } from "react";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import DatePicker from "shared/components/FormElements/DatePicker";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";

// #endregion Components

// #region Utils
import { isCompanyReceiptOptions, userApproved } from "shared/util/types";
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";
import { parseComplexDate } from "shared/util/util";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./AccountingItem.css";

const ContractNew = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [serviceStartDate, setServiceStartDate] = useState();
  const [serviceStartDateKey, setServiceStartDateKey] = useState();
  const [serviceEndingDate, setServiceEndingDate] = useState();
  const [serviceEndingDateKey, setServiceEndingDateKey] = useState();
  const [payerName, setPayerName] = useState();
  const [payerNameKey, setPayerNameKey] = useState();
  const [payerIdentity, setPayerIdentity] = useState();
  const [payerIdentityKey, setPayerIdentityKey] = useState();
  const [isInitialCompanyReceipt, setIsInitialCompanyReceipt] = useState(false);
  const [isInitialCompanyReceiptKey, setIsInitialCompanyReceiptKey] = useState(false);
  const [payerAddress, setPayerAddress] = useState();
  const [payerAddressKey, setPayerAddressKey] = useState();
  const [payerMail, setPayerMail] = useState();
  const [payerMailKey, setPayerMailKey] = useState();

  const [taxNo, setTaxNo] = useState();
  const [taxNoKey, setTaxNoKey] = useState();
  const [taxOffice, setTaxOffice] = useState();
  const [taxOfficeKey, setTaxOfficeKey] = useState();
  const [companyTitle, setCompanyTitle] = useState();
  const [companyTitleKey, setCompanyTitleKey] = useState();

  const [totalAmount, setTotalAmount] = useState();
  const [totalAmountKey, setTotalAmountKey] = useState();
  const [educationYear, setEducationYear] = useState();
  const [educationYearKey, setEducationYearKey] = useState();
  const [isCompanyReceipt, setIsCompanyReceipt] = useState(false);
  const [contractDate, setContractDate] = useState(false);
  const [contractDateKey, setContractDateKey] = useState(false);
  const [finalAmount, setFinalAmount] = useState();
  const [finalAmountKey, setFinalAmountKey] = useState();

  const updateStoreItems = store.UpdateContracts;
  const ItemTypeTR = "Sözleşme";
  const APIEndPoint = "contracts";

  const itemSubmitHandler = async (event) => {
    event.preventDefault();

    let specialDiscountToWrite = 0;
    try {
      specialDiscountToWrite = parseInt(formState.inputs.specialDiscount.value.toString().trim());
    } catch (err) {
      specialDiscountToWrite = 0;
    }

    if (specialDiscountToWrite === null) {
      specialDiscountToWrite = 0;
    }

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify({
          payerName: formState.inputs.payerName.value,
          payerIdentity: formState.inputs.payerIdentity.value,
          payerAddress: formState.inputs.payerAddress.value,
          payerMail: formState.inputs.payerMail.value,
          student: formState.inputs.student.value,
          year: formState.inputs.year.value,
          date: formState.inputs.date.value,
          contractEnabled: formState.inputs.contractEnabled.value,
          serviceTemplate: formState.inputs.serviceTemplate.value,
          serviceStartDate: formState.inputs.serviceStartDate.value,
          serviceEndingDate: formState.inputs.serviceEndingDate.value,
          totalAmount: formState.inputs.totalAmount.value,
          isCompanyReceipt: formState.inputs.isCompanyReceipt.value,
          taxNo: formState.inputs.taxNo.value,
          taxOffice: formState.inputs.taxOffice.value,
          companyTitle: formState.inputs.companyTitle.value,
          standardDiscounts: formState.inputs.standardDiscounts.value,
          specialDiscount: specialDiscountToWrite,
          specialDiscountDescription: formState.inputs.specialDiscountDescription.value,
          userApproved: formState.inputs.userApproved.value,
          finalAmount: formState.inputs.finalAmount.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      await updateStoreItems();

      setServiceStartDate(null);
      setServiceStartDateKey(Math.random());
      inputHandler("serviceStartDate", "", false);

      setServiceEndingDate(null);
      setServiceEndingDateKey(Math.random());
      inputHandler("serviceEndingDate", "", false);

      setEducationYear(null);
      setEducationYearKey(Math.random());
      inputHandler("educationYear", "", false);

      setContractDate(null);
      setContractDateKey(Math.random());
      inputHandler("contractDate", "", false);

      setTotalAmount(null);
      setTotalAmountKey(Math.random());
      inputHandler("totalAmount", "", false);

      setFinalAmount(null);
      setFinalAmountKey(Math.random());
      inputHandler("finalAmount", "", false);

      setPayerName(null);
      setPayerNameKey(Math.random());
      inputHandler("payerName", "", true);

      setPayerIdentity("");
      setPayerIdentityKey(Math.random());
      inputHandler("payerIdentity", "", true);

      setPayerAddress(null);
      setPayerAddressKey(Math.random());
      inputHandler("payerAddress", "", true);

      setPayerMail(null);
      setPayerMailKey(Math.random());
      inputHandler("payerMail", "", true);

      setIsInitialCompanyReceipt(null);
      setIsInitialCompanyReceiptKey(Math.random());
      inputHandler("isCompanyReceipt", false, true);

      setTaxNo(null);
      setTaxNoKey(Math.random());
      inputHandler("taxNo", "", true);

      setTaxOffice(null);
      setTaxOfficeKey(Math.random());
      inputHandler("taxOffice", "", true);

      setCompanyTitle(null);
      setCompanyTitleKey(Math.random());
      inputHandler("companyTitle", "", true);
    } catch (err) {}
  };

  const [formState, inputHandler] = useForm(
    {
      payerName: { value: "", isValid: true },
      payerIdentity: { value: "", isValid: true },
      payerAddress: { value: "", isValid: true },
      payerMail: { value: "", isValid: true },
      student: { value: "", isValid: false },
      year: { value: "", isValid: false },
      date: { value: "", isValid: false },
      contractEnabled: { value: true, isValid: true },
      serviceTemplate: { value: "", isValid: true },
      serviceStartDate: { value: "", isValid: false },
      serviceEndingDate: { value: "", isValid: false },
      totalAmount: { value: "", isValid: false },
      finalAmount: { value: "", isValid: false },
      isCompanyReceipt: { value: isCompanyReceipt[0], isValid: true },
      taxNo: { value: "", isValid: true },
      taxOffice: { value: "", isValid: true },
      companyTitle: { value: "", isValid: true },
      standardDiscounts: { value: [], isValid: true },
      specialDiscount: { value: 0, isValid: true },
      specialDiscountDescription: { value: "", isValid: true },
      userApproved: { value: userApproved[0], isValid: true },
    },
    false
  );

  const applyServiceTemplate = useCallback(async () => {
    const templateId = formState.inputs.serviceTemplate.value;
    const currentServiceTemplate = store.serviceTemplates.find((serviceTemplate) => serviceTemplate.id === templateId);
    if (!currentServiceTemplate) {
      return;
    }

    setServiceStartDate(currentServiceTemplate.serviceStartDate);
    setServiceStartDateKey(currentServiceTemplate.serviceStartDate + "-" + Math.random());
    inputHandler("serviceStartDate", currentServiceTemplate.serviceStartDate, true);

    setServiceEndingDate(currentServiceTemplate.serviceEndingDate);
    setServiceEndingDateKey(currentServiceTemplate.serviceEndingDate + "-" + Math.random());
    inputHandler("serviceEndingDate", currentServiceTemplate.serviceEndingDate, true);

    setEducationYear(currentServiceTemplate.year.toString());
    setEducationYearKey(currentServiceTemplate.educationYear + "-" + Math.random());
    inputHandler("educationYear", currentServiceTemplate.educationYear, true);

    const today = new Date();
    const date = parseComplexDate(today);
    setContractDate(date);
    setContractDateKey(date + +Math.random());
    inputHandler("contractDate", currentServiceTemplate.contractDate, true);

    setTotalAmount(currentServiceTemplate.amount);
    setTotalAmountKey(currentServiceTemplate.amount + "-" + Math.random());
    inputHandler("totalAmount", currentServiceTemplate.amount, true);
  }, [formState.inputs.serviceTemplate.value, inputHandler, store.serviceTemplates]);

  const bringPreviousContractOwner = useCallback(async () => {
    const studentId = formState.inputs.student.value;
    const currentStudent = store.students.find((student) => student.id === studentId);
    if (!currentStudent) {
      return;
    } else {
      const previousContract = store.contracts.find(
        (contract) => contract?.student?.id?.toString() === studentId.toString()
      );
      if (!previousContract) {
        return;
      } else {
        setPayerName(previousContract.payerName);
        setPayerNameKey(previousContract.payerName + "-" + Math.random());
        inputHandler("payerName", previousContract.payerName, true);

        setPayerIdentity(previousContract.payerIdentity);
        setPayerIdentityKey(previousContract.payerIdentity + "-" + Math.random());
        inputHandler("payerIdentity", previousContract.payerIdentity, true);

        setPayerAddress(previousContract.payerAddress);
        setPayerAddressKey(previousContract.payerAddress + "-" + Math.random());
        inputHandler("payerAddress", previousContract.payerAddress, true);

        setPayerMail(previousContract.payerMail);
        setPayerMailKey(previousContract.payerMail + "-" + Math.random());
        inputHandler("payerMail", previousContract.payerMail, true);

        setIsInitialCompanyReceipt(previousContract.isCompanyReceipt);
        setIsInitialCompanyReceiptKey(previousContract.isCompanyReceipt + "-" + Math.random());
        inputHandler("isCompanyReceipt", previousContract.isCompanyReceipt, true);

        setTaxNo(previousContract.taxNo);
        setTaxNoKey(previousContract.taxNo + "-" + Math.random());
        inputHandler("taxNo", previousContract.taxNo, true);

        setTaxOffice(previousContract.taxOffice);
        setTaxOfficeKey(previousContract.taxOffice + "-" + Math.random());
        inputHandler("taxOffice", previousContract.taxOffice, true);

        setCompanyTitle(previousContract.companyTitle);
        setCompanyTitleKey(previousContract.companyTitle + "-" + Math.random());
        inputHandler("companyTitle", previousContract.companyTitle, true);

        // inputHandler("payerName", previousContract.payerName, true);
      }
    }
  }, [formState.inputs.student.value, inputHandler, store.contracts, store.students]);

  const calculateFinalAmount = useCallback(async () => {
    const totalAmountHere = parseFloat(formState.inputs.totalAmount.value);
    const specialDiscount = parseFloat(formState.inputs.specialDiscount.value);
    const standardDiscounts = formState.inputs.standardDiscounts.value;

    let calculatedAmount = totalAmountHere;

    standardDiscounts.forEach((discount) => {
      const currentDiscount = store.discounts.find((item) => item.id.toString() === discount.toString());

      //1 --- Percentage Discount
      if (currentDiscount.type === "1") {
        calculatedAmount = calculatedAmount - (calculatedAmount * currentDiscount.amount) / 100;
      }

      //2 --- Constant Discount
      if (currentDiscount.type === "2") {
        calculatedAmount = calculatedAmount - currentDiscount;
      }
    });

    if (specialDiscount) {
      calculatedAmount = calculatedAmount - specialDiscount;
    }
    //setEducationYear(0);
    setFinalAmount(parseInt(calculatedAmount));
    setFinalAmountKey(parseInt(calculatedAmount) + "-" + Math.random());
    inputHandler("finalAmount", parseInt(calculatedAmount), true);
  }, [
    formState.inputs.totalAmount.value,
    formState.inputs.specialDiscount.value,
    formState.inputs.standardDiscounts.value,
    inputHandler,
    store.discounts,
  ]);

  const companyReceiptUIHandler = (value) => {
    setIsCompanyReceipt(value);
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <form className="new-item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        {/* <h3>{ItemTypeTR} Ekle</h3> */}

        <CustomSelect
          options={store.serviceTemplateOptions}
          id="serviceTemplate"
          onInput={inputHandler}
          label="Hizmet Şablonu"
          initialValid={false}
        ></CustomSelect>

        <Button type="button" danger onClick={applyServiceTemplate.bind(this, "apply")}>
          Şablonu Uygula
        </Button>

        <div className="groupContainer">
          <CustomSelect
            options={store.studentOptions}
            id="student"
            onInput={inputHandler}
            label="Öğrenci"
            initialValid={false}
          ></CustomSelect>

          <Button type="button" danger onClick={bringPreviousContractOwner.bind(this, "apply")}>
            Önceki Kontrat Sahibini Getir (Varsa)
          </Button>

          <Input
            key={payerNameKey}
            id="payerName"
            element="input"
            type="text"
            label="Kontrat Sahibi"
            validators={[VALIDATOR_NONE()]}
            initialValid={true}
            onInput={inputHandler}
            initialValue={payerName}
          />

          <Input
            key={payerIdentityKey}
            id="payerIdentity"
            element="input"
            type="text"
            label="Kimlik Numarası"
            validators={[VALIDATOR_NONE()]}
            initialValid={true}
            onInput={inputHandler}
            initialValue={payerIdentity}
          />

          <Input
            key={payerMailKey}
            id="payerMail"
            element="input"
            type="text"
            label="Mail Adresi"
            validators={[VALIDATOR_NONE()]}
            initialValid={true}
            onInput={inputHandler}
            initialValue={payerMail}
          />

          <Input
            key={payerAddressKey}
            id="payerAddress"
            element="input"
            type="text"
            label="İletişim Adresi"
            validators={[VALIDATOR_NONE()]}
            initialValid={true}
            onInput={inputHandler}
            initialValue={payerAddress}
          />

          <CustomSelect
            key={isInitialCompanyReceiptKey}
            options={isCompanyReceiptOptions}
            id="isCompanyReceipt"
            onInput={inputHandler}
            label="Şirket Faturası mı?"
            //initialValue={isCompanyReceiptOptions[0]}
            initialValue={isInitialCompanyReceipt ? isCompanyReceiptOptions[1] : isCompanyReceiptOptions[0]}
            initialValid={true}
            fireChange={companyReceiptUIHandler}
          ></CustomSelect>

          {isCompanyReceipt && (
            <Input
              key={taxNoKey}
              id="taxNo"
              element="input"
              type="text"
              label="Vergi Numarası"
              initialValue={taxNo || ""}
              onInput={inputHandler}
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
            />
          )}

          {isCompanyReceipt && (
            <Input
              key={taxOfficeKey}
              id="taxOffice"
              element="input"
              type="text"
              label="Vergi Dairesi"
              initialValue={taxOffice || ""}
              onInput={inputHandler}
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
            />
          )}
          {isCompanyReceipt && (
            <Input
              key={companyTitleKey}
              id="companyTitle"
              element="input"
              type="text"
              label="Şirket Ünvanı"
              initialValue={companyTitle || ""}
              onInput={inputHandler}
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
            />
          )}
        </div>

        <div className="groupContainer">
          <CustomSelect
            key={educationYearKey}
            options={store.educationYearsOptions}
            id="year"
            onInput={inputHandler}
            label="Eğitim Öğretim Dönemi"
            initialValid={false}
            initialValue={store.educationYearsOptions.find((item) => item.value.toString() === educationYear)}
          ></CustomSelect>

          <DatePicker
            key={contractDateKey}
            id="date"
            onInput={inputHandler}
            label="Sözleşme Tarihi"
            initialValid={contractDate}
            initialValue={contractDate}
          ></DatePicker>

          <DatePicker
            key={serviceStartDateKey}
            id="serviceStartDate"
            onInput={inputHandler}
            label="Hizmet Başlangıç Tarihi"
            initialValid={serviceStartDate}
            initialValue={serviceStartDate}
          ></DatePicker>

          <DatePicker
            key={serviceEndingDateKey}
            id="serviceEndingDate"
            onInput={inputHandler}
            label="Hizmet Bitiş Tarihi"
            validators={[VALIDATOR_REQUIRE()]}
            initialValid={serviceEndingDate}
            initialValue={serviceEndingDate}
          ></DatePicker>
        </div>

        <div className="groupContainer">
          <CustomSelect
            options={store.discountOptions}
            id="standardDiscounts"
            onInput={inputHandler}
            label="Uygulanacak Standart İndirimler"
            initialValid={true}
            isMulti={true}
          ></CustomSelect>

          <Input
            id="specialDiscount"
            element="input"
            type="text"
            label="Özel İndirim Tutarı"
            initialValue="0"
            validators={[VALIDATOR_NONE()]}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="specialDiscountDescription"
            element="input"
            type="text"
            label="Özel İndirim Açıklaması"
            validators={[VALIDATOR_NONE()]}
            initialValid={true}
            initialValue=""
            onInput={inputHandler}
          />
        </div>

        <CustomSelect
          options={userApproved}
          id="userApproved"
          onInput={inputHandler}
          label="Kullanıcı sözleşmeyi onayladı mı? (Sözleşme yaratıldıktan sonra onaya gönderebilirsiniz.)"
          initialValue={userApproved[0]}
          validators={[VALIDATOR_REQUIRE()]}
          initialValid={true}
          disabled
        ></CustomSelect>

        <Input
          key={totalAmountKey}
          id="totalAmount"
          element="input"
          type="text"
          label="Standart Hizmet Ücreti"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Hizmet ücretini giriniz."
          onInput={inputHandler}
          initialValue={totalAmount}
          initialValid={totalAmount}
        />

        <Button type="button" danger onClick={calculateFinalAmount}>
          Ödenecek Ücreti Hesapla
        </Button>

        <Input
          key={finalAmountKey}
          id="finalAmount"
          element="input"
          type="text"
          label="Ödenecek Toplam"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Üstteki hizmet ücretini giriniz."
          onInput={inputHandler}
          initialValid={finalAmount}
          initialValue={finalAmount}
          disabled={true}
        />

        <Button type="submit" disabled={!formState.isValid}>
          Yeni {ItemTypeTR} Yarat
        </Button>
      </form>
    </React.Fragment>
  );
};

export default ContractNew;
