import React from "react";
import {
  DataGrid,
  trTR,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { vehicleroutetypes } from "shared/util/types";
import { trTR as coretrTR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
//import { StoreContext } from 'shared/context/store-context';

const VehiclesList = (props) => {
  //  const store = useContext(StoreContext);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    trTR,
    coretrTR,
    {
      GridCsvExportOptions: { fileName: "gh" },
    }
  );

  const columns = [
    //{ field: 'school', headerName: 'Okul', width: 200, valueGetter: (params) => params.row.school.name},
    //{ field: 'company', headerName: 'Firma', width: 200,valueGetter: (params) => params.row.company.name },
    { field: "plate", headerName: "Plaka", width: 100 },
    { field: "driver", headerName: "İsim", width: 100 },
    { field: "model", headerName: "Model", width: 100 },
    {
      field: "type",
      headerName: "Türü",
      width: 100,
      valueGetter: (params) =>
        vehicleroutetypes.find((item) => item.value.toString() === params.row.type.toString()).label,
    },
  ];

  const OnSelectionChangeHandler = (event) => {
    props.onItemSelect(event[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="itemListContainer">
      <ThemeProvider theme={theme}>
        <DataGrid
          key={props.items}
          density="compact"
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={props.items}
          columns={columns}
          pageSize={100}
          getRowId={(r) => r.id}
          onSelectionModelChange={OnSelectionChangeHandler}
        />
      </ThemeProvider>
    </div>
  );
};

export default VehiclesList;
