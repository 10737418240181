export const userTypesWebsite = [
  { value: 100, label: "Kurum Sorumlusu" },
  { value: 300, label: "Muhasebe" },
  { value: 200, label: "Şirket Admini" },
  { value: 1000, label: "Genel Admin" },
];

export const userTypesDrivers = [{ value: 50, label: "Sürücü" }];

export const userAppUserTypesForSchool = [{ value: 2, label: "Veli" }];

export const userAppUserTypesForCompany = [{ value: 2, label: "Personel" }];

export const userAppUserTypesForShiftCompany = [{ value: 10, label: "Personel" }];

export const usertypes = [
  { value: 2, label: "Veli" },
  { value: 10, label: "Personel" },
  { value: 50, label: "Sürücü" },
  { value: 100, label: "Kurum Sorumlusu" },
  { value: 200, label: "Şirket Admini" },
  { value: 300, label: "Muhasebe" },
  { value: 1000, label: "Admin" },
];

export const usertypesforschool = [
  { value: 2, label: "Veli" },
  { value: 50, label: "Sürücü" },
  { value: 100, label: "Okul Sorumlusu" },
  { value: 200, label: "Şirket Admini" },
  { value: 300, label: "Muhasebe" },
  { value: 1000, label: "Admin" },
];

export const usertypes_app_company = [
  { value: "2", label: "Standart Personel" },
  { value: "10", label: "Vardiyalı Firma Personeli" },
  { value: "50", label: "Sürücü" },
];

export const usertypes_app_school = [
  { value: "2", label: "Veli" },
  { value: "10", label: "Personel" },
  { value: "50", label: "Sürücü" },
];

export const usertypes_website = [
  { value: "100", label: "Kurum Sorumlusu" },
  { value: "200", label: "Şirket Admini" },
  { value: "300", label: "Muhasebe" },
  { value: "1000", label: "Admin" },
];

export const PlayerStates = {
  NOTSTARTED: 0,
  PLAYING: 1,
  PAUSED: 2,
  FINISHED: 3,
};

export const vehicletypes = [{ value: 100, label: "Öğrenci Servisi" }];

export const booleanstatus = [
  { value: false, label: "Kapalı" },
  { value: true, label: "Açık" },
];

export const discountTypes = [
  { value: 1, label: "Yüzde" },
  { value: 2, label: "Meblağ" },
];

export const paymentMethods = [
  { value: 1, label: "Nakit" },
  { value: 2, label: "Kredi Kartı" },
  { value: 3, label: "Mail Order" },
  { value: 4, label: "Banka Kartı" },
  { value: 5, label: "Çek/Senet" },
  { value: 6, label: "Online" },
  { value: 7, label: "EFT" },
  { value: 8, label: "Mahsup Ödeme" },
  { value: 9, label: "Harici Ödeme Yöntemleri" },
];

export const expenseTypes = [
  { value: 1, label: "Çalışan Ödemesi" },
  { value: 2, label: "Yakıt" },
  { value: 3, label: "Kira" },
  { value: 4, label: "Ofis Harcamaları" },
  { value: 5, label: "Satın Alma Ödemesi" },
  { value: 6, label: "Yazılım Sunucu Ödemesi" },
  { value: 7, label: "Kayıt Dışı Harici Ödeme" },
  { value: 8, label: "Diğer" },
];

export const paymentTypes = [
  { value: 1, label: "Taksit Ödemesi" },
  { value: 2, label: "Peşin Ödeme" },
  { value: 3, label: "Ertelenmiş Ödeme" },
];

export const banks = [
  { value: 0, label: "Nakit Ödeme" },
  { value: 1, label: "Vakıfbank" },
  { value: 2, label: "Garanti Bankası" },
  { value: 3, label: "İş Bankası" },
];

export const isOfficial = [
  { value: false, label: "Hayır" },
  { value: true, label: "Evet" },
];

export const TRAVEL_STATUS_TYPE = {
  NOTSTARTED: 1,
  GOING_TO_SCHOOL: 2,
  ARRIVED_SCHOOL: 3,
  PREPARING_AT_SCHOOL: 4,
  LEAVED_SCHOOL: 5,
  FINISHED: 6,
  QR_NEADED: 7,
};

export const TRAVEL_STATUS_TYPE_EXPLANATIONS = [
  { value: TRAVEL_STATUS_TYPE.NOTSTARTED, label: "Başlamadı" },
  { value: TRAVEL_STATUS_TYPE.GOING_TO_SCHOOL, label: "Okula Gidiyor" },
  { value: TRAVEL_STATUS_TYPE.ARRIVED_SCHOOL, label: "Okula Geldi" },
  { value: TRAVEL_STATUS_TYPE.PREPARING_AT_SCHOOL, label: "Yoklama Alıyor" },
  { value: TRAVEL_STATUS_TYPE.LEAVED_SCHOOL, label: "Okuldan Çıktı" },
  { value: TRAVEL_STATUS_TYPE.FINISHED, label: "Tamamlandı" },
  { value: TRAVEL_STATUS_TYPE.QR_NEADED, label: "QR Kod Bekleniyor" },
];

export const TRAVEL_ACTION_TYPE = {
  STARTED: 1,
  APPROACH_TO_HOME: 2,
  STUDENT_GETON_INFO: 3,
  ARRIVED_SCHOOL: 4,
  QR_READED: 5,
  LEAVED_SCHOOL: 6,
  FINISHED: 7,
};

export const TRAVEL_ACTION_TYPE_EXPLANATIONS = [
  { value: TRAVEL_ACTION_TYPE.STARTED, label: "Başlatıldı" },
  {
    value: TRAVEL_ACTION_TYPE.APPROACH_TO_HOME,
    label: "Öğrenci Evine Yaklaşma Bildirimi",
  },
  {
    value: TRAVEL_ACTION_TYPE.STUDENT_GETON_INFO,
    label: "Öğrenci Binmedi Bilgisi",
  },
  { value: TRAVEL_ACTION_TYPE.ARRIVED_SCHOOL, label: "Okula Giriş Yaptı" },
  { value: TRAVEL_ACTION_TYPE.QR_READED, label: "QR Kod Okutuldu" },
  { value: TRAVEL_ACTION_TYPE.LEAVED_SCHOOL, label: "Okuldan Ayrıldı" },
  { value: TRAVEL_ACTION_TYPE.FINISHED, label: "QR Kod Okutuldu" },
];

export const APPROACH_TO_HOME_TYPE = {
  PARENT_INFORMED: 1,
  PARENT_NOT_INFORMED: 2,
  PARENT_NOT_INFORMED_STUDENT_NOTGETON: 3,
};

export const APPROACH_TO_HOME_TYPE_EXPLANATIONS = [
  {
    value: APPROACH_TO_HOME_TYPE.PARENT_INFORMED,
    label: "Araç Yaklaştı Bilgisi",
  },
  {
    value: APPROACH_TO_HOME_TYPE.PARENT_NOT_INFORMED,
    label: "Araç Yaklaştı Bilgisi (Kapalı)",
  },
  {
    value: APPROACH_TO_HOME_TYPE.PARENT_NOT_INFORMED_STUDENT_NOTGETON,
    label: "Araç Yaklaştı Bilgisi (Öğrenci Binmedi)",
  },
];

export const STUDENT_GETON_INFO_TYPE = {
  GETON_PARENT_INFORMED: 1,
  GETON_PARENT_NOT_INFORMED: 2,

  NOT_GETON_PARENT_INFORMED: 3,
  NOT_GETON_PARENT_NOT_INFORMED: 4,

  GETOFF_PARENT_INFORMED: 5,
  GETOFF_PARENT_NOT_INFORMED: 6,
};

export const STUDENT_GETON_INFO_TYPE_EXPLANATIONS = [
  {
    value: STUDENT_GETON_INFO_TYPE.GETON_PARENT_INFORMED,
    label: "Öğrenci Bindi Bilgisi",
  },
  {
    value: STUDENT_GETON_INFO_TYPE.GETON_PARENT_NOT_INFORMED,
    label: "Öğrenci Bindi Bilgisi (Kapalı)",
  },
  {
    value: STUDENT_GETON_INFO_TYPE.NOT_GETON_PARENT_INFORMED,
    label: "Öğrenci Binmedi Bilgisi",
  },
  {
    value: STUDENT_GETON_INFO_TYPE.NOT_GETON_PARENT_NOT_INFORMED,
    label: "Öğrenci Binmedi Bilgisi (Kapalı)",
  },
  {
    value: STUDENT_GETON_INFO_TYPE.GETOFF_PARENT_INFORMED,
    label: "Öğrenci İndi Bilgisi",
  },
  {
    value: STUDENT_GETON_INFO_TYPE.GETOFF_PARENT_NOT_INFORMED,
    label: "Öğrenci İndi Bilgisi (Kapalı)",
  },
];

export const ARRIVED_SCHOOL_TYPE = {
  BUS_ARRIVED: 1,
  PARENT_INFORMED: 2,
  PARENT_NOT_INFORMED: 3,
  PARENT_NOT_INFORMED_STUDENT_NOTGETON: 4,
};

export const ARRIVED_SCHOOL_TYPE_EXPLANATIONS = [
  { value: ARRIVED_SCHOOL_TYPE.BUS_ARRIVED, label: "Okula Giriş Yaptı" },
  { value: ARRIVED_SCHOOL_TYPE.PARENT_INFORMED, label: "Okula Giriş Bilgisi" },
  {
    value: ARRIVED_SCHOOL_TYPE.PARENT_NOT_INFORMED,
    label: "Okula Giriş Bilgisi (Kapalı)",
  },
  {
    value: ARRIVED_SCHOOL_TYPE.PARENT_NOT_INFORMED_STUDENT_NOTGETON,
    label: "Okula Giriş Bilgisi (Öğrenci Binmedi)",
  },
];

export const LEAVED_SCHOOL_TYPE = {
  BUS_LEAVED: 1,
  PARENT_INFORMED: 2,
  PARENT_NOT_INFORMED: 3,
};

export const vehicleroutetypes = [
  { value: 100, label: "Toplama" },
  { value: 200, label: "Dağıtma" },
];

export const personelServiceRouteTypes = [
  { value: "100", label: "Toplama" },
  { value: "200", label: "Dağıtma" },
];

export const schooltypes = [
  { value: 1, label: "Okul" },
  { value: 2, label: "Personel" },
];

export const qrcodetypes = [
  { value: 1, label: "Evet" },
  { value: 2, label: "Hayır" },
];

export const stationtypes = [
  { value: 1, label: "Personel Durağı" },
  { value: 2, label: "Bağımsız Durak" },
];

export const transfertypes = [
  { value: 100, label: "Toplama" },
  { value: 200, label: "Bırakma" },
];

export const notificationtypes = [
  { value: 1, label: "Uygulama İçi" },
  { value: 2, label: "Uygulama İçi" },
  { value: 3, label: "Uygulama İçi" },
];

export const notificationmethods = [
  { value: 1, label: "Süre" },
  { value: 2, label: "Uzaklık" },
];

export const durations = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
];

export const distances = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
];

export const isCompanyReceiptOptions = [
  { value: false, label: "Hayır" },
  { value: true, label: "Evet" },
];

export const userApproved = [
  { value: false, label: "Hayır" },
  { value: true, label: "Evet" },
];

export const sendUserApprovel = [
  { value: false, label: "Hayır" },
  { value: true, label: "Evet" },
];

export const yesNoOptions = [
  { value: true, label: "Evet" },
  { value: false, label: "Hayır" },
];

export const isActiveOptions = [
  { value: false, label: "Pasif" },
  { value: true, label: "Aktif" },
];

export const transferStatus = [
  { value: false, label: "Aktif" },
  { value: true, label: "Tamamlandı" },
];

export const languages = [
  {
    code: "en",
    name: "English",
    country_code: "us",
  },
  {
    code: "tr",
    name: "Türkçe",
    country_code: "tr",
  },
];

export const days = [
  { value: "1", label: "Pazartesi" },
  { value: "2", label: "Salı" },
  { value: "3", label: "Çarşamba" },
  { value: "4", label: "Perşembe" },
  { value: "5", label: "Cuma" },
  { value: "6", label: "Cumartesi" },
  { value: "7", label: "Pazar" },
];

export const days_en = [
  { value: "1", label: "Monday" },
  { value: "2", label: "Tuesday" },
  { value: "3", label: "Wednesday" },
  { value: "4", label: "Thursday" },
  { value: "5", label: "Friday" },
  { value: "6", label: "Saturday" },
  { value: "7", label: "Sunday" },
];
