import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import Marker from "shared/components/UIElements/Marker";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";

import { isActiveOptions } from "shared/util/types";

import { LocationFromAddress, AddressFromLocation } from "shared/util/location";

// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const StudentItem = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);

  const [itemstate, setItemState] = useState();
  const [loadedItem, setLoadedItem] = useState();
  const [openError, setOpenError] = React.useState(false);

  const [markerLocationMorning, setMarkerLocationMorning] = useState();
  const [mapCenterMorning, setMapCenterMorning] = useState();
  const [studentAddressMorning, setStudentAddressMorning] = useState();

  const [markerLocationEvening, setMarkerLocationEvening] = useState();
  const [mapCenterEvening, setMapCenterEvening] = useState();
  const [studentAddressEvening, setStudentAddressEvening] = useState();

  const [showHelp, setShowHelp] = useState(false);

  const itemId = props.itemid;
  const updateStoreItems = store.UpdateStudents;

  const ItemTypeTR = "Öğrenci";
  const ItemType = "student";
  const ItemEndPointType = "students";

  // #region Standard Interface functions
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  // #endregion Standard Interface functions

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL + `/${ItemEndPointType}/${itemId}`);
      setLoadedItem(responseData[ItemType]);
      setMarkerLocationMorning(responseData[ItemType].locationgeton);
      setMapCenterMorning(responseData[ItemType].locationgeton);
      setStudentAddressMorning(responseData[ItemType].addressgeton);

      setMarkerLocationEvening(responseData[ItemType].locationgetoff);
      setMapCenterEvening(responseData[ItemType].locationgetoff);
      setStudentAddressEvening(responseData[ItemType].addressgetoff);

      setItemState(Date.now());
    } catch (err) {}
  }, [sendRequest, itemId]);

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      await sendRequest(process.env.REACT_APP_BACKEND_URL + `/${ItemEndPointType}/${itemId}`, "DELETE", null, {
        "Content-Type": "application/json",
      });
      setItemState(Date.now());
      updateStoreItems();
      setLoadedItem(null);
      navigate(`/${ItemEndPointType}`);
    } catch (err) {}
  };
  // #endregion Standard API calls

  useEffect(() => {
    fetchItem();
    // eslint-disable-next-line
  }, [itemId]);

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: true },
      identifier: { value: "", isValid: true },
      name: { value: "", isValid: true },
      phonenumber: { value: null, isValid: true },
      level: { value: "", isValid: true },
      email: { value: null, isValid: true },
      addressgeton: { value: null, isValid: true },
      addressgetoff: { value: null, isValid: true },
      isActive: { value: "", isValid: true },
    },
    true
  );

  // eslint-disable-next-line
  const itemUpdateSubmitHandler = useCallback(async (event) => {
    event.preventDefault();
    let responseData;
    try {
      responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/${ItemEndPointType}/${itemId}`,
        "PATCH",
        JSON.stringify({
          school: formState.inputs.school.value,
          identifier: formState.inputs.identifier.value,
          name: formState.inputs.name.value,
          phonenumber: formState.inputs.phonenumber.value,
          email: formState.inputs.email.value,
          level: formState.inputs.level.value,
          addressgeton: formState.inputs.addressgeton.value,
          locationgeton: markerLocationMorning,
          addressgetoff: formState.inputs.addressgetoff.value,
          locationgetoff: markerLocationEvening,
          isActive: formState.inputs.isActive.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      setLoadedItem(responseData[ItemType]);
      setItemState(Date.now());
      updateStoreItems();

      fetchItem();

      //navigate(`/${ItemEndPointType}`);
    } catch (err) {}
  });

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return (
      <div className="center">
        <Card>
          <h2>{ItemTypeTR} bulunamadı.</h2>
        </Card>
      </div>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const mapClickHandler = async (routetype, event) => {
    const currentLocation = { lat: event.lat, lng: event.lng };
    let address = null;
    if (routetype === "geton") {
      try {
        address = await AddressFromLocation(currentLocation);
      } catch (error) {
        setOpenError(true);
      }
      if (address) {
        setMarkerLocationMorning(currentLocation);
        setStudentAddressMorning(address);
        inputHandler("addressgeton", address, true);
      } else {
        setOpenError(true);
        setStudentAddressMorning("-");
        inputHandler("addressgeton", "-", false);
      }
    }

    if (routetype === "getoff") {
      try {
        address = await AddressFromLocation(currentLocation);
      } catch (error) {
        setOpenError(true);
      }
      if (address) {
        setMarkerLocationEvening(currentLocation);
        setStudentAddressEvening(address);
        inputHandler("addressgetoff", address, true);
      } else {
        setOpenError(true);
        setStudentAddressEvening("-");
        inputHandler("addressgetoff", "-", false);
      }
    }
  };

  const SetLocationFromAddress = async (routetype) => {
    let location;
    if (routetype === "geton") {
      try {
        location = await LocationFromAddress(formState.inputs.addressgeton.value);
      } catch (error) {
        setMarkerLocationMorning(null);
        setOpenError(true);
        return;
      }
      setMarkerLocationMorning(location);
      setMapCenterMorning(location);
    }
    if (routetype === "getoff") {
      try {
        location = await LocationFromAddress(formState.inputs.addressgetoff.value);
      } catch (error) {
        setMarkerLocationEvening(null);
        setOpenError(true);
        return;
      }
      setMarkerLocationEvening(location);
      setMapCenterEvening(location);
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Emin misiniz?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              Hayır
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>{ItemTypeTR} silme işlemini onaylıyor musunuz? Bu işlem geri alınamaz.</p>
      </Modal>

      <Modal
        show={showHelp}
        header="Yardım"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button danger onClick={() => setShowHelp(false)}>
              Tamam
            </Button>
          </React.Fragment>
        }
      >
        <p>
          <ul>
            <li>
              <b>Açıklama</b> maddesi aynı isimli öğrencilerinizi ayırt etmek için kullanılır. Bu adıma "Öğrenci Ad
              Soyad - Veli Ad Soyad" bilgilerini yazmanızı öneririz.
            </li>
            <br />
            <li>
              <b>Öğrenci adres bilgileri</b> kısmında öğrencinin biniş ve iniş adreslerini giriniz. Adres aynı olsa dahi
              bu bilginin hem biniş, hem iniş adımlarına girilmesi gerekmektedir.
            </li>
            <br />
            <li>
              <b>Biniş/İniş adresinden konum getir</b> tuşlarına basmanız ve haritalar üzerinde öğrencinin adresinin
              doğru şekilde konumlandığını kontrol etmeniz gerekmektedir. Eğer harita üzerinde adresiniz doğru
              konumlandırılmamışsa öğrenci servis uygulamasını <b>kullanamayacaktır</b>.
            </li>
            <br />
            <li>
              <b>Öğrenci eğer artık okulunuz bünyesinde servis kullanmayacaksa </b> Durum bilgisini Pasif olarak
              işaretleyiniz. Pasif durumdaki öğrenciler yukarıdaki <b>Analiz</b> sekmesindeki adımlarda dikkate
              alınmazlar. Bir öğrenci okulunuzdan ayrıldığında öğrenciyi silmek yerine kullanım durumunu pasif'e
              çekmeniz gerekmektedir. Böylece öğrenciye ait geçmiş muhasebe bilgilerine ulaşabilirsiniz ve öğrencinin
              okulunuza geri dönmesi durumunda tekrar kayıt oluşturmanıza gerek kalmaz.
            </li>
            <br />
            <li>
              <b>Öğrenci silme işlemini </b> yalnızca çok bariz bir şekilde hatalı giriş yaptığınızda son seçenek olarak
              kullanınız. Daha önce servis kullanmış, sözleşme ve muhasebe kayıtları oluşturulmuş öğrencileri silmek
              yerine pasif duruma çekmeniz gerekmektedir.
            </li>
          </ul>
        </p>
      </Modal>

      <Snackbar
        open={openError}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Adres-Konum eşleşmesi bulunamadı.
        </MuiAlert>
      </Snackbar>

      {!isLoading && loadedItem && studentAddressMorning && (
        <form key={itemstate} className="item-form" onSubmit={itemUpdateSubmitHandler}>
          <div className="button-container">
            <Button type="button" danger onClick={props.onClose}>
              &#60; Geri
            </Button>
            <Button type="button" onClick={() => setShowHelp(true)}>
              Yardım
            </Button>
          </div>

          <CustomSelect
            options={store.schoolOptions}
            id="school"
            onInput={inputHandler}
            label="Okul*"
            initialValue={store.schoolOptions.find(
              (option) => option.value.toString() === loadedItem.school.toString()
            )}
            validators={[VALIDATOR_REQUIRE()]}
            initialValid={true}
          ></CustomSelect>

          <Input
            id="name"
            element="input"
            type="text"
            label="İsim*"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="İsim giriniz."
            initialValue={loadedItem.name}
            initialValid={true}
            onInput={inputHandler}
          />
          <Input
            id="identifier"
            element="input"
            type="text"
            label="Açıklama*"
            validators={[VALIDATOR_REQUIRE()]}
            initialValue={loadedItem.identifier}
            initialValid={true}
            onInput={inputHandler}
            errorText="Zorunlu alan. Her öğrenci için ayırıcı bir metin olmalı. Sadece yöneticilere görünecek bilgi."
          />
          <CustomSelect
            options={isActiveOptions}
            id="isActive"
            onInput={inputHandler}
            label="Durum"
            initialValue={
              isActiveOptions.find((option) => option.value.toString() === loadedItem.isActive.toString())
                ? isActiveOptions.find((option) => option.value.toString() === loadedItem.isActive.toString())
                : isActiveOptions[1]
            }
            validators={[VALIDATOR_REQUIRE()]}
            initialValid={true}
          ></CustomSelect>
          <Input
            id="level"
            element="input"
            type="text"
            label="Sınıf"
            initialValue={loadedItem.level}
            validators={[VALIDATOR_NONE()]}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            key={studentAddressMorning + "_geton"}
            id="addressgeton"
            element="input"
            type="text"
            label="Biniş Adresi*"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Adres giriniz."
            // initialValue={loadedItem.address}
            initialValue={studentAddressMorning}
            initialValid={true}
            onInput={inputHandler}
          />

          <Button type="button" danger o onClick={SetLocationFromAddress.bind(this, "geton")}>
            Biniş Adresinden Konumu Getir
          </Button>

          {markerLocationMorning && (
            <div className="map-container">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
                }}
                //defaultCenter={markerLocationMorning}
                options={function (maps) {
                  return {
                    mapTypeControl: true,
                    mapTypeId: "roadmap",
                    mapTypeControlOptions: {
                      mapTypeIds: ["satellite", "roadmap"],
                      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                      position: maps.ControlPosition.TOP_LEFT,
                    },
                  };
                }}
                center={mapCenterMorning}
                defaultZoom={16}
                onClick={mapClickHandler.bind(this, "geton")}
              >
                <Marker Icon={RoomIcon} lat={markerLocationMorning.lat} lng={markerLocationMorning.lng} />
              </GoogleMapReact>
              {/* <Map center={GetCoordinates(loadedItem.address)} zoom={16} /> */}
            </div>
          )}

          <Input
            key={studentAddressEvening + "_getoff"}
            id="addressgetoff"
            element="input"
            type="text"
            label="İniş Adresi*"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Adres giriniz."
            // initialValue={loadedItem.address}
            initialValue={studentAddressEvening}
            initialValid={true}
            onInput={inputHandler}
          />

          <Button type="button" danger onClick={SetLocationFromAddress.bind(this, "getoff")}>
            İniş Adresinden Konumu Getir
          </Button>

          {markerLocationEvening && (
            <div className="map-container">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
                }}
                //defaultCenter={markerLocationMorning}
                options={function (maps) {
                  return {
                    mapTypeControl: true,
                    mapTypeId: "roadmap",
                    mapTypeControlOptions: {
                      mapTypeIds: ["satellite", "roadmap"],
                      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                      position: maps.ControlPosition.TOP_LEFT,
                    },
                  };
                }}
                center={mapCenterEvening}
                defaultZoom={15}
                onClick={mapClickHandler.bind(this, "getoff")}
              >
                <Marker Icon={RoomIcon} lat={markerLocationEvening.lat} lng={markerLocationEvening.lng} />
              </GoogleMapReact>
              {/* <Map center={GetCoordinates(loadedItem.address)} zoom={16} /> */}
            </div>
          )}

          <Input
            id="phonenumber"
            element="input"
            type="text"
            label="Telefon"
            //errorText="Telefon giriniz."
            validators={[VALIDATOR_NONE()]}
            initialValue={loadedItem.phonenumber}
            initialValid={true}
            onInput={inputHandler}
          />
          <Input
            id="email"
            element="input"
            type="text"
            label="Email"
            errorText="Email giriniz."
            onInput={inputHandler}
            initialValue={loadedItem.email}
            initialValid={true}
            validators={[VALIDATOR_NONE()]}
          />
          <div className="button-container">
            <Button type="submit" disabled={!formState.isValid}>
              Güncelle
            </Button>
            <Button type="button" danger onClick={showDeleteWarningHandler}>
              SİL
            </Button>
          </div>
        </form>
      )}
    </React.Fragment>
  );
};

export default StudentItem;
