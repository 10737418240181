import React, { useContext, useState } from "react";
import {
  DataGrid,
  trTR,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import "./AccountingList.css";
import { trTR as coretrTR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StoreContext } from "shared/context/store-context";

const ContractList = (props) => {
  const visibilityJSON = localStorage.getItem("contracts_visibility");
  const visibilityModel = visibilityJSON ? JSON.parse(visibilityJSON) : {};
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(visibilityModel);
  const filterJSON = localStorage.getItem("contracts_filter");
  const initialFilterModel = filterJSON ? JSON.parse(filterJSON) : { items: [] };
  const [filterModel, setFilterModel] = useState(initialFilterModel);

  const store = useContext(StoreContext);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    trTR,
    coretrTR,
    {
      GridCsvExportOptions: { fileName: "gh" },
    }
  );

  const columns = [
    {
      field: "school",
      headerName: "Okul",
      width: 300,
      valueGetter: (params) =>
        store.schoolOptions.find((item) => item.value.toString() === params.row.student.school.toString()).label,
    },
    {
      field: "year",
      headerName: "Yıl",
      width: 150,
      valueGetter: (params) =>
        store.educationYearsOptions.find((item) => item.value.toString() === params.row.year.toString()).label,
    },
    { field: "payerName", headerName: "Fatura İsmi", width: 300 },
    {
      field: "student",
      headerName: "Öğrenci",
      width: 300,
      valueGetter: (params) => {
        try {
          return store.students.find((item) => item.id.toString() === params.row.student.id.toString()).name;
        } catch {
          return "";
        }
      },
    },
    { field: "finalAmount", headerName: "Ücret", width: 100 },

    {
      field: "totalPayed",
      headerName: "Ödenen",
      width: 100,
      valueGetter: (params) => {
        try {
          const contractId = store.contractsOptions.find(
            (item) => item.value.toString() === params.row.id.toString()
          ).value;

          const allreceipts = store.receipts.filter((item) => item.contract.toString() === contractId);

          let totalPayed = 0;
          allreceipts.forEach((item) => {
            totalPayed += item.amount;
          });

          return totalPayed;
        } catch (e) {
          return "";
        }
      },
    },

    {
      field: "totalBorc",
      headerName: "Kalan",
      width: 100,
      valueGetter: (params) => {
        try {
          const contractId = store.contractsOptions.find(
            (item) => item.value.toString() === params.row.id.toString()
          ).value;

          const allreceipts = store.receipts.filter((item) => item.contract.toString() === contractId);

          let totalPayed = 0;
          allreceipts.forEach((item) => {
            totalPayed += item.amount;
          });

          return parseFloat(params.row.finalAmount) - totalPayed;
        } catch (e) {
          return "";
        }
      },
    },

    {
      field: "userApproved",
      headerName: "Onay",
      width: 75,
      valueGetter: (params) => (params.row.userApproved === true ? "Evet" : "Hayır"),
    },

    { field: "payerIdentity", headerName: "Kimlik No", width: 75, hide: true },
    { field: "payerMail", headerName: "Mail", width: 75, hide: true },
    { field: "payerAddress", headerName: "Adres", width: 150, hide: true },
    {
      field: "isCompanyReceipt",
      headerName: "Şirket mi",
      width: 75,
      valueGetter: (params) => (params.row.isCompanyReceipt === true ? "Evet" : "Hayır"),
      hide: true,
    },

    { field: "companyTitle", headerName: "Şirket İsmi", width: 150, hide: true },
    { field: "taxNo", headerName: "Vergi No", width: 75, hide: true },
    { field: "taxOffice", headerName: "Vergi Dairesi", width: 75, hide: true },
  ];

  const OnSelectionChangeHandler = (event) => {
    props.onItemSelect(event[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="itemListContainer">
      <ThemeProvider theme={theme}>
        <DataGrid
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            localStorage.setItem("contracts_visibility", JSON.stringify(newModel));
            setColumnVisibilityModel(newModel);
          }}
          filterModel={filterModel}
          onFilterModelChange={(newModel) => {
            localStorage.setItem("contracts_filter", JSON.stringify(newModel));
            setFilterModel(newModel);
          }}
          key={props.items}
          density="compact"
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={props.items}
          columns={columns}
          pageSize={100}
          getRowId={(r) => r.id}
          onSelectionModelChange={OnSelectionChangeHandler}
        />
      </ThemeProvider>
    </div>
  );
};

export default ContractList;
