import React, { useContext, useState } from "react";
import {
  DataGrid,
  trTR,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import "./AccountingList.css";
import { trTR as coretrTR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StoreContext } from "shared/context/store-context";

const ReportVehicleList = (props) => {
  const visibilityJSON = localStorage.getItem("reportvehicle_visibility");
  const visibilityModel = visibilityJSON ? JSON.parse(visibilityJSON) : {};
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(visibilityModel);
  const filterJSON = localStorage.getItem("reportvehicle_filter");
  const initialFilterModel = filterJSON ? JSON.parse(filterJSON) : { items: [] };
  const [filterModel, setFilterModel] = useState(initialFilterModel);

  const store = useContext(StoreContext);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    trTR,
    coretrTR,
    {
      GridCsvExportOptions: { fileName: "gh" },
    }
  );

  let columns = [
    //{
    //   field: 'school',
    //   headerName: 'Okul',
    //   width: 300,
    //   valueGetter: (params) =>
    //     store.schoolOptions.find(
    //       (item) => item.value.toString() === params.row.school.toString()
    //     ).label,
    // },
    // {
    //   field: 'vehiclePayment',
    //   headerName: 'Rota',
    //   width: 400,
    //   valueGetter: (params) =>
    //     store.vehicleRouteOptions.find(
    //       (item) => item.value.toString() === params.row.vehicleRoute.toString()
    //     ).label,
    // },

    {
      field: "vehiclePayment",
      headerName: "Araç Sahibi",
      width: 300,
      valueGetter: (params) => params.row.vehiclePayment.owner,
    },
    {
      field: "vehicleRoute",
      headerName: "Servis",
      width: 300,
      valueGetter: (params) => {
        const schoolName = store.schoolOptions.find(
          (item) => item.value.toString() === params.row.vehiclePayment.school.toString()
        ).label;
        //return (schoolName + " - " + params.row.route.name + " - " + routeType)
        return schoolName + " - " + params.row.route.name;
      },
    },
    { field: "studentCount", headerName: "Öğrenci Sayısı", width: 150 },
    {
      field: "vehicleCost",
      headerName: "Maliyet",
      width: 150,
      valueGetter: (params) => params.row.vehiclePayment.amount,
    },
    { field: "totalAmount", headerName: "Gelir", width: 150 },
    {
      field: "netAmount",
      headerName: "Kazanç",
      width: 150,
      valueGetter: (params) => params.row.totalAmount - params.row.vehiclePayment.amount,
    },

    // { field: 'amount', headerName: 'Ücret', width: 100 },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="itemListContainer">
      <ThemeProvider theme={theme}>
        <DataGrid
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            localStorage.setItem("reportvehicle_visibility", JSON.stringify(newModel));
            setColumnVisibilityModel(newModel);
          }}
          filterModel={filterModel}
          onFilterModelChange={(newModel) => {
            localStorage.setItem("reportvehicle_filter", JSON.stringify(newModel));
            setFilterModel(newModel);
          }}
          key={props.items}
          density="compact"
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={props.items}
          columns={columns}
          pageSize={100}
          getRowId={(r) => r.id}
        />
      </ThemeProvider>
    </div>
  );
};

export default ReportVehicleList;
