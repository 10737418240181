import React, { useContext, useState } from "react";
import {
  DataGrid,
  trTR,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { trTR as coretrTR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StoreContext } from "shared/context/store-context";

const StudentsList = (props) => {
  const visibilityJSON = localStorage.getItem("students_visibility");
  const visibilityModel = visibilityJSON ? JSON.parse(visibilityJSON) : {};
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(visibilityModel);
  const filterJSON = localStorage.getItem("students_filter");
  const initialFilterModel = filterJSON ? JSON.parse(filterJSON) : { items: [] };
  const [filterModel, setFilterModel] = useState(initialFilterModel);

  const store = useContext(StoreContext);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    trTR,
    coretrTR,
    {
      GridCsvExportOptions: { fileName: "gh" },
    }
  );

  const columns = [
    {
      field: "school",
      headerName: "Kurum",
      width: 350,
      valueGetter: (params) =>
        store.schoolOptions.find((school) => school.value === params.row.school.toString()).label,
    },
    {
      field: "name",
      headerName: "İsim",
      width: 350,
    },
    {
      field: "level",
      headerName: "Sınıf",
      width: 100,
      valueGetter: (params) => {
        try {
          return params.row.level.toString();
        } catch {
          return "";
        }
      },
    },
    {
      field: "routemorning",
      headerName: "Toplama Servisi",
      width: 250,
      valueGetter: (params) => {
        //store.studentRoutes.find((studentRoute) => studentRoute.id === params.row.id.toString()).routemorning,
        try {
          const studentroutes = store.vehicleRoutes.filter((route) =>
            route.student.map((student) => student.id).includes(params.row.id.toString())
          );
          if (studentroutes.length === 0) {
            return "-";
          } else {
            return store.vehicleRoutes.filter((route) =>
              route.student.map((student) => student.id).includes(params.row.id.toString())
            )[0].name;
          }
        } catch {
          return "";
        }
      },
    },
    {
      field: "routeevening",
      headerName: "Dağıtma Servisi",
      width: 250,
      valueGetter: (params) => {
        // store.studentRoutes.find((studentRoute) => studentRoute.id === params.row.id.toString()).routeevening,
        try {
          const studentroutes = store.vehicleRoutes.filter((route) =>
            route.student.map((student) => student.id).includes(params.row.id.toString())
          );
          if (studentroutes.length === 0) {
            return "-";
          } else {
            return store.vehicleRoutes.filter((route) =>
              route.student.map((student) => student.id).includes(params.row.id.toString())
            )[0].name;
          }
        } catch {
          return "";
        }
      },
    },
    { field: "identifier", headerName: "Açıklama", width: 450 },
    {
      field: "isActive",
      headerName: "Durum",
      width: 100,
      valueGetter: (params) => {
        const student = store.students.find((student) => student.id.toString() === params.row.id.toString());
        if (!student.hasOwnProperty("isActive")) {
          return "Aktif";
        } else {
          return student.isActive ? "Aktif" : "Pasif";
        }
      },
    },

    {
      field: "phonenumber",
      headerName: "Telefon",
      width: 350,
      hide: true,
    },
    {
      field: "addressgeton",
      headerName: "Biniş Adresi",
      width: 150,
      hide: true,
    },
    {
      field: "addressgetoff",
      headerName: "İniş Adresi",
      width: 100,
      hide: true,
    },
  ];

  const OnSelectionChangeHandler = (event) => {
    props.onItemSelect(event[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="itemListContainer">
      <ThemeProvider theme={theme}>
        <DataGrid
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            localStorage.setItem("students_visibility", JSON.stringify(newModel));
            setColumnVisibilityModel(newModel);
          }}
          filterModel={filterModel}
          onFilterModelChange={(newModel) => {
            localStorage.setItem("students_filter", JSON.stringify(newModel));
            setFilterModel(newModel);
          }}
          key={props.items}
          density="compact"
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={props.items}
          columns={columns}
          pageSize={100}
          getRowId={(r) => r.id}
          onSelectionModelChange={OnSelectionChangeHandler}
        />
      </ThemeProvider>
    </div>
  );
};

export default StudentsList;
