import React, { useCallback, useEffect } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";

import { RiLockPasswordLine } from "react-icons/ri";

import Card from "shared/components/UIElements/Card";
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import Link from "shared/components/FormElements/Link";
import { VALIDATOR_MINLENGTH } from "shared/util/validators";
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { queryStringToObject } from "shared/util/util";
import "./Auth.css";

const Auth = () => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoading, sendRequest } = useHttpClient();

  const [showPasswordNotSame, setShowPasswordNotSame] = React.useState(false);

  const [showRecoverFailed, setShowRecoverFailed] = React.useState(false);

  const [recoverFailMessage, setRecoverFailMessage] = React.useState("");
  const [link, setLink] = React.useState(null);

  const [formState, inputHandler] = useForm(
    {
      password1: {
        value: "",
        isValid: false,
      },
      password2: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const checkLink = useCallback(async () => {
    let language = (i18next?.language || window?.localStorage?.i18nextLng || "en").substring(0, 2);
    const queryParams = queryStringToObject(search, { link: "" });
    if (queryParams?.link) {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/checkresetpassword`,
          "POST",
          JSON.stringify({
            link: queryParams?.link,
            language: language,
          }),
          {
            "Content-Type": "application/json",
          }
        );
        if (responseData.error) {
          localStorage.setItem("GenericError", responseData.error);
          navigate("/reset-password");
          return;
        }
        setLink(queryParams?.link);
      } catch (err) {
        return;
      }
    } else {
      setLink(null);
      setShowRecoverFailed(true);
      setRecoverFailMessage(t("invalidLink"));
    }
  }, [search, sendRequest, t]);

  useEffect(() => {
    checkLink();
  }, [search, checkLink]);

  const onHandleInput = () => {
    setShowPasswordNotSame(false);
    setShowRecoverFailed(false);
  };

  const recoverSubmitHandler = async (event) => {
    event.preventDefault();

    if (formState.inputs.password1.value !== formState.inputs.password2.value) {
      setShowPasswordNotSame(true);
      setShowRecoverFailed(false);
      return;
    }

    let language = (i18next?.language || window?.localStorage?.i18nextLng || "en").substring(0, 2);
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/recoverPassword`,
        "POST",
        JSON.stringify({
          link,
          password: formState.inputs.password1.value,
          language: language,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.error) {
        localStorage.setItem("GenericError", responseData.error);
        navigate("/reset-password");
        return;
      }
      localStorage.setItem("GenericError", t("yourPasswordHasBeenChanged"));
      navigate("/login");
    } catch (err) {
      setShowRecoverFailed(true);
      setRecoverFailMessage(err.message);
    }
  };

  return (
    <React.Fragment>
      <Card className="authentication">
        <RiLockPasswordLine className="auth-main-icon" />
        <h2>{t("resetPassword")}</h2>
        <hr />
        <form onSubmit={recoverSubmitHandler}>
          <Input
            element="input"
            id="password1"
            type="password"
            label={t("password")}
            validators={[VALIDATOR_MINLENGTH(6)]}
            onInput={inputHandler}
            onChange={onHandleInput}
            disabled={isLoading || !link}
          />

          <Input
            element="input"
            id="password2"
            type="password"
            label={t("passwordAgain")}
            validators={[VALIDATOR_MINLENGTH(6)]}
            onInput={inputHandler}
            onChange={onHandleInput}
            disabled={isLoading || !link}
          />

          <Button type="submit" isLoading={isLoading} disabled={!formState.isValid || !link}>
            {t("send")}
          </Button>

          <div className="auth-bottom-container">
            <div className="auth-messages-container">
              {showPasswordNotSame && (
                <div className="login-failed">
                  <p>{t("passwordsNotSame")}</p>
                </div>
              )}
              {showRecoverFailed && (
                <div className="login-failed">
                  <p>{recoverFailMessage}</p>
                </div>
              )}
            </div>
            <div className="auth-links-container">
              <Link to="/login" styles={{ float: "left", fontWeight: "bold" }}>
                {t("tryLogin")}
              </Link>
              <Link to="/sign-up" styles={{ float: "right", fontWeight: "bold" }}>
                {t("signup")}
              </Link>
            </div>
          </div>
        </form>
      </Card>
    </React.Fragment>
  );
};

export default Auth;
