import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, RouterProvider, Route, Link } from "react-router-dom";
import * as ReactDOM from "react-dom/client";

import "./index.css";
import "components/Item.css";
import "components/List.css";
import App from "./App";
import "./i18n";

const root = createRoot(document.getElementById("root"));

root.render(<BrowserRouter>{<App />}</BrowserRouter>);
// ReactDOM.render(
//   <Suspense fallback="loading">
//     {" "}
//     <App />{" "}
//   </Suspense>,
//   document.getElementById("root")
// );
