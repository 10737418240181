import React, { useState } from "react";
import Modal from "shared/components/UIElements/Modal";

import Button from "shared/components/FormElements/Button";

import "./Help.css";

const Help = ({ standardHelpItems, highlightedHelpItems }) => {
  const [showHelpModal, setShowHelpModal] = useState(false);

  return (
    <React.Fragment>
      <Modal
        show={showHelpModal}
        onCancel={() => setShowHelpModal(false)}
        header="Yardım"
        footerClass="shared-help-footer"
        footer={
          <React.Fragment>
            <Button inverse onClick={() => setShowHelpModal(false)}>
              Kapat
            </Button>
          </React.Fragment>
        }
      >
        <div className="shared-help-content">
          {standardHelpItems && (
            <div>
              <h4 style={{ marginTop: "0" }}>Bu sayfada yapabileceğiniz işlemler</h4>
              {standardHelpItems.map((item) => (
                <p>{item}</p>
              ))}
            </div>
          )}
          {highlightedHelpItems && (
            <div>
              <h4 style={{ marginTop: "2rem", color: "red" }}>Dikkat edilmesi gereken noktalar</h4>
              {highlightedHelpItems.map((item) => (
                <p>{item}</p>
              ))}
            </div>
          )}
        </div>
      </Modal>
      <Button danger size="small" onClick={() => setShowHelpModal(true)}>
        Yardım
      </Button>
    </React.Fragment>
  );
};

export default Help;
