/*eslint-disable eqeqeq*/
import React, { useEffect } from "react";
import { Router, Route, Routes, Redirect } from "react-router-dom";
import i18next from "i18next";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";

import Accounting from "components/accounting/Accounting";
import Analysis from "components/analysis/AnalysisManagement";

import Live from "pages/Live";
import LiveItem from "components/live/LiveItem";

import Shifts from "pages/Shifts";
import Stops from "pages/Stops";

import StandardPersonelServices from "pages/StandardPersonelServices";
import ShiftPersonelServices from "pages/ShiftPersonelServices";

import Users from "pages/Users";
import NewUserItem from "components/user/NewUserItem";

import UsersWebsite from "pages/UsersWebsite";

//import Personels from './admin/pages/Personels';
//import NewUserItem from './admin/components/NewUserItem';
import TransferManagement from "components/employee/TransferManagement";
import Transfer from "components/employee/Transfer";
import TransferNew from "components/employee/TransferNew";
import TransferRegionNew from "components/employee/TransferRegionNew";

import Ikarus from "components/ikarus/IkarusManagement";

import PLocation from "components/employee/PLocation";
import PLocationNew from "components/employee/PLocationNew";

import Students from "pages/Students";
import NewStudentItem from "components/student/NewStudentItem";

import Relations from "pages/Relations";
import NewRelationItem from "components/relation/NewRelationItem";

import Schools from "pages/Schools";
import NewSchoolItem from "components/school/NewSchoolItem";

import Companies from "pages/Companies";
import NewCompanyItem from "components/company/NewCompanyItem";

import VehicleRoutes from "pages/VehicleRoutes";
import NewVehicleRouteItem from "components/vehicle-routes/NewVehicleRouteItem";

import Vehicles from "pages/Vehicles";
import NewVehicleItem from "components/vehicle/NewVehicleItem";

import Login from "pages/auth/Login";
import ForgotPassword from "pages/auth/ForgotPassword";
import RecoverPassword from "pages/auth/RecoverPassword";
import SignUp from "pages/auth/SignUp";
import MainNavigation from "navigation/MainNavigation";
import { AuthContext } from "shared/context/auth-context";
import { useAuth } from "shared/hooks/auth-hook";

import { StoreContext } from "shared/context/store-context";
import { useStore } from "shared/hooks/store-hook";
import { useHttpClient } from "shared/hooks/http-hook";

import ShiftManagement from "components/shift-management/ShiftManagement";

const App = () => {
  const { sendRequest } = useHttpClient();
  // const store = useContext(StoreContext);
  const {
    token,
    userLoaded,
    login,
    logout,
    userId,
    userName,
    school,
    company,
    userType,
    schoolType,
    theme,
    UpdateTheme,
  } = useAuth();

  let routes;

  const {
    isAdmin,
    users,
    drivers,
    driverOptions,
    companies,
    relations,
    companyOptions,
    schools,
    vehicles,
    schoolOptions,
    stopOptions,
    studentOptions,
    parentOptions,
    personelOptions,
    vehicleRoutes,
    vehicleRouteOptions,
    students,
    storeReady,
    studentRoutes,
    contracts,
    educationYears,
    discounts,
    serviceTemplates,
    educationYearsOptions,
    discountOptions,
    serviceTemplateOptions,
    receipts,
    isProcessing,
    withdraws,
    contractsOptions,
    expenses,
    vehiclePayments,
    transfers,
    pLocations,
    pLocationsOptions,
    transferregions,
    transferregionOptions,
    ikarusTransfers,
    ikarusTransferRegions,
    ikarusTransferRegionOptions,
    ikarusTransferPassengers,
    ikarusTransferPassengerOptions,
    shifts,
    stops,
    userOptions,
    shiftOptions,
    shiftUsers,
    shiftUserOptions,
    // Personel Services
    standartPersonelServices,
    standartPersonelServiceOptions,
    shiftPersonelServices,
    shiftPersonelServiceOptions,
    InsertUser,
    UpdateUser,
    DeleteUser,
    InsertShift,
    UpdateShift,
    DeleteShift,
    UpdateShifts,
    InsertStop,
    UpdateStop,
    DeleteStop,
    InsertVehicleRoute,
    UpdateVehicleRoute,
    DeleteVehicleRoute,
    UpdateRelations,
    UpdateVehicles,
    UpdateCompanies,
    UpdateVehicleRoutes,
    UpdateSchools,
    UpdateUsers,
    UpdateStudents,
    UpdateEverything,
    UpdateContracts,
    UpdateReceipts,
    UpdateWithdraws,
    UpdateExpenses,
    UpdateAccountingUtils,
    UpdateVehiclePayments,
    UpdateTransfers,
    UpdatePLocations,
    UpdateTransferregions,
    UpdateIkarusTransfers,
    UpdateIkarusTransferRegions,
    UpdateIkarusTransferPassengers,
    UpdatePersonelServices,
    InsertPersonelService,
    UpdatePersonelService,
    DeletePersonelService,
  } = useStore();

  useEffect(() => {
    const UpdateStore = async () => {
      if (i18next) {
        let language = i18next.language || window.localStorage.i18nextLng;
        UpdateTheme(language);
      } else {
        UpdateTheme("tr");
      }

      if (userId != false && token) {
        try {
          const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${userId}`, "GET", null, {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          });
          const currentuser = responseData.user;
          if (currentuser.usertype == "1000") {
            await UpdateEverything("All", currentuser.usertype);
          }
          if (currentuser.usertype == "100" || currentuser.usertype == "200") {
            await UpdateEverything(currentuser.school[0].toString(), currentuser.usertype);
          }
        } catch (err) {
          if (err.code === 401) {
            logout();
          }
        }
      }

      // const currentuser = users.find(user => user.Id == userId);
    };

    UpdateStore();
  }, [UpdateEverything, sendRequest, token, userId, UpdateTheme, logout]);

  if (token) {
    routes = (
      <Routes>
        <Route path="/" element={<Users />} end></Route>
        <Route path="/live" element={<Live />} end></Route>

        <Route path="/shifts" element={<Shifts />} exact></Route>

        <Route path="/stops" element={<Stops />} exact></Route>

        <Route path="/live/route" element={<LiveItem />} exact></Route>
        <Route path="/transfermanagement" element={<TransferManagement />} exact></Route>
        <Route path="/shiftmanagement" element={<ShiftManagement />} exact></Route>
        <Route path="/ikarus" element={<Ikarus />} exact></Route>
        <Route path="/accounting" element={<Accounting />} exact></Route>

        <Route path="/analysis" element={<Analysis />} exact></Route>

        <Route path="/users" element={<Users />} exact></Route>
        <Route path="/transfers" element={<Transfer />} exact></Route>
        <Route path="/plocations" element={<PLocation />} exact></Route>
        <Route path="/students" element={<Students />} exact></Route>
        <Route path="/notifications" element={<Relations />} exact></Route>
        <Route path="/vehicles" element={<Vehicles />} exact></Route>
        <Route path="/vehicleroutes" element={<VehicleRoutes />} exact></Route>
        <Route path="/standardpersonelservices" element={<StandardPersonelServices />} exact></Route>
        <Route path="/shiftpersonelservices" element={<ShiftPersonelServices />} exact></Route>
        <Route path="/schools" element={<Schools />} exact></Route>
        <Route path="/companies" element={<Companies />} exact></Route>

        <Route path="/new/user" element={<NewUserItem />}></Route>
        <Route path="/new/transfer" element={<TransferNew />}></Route>
        <Route path="/new/plocation" element={<PLocationNew />}></Route>
        <Route path="/new/student" element={<NewStudentItem />} exact></Route>
        <Route path="/new/notification" element={<NewRelationItem />} exact></Route>
        <Route path="/new/vehicle" element={<NewVehicleItem />} exact></Route>
        <Route path="/new/vehicleroute" element={<NewVehicleRouteItem />} exact></Route>
        <Route path="/new/school" element={<NewSchoolItem />} exact></Route>
        <Route path="/new/company" element={<NewCompanyItem />} exact></Route>
        <Route path="/new/transferregion" element={<TransferRegionNew />} exact></Route>
        <Route path="/userswebsite" element={<UsersWebsite />} exact></Route>
        <Route path="/reset-password" element={<ForgotPassword />} exact></Route>
        <Route path="/recover-password" element={<RecoverPassword />} exact></Route>
        {/* <Redirect to="/" /> */}
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path="/" element={<Login />} exact></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/reset-password" element={<ForgotPassword />}></Route>
        <Route path="/recover-password" element={<RecoverPassword />}></Route>
        <Route path="/sign-up" element={<SignUp />}></Route>
      </Routes>
    );
  }

  if (!userLoaded) {
    return <LoadingSpinner asOverlay />;
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        userName: userName,
        school: school,
        company: company,
        schoolType: schoolType,
        userType: userType,
        login: login,
        logout: logout,
        userLoaded: userLoaded,
        theme: theme,
        UpdateTheme: UpdateTheme,
      }}
    >
      <MainNavigation />
      <StoreContext.Provider
        value={{
          isAdmin: isAdmin,
          users: users,
          drivers: drivers,
          driverOptions: driverOptions,
          companies: companies,
          companyOptions: companyOptions,
          studentOptions: studentOptions,
          parentOptions: parentOptions,
          personelOptions: personelOptions,
          schools: schools,
          relations: relations,
          vehicles: vehicles,
          students: students,
          schoolOptions: schoolOptions,
          stopOptions: stopOptions,
          vehicleRoutes: vehicleRoutes,
          storeReady: storeReady,
          contracts: contracts,
          studentRoutes: studentRoutes,
          educationYears: educationYears,
          discounts: discounts,
          serviceTemplates: serviceTemplates,
          educationYearsOptions: educationYearsOptions,
          discountOptions: discountOptions,
          serviceTemplateOptions: serviceTemplateOptions,
          receipts: receipts,
          withdraws: withdraws,
          isProcessing: isProcessing,
          contractsOptions: contractsOptions,
          expenses: expenses,
          vehicleRouteOptions: vehicleRouteOptions,
          vehiclePayments: vehiclePayments,
          transfers: transfers,
          pLocations: pLocations,
          pLocationsOptions: pLocationsOptions,
          transferregions: transferregions,
          transferregionOptions: transferregionOptions,
          ikarusTransfers: ikarusTransfers,
          ikarusTransferRegions: ikarusTransferRegions,
          ikarusTransferRegionOptions: ikarusTransferRegionOptions,
          ikarusTransferPassengers: ikarusTransferPassengers,
          ikarusTransferPassengerOptions: ikarusTransferPassengerOptions,
          shifts: shifts,
          stops: stops,
          userOptions: userOptions,
          shiftOptions: shiftOptions,
          shiftUsers: shiftUsers,
          shiftUserOptions: shiftUserOptions,

          // Personel Services
          standartPersonelServices: standartPersonelServices,
          standartPersonelServiceOptions: standartPersonelServiceOptions,
          shiftPersonelServices: shiftPersonelServices,
          shiftPersonelServiceOptions: shiftPersonelServiceOptions,

          InsertUser: InsertUser,
          UpdateUser: UpdateUser,
          DeleteUser: DeleteUser,
          InsertShift: InsertShift,
          UpdateShift: UpdateShift,
          DeleteShift: DeleteShift,
          UpdateShifts: UpdateShifts,
          InsertStop: InsertStop,
          UpdateStop: UpdateStop,
          DeleteStop: DeleteStop,
          InsertVehicleRoute: InsertVehicleRoute,
          UpdateVehicleRoute: UpdateVehicleRoute,
          DeleteVehicleRoute: DeleteVehicleRoute,
          UpdateVehicles: UpdateVehicles,
          UpdateRelations: UpdateRelations,
          UpdateVehicleRoutes: UpdateVehicleRoutes,
          UpdateCompanies: UpdateCompanies,
          UpdateSchools: UpdateSchools,
          UpdateUsers: UpdateUsers,
          UpdateStudents: UpdateStudents,
          UpdateEverything: UpdateEverything,
          UpdateContracts: UpdateContracts,
          UpdateReceipts: UpdateReceipts,
          UpdateWithdraws: UpdateWithdraws,
          UpdateExpenses: UpdateExpenses,
          UpdateAccountingUtils: UpdateAccountingUtils,
          UpdateVehiclePayments: UpdateVehiclePayments,
          UpdateTransfers: UpdateTransfers,
          UpdatePLocations: UpdatePLocations,
          UpdateTransferregions: UpdateTransferregions,
          UpdateIkarusTransfers: UpdateIkarusTransfers,
          UpdateIkarusTransferRegions: UpdateIkarusTransferRegions,
          UpdateIkarusTransferPassengers: UpdateIkarusTransferPassengers,
          UpdatePersonelServices: UpdatePersonelServices,
          InsertPersonelService: InsertPersonelService,
          UpdatePersonelService: UpdatePersonelService,
          DeletePersonelService: DeletePersonelService,
        }}
      >
        <main style={{ width: "100%" }}>{routes}</main>
      </StoreContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
