export function GetHoursMinutesFromDate(date) {
  if (typeof date === "string") date = new Date(date);

  return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
}

export function GetStartStopTime(date) {
  return date.split(" ")[4].split(":")[0] + ":" + date.split(" ")[4].split(":")[1];
}

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date) {
  return (
    [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join("-") +
    " " +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(":")
  );
}

export function getFormattedDate(date) {
  return formatDate(date).split(" ")[0];
}

export function subtractSecondsFromDate(dateString, seconds) {
  var date = new Date(dateString);
  date.setSeconds(date.getSeconds() - seconds);
  return date.toString();
}

export function CheckTimeString(time) {
  //check if the string is HH:MM format
  time = time.trim();
  if (time.length === 5) {
    const hours = time.split(":")[0];
    const minutes = time.split(":")[1];
    if (hours.length === 2 && minutes.length === 2) {
      //check if the hours and minutes are valid
      if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
        return true;
      }
    }
  }
  return false;
}
