import React, { useEffect, useState, useCallback } from "react";
import DatePicker from "react-datepicker";

import { parseComplexDate } from "../../util/util";

import "./DatePicker.css";

import { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import { parseISO } from "date-fns";
registerLocale("tr", tr);

const DateSelect = (props) => {
  const [date, setDate] = useState();

  const initialValue = props.initialValue;
  const id = props.id;
  const onInput = props.onInput;

  useEffect(() => {
    if (initialValue) {
      setDate(parseISO(initialValue));
      onInput(id, initialValue, true);
    }
  }, [initialValue, id, onInput]);

  const handleChange = useCallback(
    (selectedDate) => {
      if (selectedDate) {
        setDate(selectedDate);
        props.onInput(props.id, parseComplexDate(selectedDate), true);
      } else {
        if (props.nonrequired) {
          props.onInput(props.id, "", true);
          setDate("");
        } else {
          props.onInput(props.id, "", false);
          setDate("");
        }
      }
    },
    [props]
  );

  return (
    <div className="form-control">
      <label htmlFor={props.id}>{props.label}</label>

      <DatePicker
        autoComplete="off"
        id={props.id}
        dateFormat="yyyy/MM/dd"
        locale="tr"
        className="datepickerclass"
        selected={date}
        onChange={(date) => handleChange(date)}
      />
    </div>
  );
};

export default DateSelect;
