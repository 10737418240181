import React, { useCallback, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import StopsList from "components/stop/StopsList";
import StopItem from "components/stop/StopItem";
import NewStopItem from "components/stop/NewStopItem";
import Button from "shared/components/FormElements/Button";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
import "./Admin.css";

const Stops = () => {
  const { t } = useTranslation();
  const store = useContext(StoreContext);
  const { isLoading, error, clearError } = useHttpClient();
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemShow, setSelectedItemShow] = useState(false);
  const [newItemShow, setNewItemShow] = useState(false);

  const pageItems = store.stops;
  const ItemType = "stop";

  const ItemSelectHandler = useCallback((itemid) => {
    setSelectedItemShow(true);
    setNewItemShow(false);
    setSelectedItemId(itemid);
  }, []);

  const ItemUpdateHandler = useCallback((itemid) => {
    setNewItemShow(false);
    setSelectedItemId(itemid);
  }, []);

  const newItemHandler = useCallback(() => {
    setSelectedItemShow(false);
    setNewItemShow(true);
  }, []);

  const closeNewItemHandler = useCallback(() => {
    setNewItemShow(false);
    setSelectedItemShow(false);
    setSelectedItemId(null);
  }, []);

  if (isLoading || !store.storeReady) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <div className="adminNavigationHeader">
        <Button danger size="small" onClick={newItemHandler}>
          {t(`${ItemType}.createNewItem`)}
        </Button>
      </div>
      <div className="adminpageContainer">
        {!isLoading && pageItems && (
          <div className="adminListContainer">
            <StopsList items={pageItems} onItemSelect={ItemSelectHandler} selectedId={selectedItemId} />
          </div>
        )}
        {selectedItemId && selectedItemShow && (
          <div className="adminUpdateContainer">
            <StopItem
              itemid={selectedItemId}
              onItemUpdate={ItemUpdateHandler}
              onClose={() => setSelectedItemShow(false)}
            ></StopItem>
          </div>
        )}
        {newItemShow && (
          <div className="adminUpdateContainer">
            <NewStopItem gotoItem={ItemSelectHandler} onClose={closeNewItemHandler}></NewStopItem>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Stops;
