/*eslint-disable eqeqeq*/
export const getStudentsWithLocationProblems = (students) => {
  const studentWithouthLocation = students.filter((s) => !s?.locationgeton || !s.locationgetoff);
  const studentWithoutLocationSet = students.filter(
    (s) =>
      s?.locationgeton?.lat == 1.1 ||
      s?.locationgeton?.lat == 1.1 ||
      s?.locationgetoff?.lat == 1.1 ||
      s?.locationgetoff?.lat == 1.1
  );

  const locationProblemaicStudents = [...studentWithouthLocation, ...studentWithoutLocationSet];
  locationProblemaicStudents.sort((a, b) => (a.name > b.name ? 1 : -1));
  return locationProblemaicStudents;
};

export const getStudentsWithoutNotifications = (students, notifications) => {
  let studentsWithouthNotification = [];

  students.forEach((student) => {
    const notification = notifications.find((n) => n.student.toString() === student.id.toString());
    if (!notification) {
      studentsWithouthNotification.push(student);
    }
  });

  return studentsWithouthNotification;
};

export const getNotificationsWithoutParent = (students, users, notifications) => {
  let notificationsWithoutParent = [];

  students.forEach((student) => {
    const notification = notifications.find((n) => n.student.toString() === student.id.toString());
    if (notification) {
      const parent = users.find((u) => u.id.toString() === notification.parent.toString());
      if (!parent) {
        notificationsWithoutParent.push(notification);
      }
    }
  });

  return notificationsWithoutParent;
};
