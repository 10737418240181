import React, { useContext } from "react";
import {
  DataGrid,
  trTR,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import "./AccountingList.css";
import { trTR as coretrTR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StoreContext } from "shared/context/store-context";

const ServiceTemplatesList = (props) => {
  const store = useContext(StoreContext);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    trTR,
    coretrTR,
    {
      GridCsvExportOptions: { fileName: "gh", allColumns: true, exportmode: "grid" },
      GridExcelExportOptions: { fileName: "gh", allColumns: true },
    }
  );

  let columns = [
    {
      field: "year",
      headerName: "Eğitim Yılı",
      width: 150,
      valueGetter: (params) =>
        store.educationYearsOptions.find((item) => item.value.toString() === params.row.year.toString()).label,
    },

    { field: "name", headerName: "Hizmet Adı", width: 300 },
    {
      field: "serviceStartDate",
      headerName: "Hizmet Başlangıç Tarihi",
      width: 200,
    },
    {
      field: "serviceEndingDate",
      headerName: "Hizmet Bitiş Tarihi",
      width: 200,
    },
    { field: "amount", headerName: "Meblağ", width: 200 },
  ];

  const OnSelectionChangeHandler = (event) => {
    props.onItemSelect(event[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="itemListContainer">
      <ThemeProvider theme={theme}>
        <DataGrid
          key={props.items}
          density="compact"
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={props.items}
          columns={columns}
          pageSize={100}
          getRowId={(r) => r.id}
          onSelectionModelChange={OnSelectionChangeHandler}
        />
      </ThemeProvider>
    </div>
  );
};

export default ServiceTemplatesList;
