import React, { useState, useContext, useCallback } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

import { CheckAuthorization } from "shared/util/util";

import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

import ReceiptNew from "./InvoiceNew";
import ReceiptList from "./InvoiceList";
import ReceiptItem from "./InvoiceItem";

import "./AccountingItem.css";

import TabPanel from "shared/components/UIElements/TabPanel";

const Receipt = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const pageItems = store.contracts;
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemShow, setSelectedItemShow] = useState(false);

  const Authorization = CheckAuthorization(auth.userId);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const ItemSelectHandler = useCallback((itemid) => {
    // selectedItemId.current = itemid;
    setSelectedItemShow(true);
    setSelectedItemId(itemid);
  }, []);

  const ItemUpdateHandler = useCallback((itemid) => {
    setSelectedItemId(itemid);
  }, []);

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `receipt-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  if (!Authorization.canUpdateContract) {
    return <div className="center">Faturalara erişiminiz bulunmuyor.</div>;
  }

  return (
    <React.Fragment>
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Faturalar" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <div className="adminpageContainer">
          {pageItems && (
            <div className="adminListContainer">
              <ReceiptList items={pageItems} onItemSelect={ItemSelectHandler} />
            </div>
          )}
          {selectedItemId && selectedItemShow && (
            <div className="adminUpdateContainer">
              <ReceiptItem
                itemid={selectedItemId}
                onItemUpdate={ItemUpdateHandler}
                onClose={() => setSelectedItemShow(false)}
              ></ReceiptItem>
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ReceiptNew></ReceiptNew>
      </TabPanel>
    </React.Fragment>
  );
};

export default Receipt;
