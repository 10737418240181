import React, { useContext } from "react";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import DatePicker from "shared/components/FormElements/DatePicker";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";

// #endregion Components

// #region Utils
import { isOfficial } from "shared/util/types";
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./AccountingItem.css";

const VehiclePaymentNew = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const updateStoreItems = store.UpdateVehiclePayments;
  const ItemTypeTR = "Araç Bilgileri";
  const APIEndPoint = "vehiclepayments";

  const itemSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify({
          school: formState.inputs.school.value,
          vehicleRoute: formState.inputs.vehicleRoute.value,
          plate: formState.inputs.plate.value,
          owner: formState.inputs.owner.value,
          driver: formState.inputs.driver.value,
          district: formState.inputs.district.value,
          startDate: formState.inputs.startDate.value,
          endingDate: formState.inputs.endingDate.value,
          hours: formState.inputs.hours.value,
          hostess: formState.inputs.hostess.value,
          weekend: formState.inputs.weekend.value,
          description: formState.inputs.description.value,
          amount: formState.inputs.amount.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      updateStoreItems();
      // history.push(`/${ItemEndPointType}`);
    } catch (err) {
      console.log(err);
    }
  };

  const [formState, inputHandler] = useForm(
    {
      school: { value: null, isValid: false },
      vehicleRoute: { value: null, isValid: true },
      plate: { value: "", isValid: true },
      owner: { value: "", isValid: false },
      driver: { value: "", isValid: true },
      district: { value: "", isValid: true },
      startDate: { value: "", isValid: true },
      endingDate: { value: "", isValid: true },
      hours: { value: "", isValid: true },
      hostess: { value: false, isValid: true },
      weekend: { value: false, isValid: true },
      description: { value: "", isValid: true },
      amount: { value: 0, isValid: true },
    },
    false
  );

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <form className="new-item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <h3>{ItemTypeTR} Ekle</h3>

        <CustomSelect
          options={store.schoolOptions}
          id="school"
          onInput={inputHandler}
          label="Okul"
          initialValid={false}
        ></CustomSelect>

        <CustomSelect
          options={store.vehicleRouteOptions}
          id="vehicleRoute"
          onInput={inputHandler}
          label="Servis"
          initialValid={true}
        ></CustomSelect>

        <Input
          id="plate"
          element="input"
          type="text"
          label="Plaka"
          validators={[VALIDATOR_NONE()]}
          errorText="Plaka giriniz."
          initialValid={true}
          onInput={inputHandler}
        />

        <Input
          id="owner"
          element="input"
          type="text"
          label="Araç sahibi"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Araç sahibi ismi giriniz."
          initialValid={false}
          onInput={inputHandler}
        />

        <Input
          id="driver"
          element="input"
          type="text"
          label="Sürücü"
          validators={[VALIDATOR_NONE()]}
          errorText="Sürücü ismi giriniz."
          initialValid={true}
          onInput={inputHandler}
        />

        <Input
          id="district"
          element="input"
          type="text"
          label="Güzergah Bölgesi"
          validators={[VALIDATOR_NONE()]}
          errorText="Güzergah bölgesi giriniz."
          initialValid={true}
          onInput={inputHandler}
        />

        <Input
          id="hours"
          element="input"
          type="text"
          label="Servis Saatleri"
          validators={[VALIDATOR_NONE()]}
          errorText="Servis saatlerini giriniz."
          initialValid={true}
          onInput={inputHandler}
        />

        <DatePicker
          id="startDate"
          onInput={inputHandler}
          label="Hizmet Başlangıç Tarihi"
          initialValid={true}
          validators={[VALIDATOR_NONE()]}
          nonrequired={true}
        ></DatePicker>

        <DatePicker
          id="endingDate"
          onInput={inputHandler}
          label="Hizmet Bitiş Tarihi"
          initialValid={true}
          validators={[VALIDATOR_NONE()]}
          nonrequired={true}
        ></DatePicker>

        <CustomSelect
          options={isOfficial}
          id="hostess"
          onInput={inputHandler}
          label="Araçta hostes var mı?"
          initialValid={true}
          validators={[VALIDATOR_NONE()]}
          initialValue={isOfficial.find((item) => item.value.toString() === "false")}
        ></CustomSelect>

        <CustomSelect
          options={isOfficial}
          id="weekend"
          onInput={inputHandler}
          label="Hafta sonu çalışıyor mu?"
          initialValid={true}
          validators={[VALIDATOR_NONE()]}
          initialValue={isOfficial.find((item) => item.value.toString() === "false")}
        ></CustomSelect>

        <Input
          id="description"
          element="textarea"
          type="text"
          label="Notlar (Araca ait ek bilgileri burada tutabilirsiniz)"
          validators={[VALIDATOR_NONE()]}
          initialValid={true}
          onInput={inputHandler}
        />

        <Input
          id="amount"
          element="input"
          type="text"
          label="Toplam Ücret"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Hizmet bedelinin standart ücretini giriniz."
          initialValid={false}
          onInput={inputHandler}
        />

        <Button type="submit" disabled={!formState.isValid}>
          Yeni {ItemTypeTR} Yarat
        </Button>
      </form>
    </React.Fragment>
  );
};

export default VehiclePaymentNew;
