import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// #region Components
import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./Item.css";

const TransferRegionNew = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const [openError, setOpenError] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();

  const updateStoreItems = store.UpdateTransferregions;
  const ItemTypeTR = "Transfer Bölgesi";
  const APIEndPoint = "transferregions";

  const itemSubmitHandler = async (event) => {
    event.preventDefault();

    let amounttoWrite = 0;
    //check if amount is number
    if (isNaN(formState.inputs.amount.value)) {
      setOpenError(true);
      return;
    } else {
      amounttoWrite = parseInt(formState.inputs.amount.value);
    }

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify({
          school: formState.inputs.school.value,
          name: formState.inputs.name.value,
          amount: amounttoWrite,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      updateStoreItems();
      navigate(`/transfermanagement`);
      //navigate(`/${ItemEndPointType}`);
    } catch (err) {}
  };

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: false },
      name: { value: "", isValid: false },
      amount: { value: 0, isValid: false },
    },
    false
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <Snackbar
        open={openError}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Ücret bilgisi sayısal bir değer olmalıdır!
        </MuiAlert>
      </Snackbar>

      <form className="new-item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <h3>{ItemTypeTR} Ekle</h3>

        <CustomSelect
          options={store.schoolOptions}
          id="school"
          onInput={inputHandler}
          label="Kurum"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Zorunlu alan"
        ></CustomSelect>

        <Input
          id="name"
          element="input"
          type="text"
          label="İsim"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="İsim giriniz."
          onInput={inputHandler}
        />

        <Input
          id="amount"
          element="input"
          type="text"
          label="Ücret"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Ücret giriniz."
          onInput={inputHandler}
        />

        <Button type="submit" disabled={!formState.isValid}>
          Yeni {ItemTypeTR} Yarat
        </Button>
      </form>
    </React.Fragment>
  );
};

export default TransferRegionNew;
