/*eslint-disable eqeqeq*/
import { useState, useCallback, useRef } from "react";
import { useHttpClient } from "shared/hooks/http-hook";
import { days } from "shared/util/types";

export const useStore = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const { sendRequest } = useHttpClient();
  const [users, setUsers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [driverOptions, setDriverOptions] = useState();
  const [schools, setSchools] = useState();
  const [schoolOptions, setSchoolOptions] = useState();
  const [companies, setCompanies] = useState();
  const [companyOptions, setCompanyOptions] = useState();
  const [vehicleRoutes, setVehicleRoutes] = useState();
  const [vehicleRouteOptions, setVehicleRouteOptions] = useState();
  const [vehicles, setVehicles] = useState();
  const [students, setStudents] = useState();
  const [relations, setRelations] = useState();
  const [studentOptions, setStudentOptions] = useState();
  const [parentOptions, setParentOptions] = useState();
  const [personelOptions, setPersonelOptions] = useState();
  const [storeReady, setStoreReady] = useState();
  const [studentRoutes, setStudentRoutes] = useState();
  const [educationYears, setEducationYears] = useState();
  const [discounts, setDiscounts] = useState();
  const [serviceTemplates, setServiceTemplates] = useState();
  const [educationYearsOptions, setEducationYearsOptions] = useState();
  const [discountOptions, setDiscountOptions] = useState();
  const [serviceTemplateOptions, setServiceTemplateOptions] = useState();
  const [receipts, setReceipts] = useState();
  const [withdraws, setWithdraws] = useState();
  const [transfers, setTransfers] = useState();
  const [contractsOptions, setContractsOptions] = useState();
  const [expenses, setExpenses] = useState();
  const [vehiclePayments, setVehiclePayments] = useState();
  const [pLocations, setPLocations] = useState();
  const [pLocationsOptions, setPLocationsOptions] = useState();
  const [transferregions, setTransferRegions] = useState();
  const [transferregionOptions, setTransferRegionOptions] = useState();
  const [ikarusTransfers, setIkarusTransfers] = useState();
  const [ikarusTransferRegions, setIkarusTransferRegions] = useState();
  const [ikarusTransferRegionOptions, setIkarusTransferRegionOptions] = useState();
  const [ikarusTransferPassengers, setIkarusTransferPassengers] = useState();
  const [ikarusTransferPassengerOptions, setIkarusTransferPassengerOptions] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [shifts, setShifts] = useState();
  const [stops, setStops] = useState();
  const [stopOptions, setStopOptions] = useState();
  const [standartPersonelServices, setStandartPersonelServices] = useState([]);
  const [standartPersonelServiceOptions, setStandartPersonelServiceOptions] = useState([]);
  const [shiftPersonelServices, setShiftPersonelServices] = useState([]);
  const [shiftPersonelServiceOptions, setShiftPersonelServiceOptions] = useState([]);
  const [userOptions, setUserOptions] = useState();
  const [shiftOptions, setShiftOptions] = useState();
  const [shiftUsers, setShiftUsers] = useState();
  const [shiftUserOptions, setShiftUserOptions] = useState();

  //Accounting
  const [contracts, setContracts] = useState();

  const school = useRef("All");
  const loggedInUserType = useRef();
  const schoolsREF = useRef([]);
  const current_student_ids = useRef([]);
  const current_user_ids = useRef([]);

  const current_student_ids_for_student_routes = useRef([]);

  const UpdateStudents = useCallback(async () => {
    const fetchStudents = async () => {
      setIsProcessing(true);
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/students`);

        let allStudents = responseData.students;

        if (school.current != "All") {
          allStudents = responseData.students.filter((student) => student.school == school.current);
          current_student_ids.current = allStudents.map((student) => student.id.toString());
        }

        current_student_ids_for_student_routes.current = allStudents.map((student) => student.id.toString());

        setStudents(allStudents);

        const studentoptions = allStudents.map((student) => {
          return {
            value: student.id,
            label: student.name + " - " + student.identifier,
          };
        });
        setStudentOptions(studentoptions);
      } catch (err) {}
      setIsProcessing(false);
    };
    fetchStudents();
  }, [sendRequest]);

  const UpdateRelations = useCallback(async () => {
    const fetchRelations = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/relations`);

        let allRelations = responseData.relations;

        await new Promise((resolve) => setTimeout(resolve, 1000));

        if (school.current != "All") {
          allRelations = allRelations.filter(
            (relation) =>
              current_student_ids.current.includes(relation.student.toString()) &&
              current_user_ids.current.includes(relation.parent.toString())
          );
        }

        setRelations(allRelations);
      } catch (err) {}
    };
    fetchRelations();
  }, [sendRequest]);

  const InsertUser = (user) => {
    setUsers([user, ...users]);
    setUserOptions([...userOptions, { value: user.id, label: user.name }]);

    if (user?.isshift) {
      setShiftUsers([...shiftUsers, user]);
      setShiftUserOptions([...shiftUserOptions, { value: user.id, label: user.name }]);
    }

    if (user.usertype === "50") {
      setDrivers(...drivers, user);
      setDriverOptions([
        ...driverOptions,
        {
          value: user.id,
          label: user.name,
        },
      ]);
    }
  };

  const UpdateUser = (user) => {
    const index = users.findIndex((x) => x.id === user.id);
    const newUsers = [...users];
    newUsers[index] = user;
    setUsers([...newUsers]);

    const driverIndex = drivers.findIndex((x) => x.id === user.id);
    if (driverIndex) {
      const newDrivers = [...drivers];
      newDrivers[driverIndex] = user;
      setDrivers([...newDrivers]);
    }

    setUserOptions(
      userOptions.map((item) => {
        if (item.value === user.id) {
          return { value: user.id, label: user.name };
        }
        return item;
      })
    );

    if (user?.isshift) {
      const shiftUserIndex = shiftUsers.findIndex((x) => x.id === user.id);
      if (shiftUserIndex) {
        const newShiftUsers = [...shiftUsers];
        newShiftUsers[shiftUserIndex] = user;
        setShiftUsers([...newShiftUsers]);
      }
      setShiftUserOptions(
        shiftUserOptions.map((item) => {
          if (item.value === user.id) {
            return { value: user.id, label: user.name };
          }
          return item;
        })
      );
    }

    if (driverOptions.map((item) => item.value).includes(user.id)) {
      setDriverOptions(
        driverOptions.map((item) => {
          if (item.value === user.id) {
            return { value: user.id, label: user.name };
          }
          return item;
        })
      );
    }
  };

  const DeleteUser = (userId) => {
    const newUsers = users.filter((user) => user.id !== userId);
    setUsers([...newUsers]);
    setUserOptions(userOptions.filter((item) => item.value !== userId));

    if (shiftUsers.map((item) => item.id).includes(userId)) {
      setShiftUsers(shiftUsers.filter((user) => user.id !== userId));
      setShiftUserOptions(shiftUserOptions.filter((item) => item.value !== userId));
    }

    const newDrivers = drivers.filter((driver) => driver.id !== userId);
    setDrivers([...newDrivers]);
    if (driverOptions.map((item) => item.value).includes(userId)) {
      setDriverOptions(driverOptions.filter((item) => item.value !== userId));
    }
  };

  const InsertShift = (shift) => {
    setShifts([shift, ...shifts]);
    setShiftOptions([
      ...shiftOptions,
      {
        value: shift.id,
        label: days.find((day) => day.value === shift.day).label + " - " + shift.name,
      },
    ]);
  };

  const UpdateShift = (shift) => {
    const index = shifts.findIndex((x) => x.id === shift.id);
    const newShifts = [...shifts];
    newShifts[index] = shift;
    setShifts([...newShifts]);
    setShiftOptions(
      shiftOptions.map((item) => {
        if (item.value === shift.id) {
          return {
            value: shift.id,
            label: days.find((day) => day.value === shift.day).label + " - " + shift.name,
          };
        }
        return item;
      })
    );
  };

  const DeleteShift = (shiftId) => {
    const newShifts = shifts.filter((shift) => shift.id !== shiftId);
    setShifts([...newShifts]);
    setShiftOptions(shiftOptions.filter((item) => item.value !== shiftId));
  };

  const InsertStop = (stop) => {
    setStops([stop, ...stops]);
    setStopOptions([...stopOptions, { value: stop.id, label: stop.name }]);
  };

  const UpdateStop = (stop) => {
    const index = stops.findIndex((x) => x.id === stop.id);
    const newStops = [...stops];
    newStops[index] = stop;
    setStops([...newStops]);
    setStopOptions(
      stopOptions.map((item) => {
        if (item.value === stop.id) {
          return { value: stop.id, label: stop.name };
        }
        return item;
      })
    );
  };

  const DeleteStop = (stopId) => {
    const newStops = stops.filter((stop) => stop.id !== stopId);
    setStops([...newStops]);
    setStopOptions(stopOptions.filter((item) => item.value !== stopId));
  };

  const InsertVehicleRoute = (vehicleroute) => {
    setVehicleRoutes([vehicleroute, ...vehicleRoutes]);
  };

  const UpdateVehicleRoute = (vehicleroute) => {
    const index = vehicleRoutes.findIndex((x) => x.id === vehicleroute.id);
    const newVehicleRoutes = [...vehicleRoutes];
    newVehicleRoutes[index] = vehicleroute;
    setVehicleRoutes([...newVehicleRoutes]);
  };

  const DeleteVehicleRoute = (vehiclerouteId) => {
    const newVehicleRoutes = vehicleRoutes.filter((vehicleroute) => vehicleroute.id !== vehiclerouteId);
    setVehicleRoutes([...newVehicleRoutes]);
  };

  const UpdateUsers = useCallback(async () => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users`);

        let allUsers = responseData.users;

        const useroptions = allUsers.map((user) => {
          return { value: user.id, label: user.name };
        });
        setUserOptions(useroptions);

        if (school.current != "All" && school.current != "66078db18bf1bd0015add40c") {
          allUsers = responseData.users.filter((user) => user.school[0] == school.current);
          current_user_ids.current = allUsers.map((user) => user.id.toString());
        }

        setUsers(allUsers);

        setShiftUsers(allUsers.filter((user) => user?.isshift));
        setShiftUserOptions(
          allUsers
            .filter((user) => user?.isshift)
            .map((user) => {
              return { value: user.id, label: user.name };
            })
        );

        const allparents = allUsers.filter((user) => user.usertype === "2");
        const allpersonels = allUsers.filter((user) => user.usertype === "4");

        const parentoptions = allparents.map((user) => {
          return {
            value: user.id,
            label: user.name + " - " + user.phonenumber,
          };
        });

        const personeloptions = allpersonels.map((user) => {
          return {
            value: user.id,
            label: user.name + " - " + user.phonenumber,
          };
        });
        setParentOptions(parentoptions);
        setPersonelOptions(personeloptions);
        const responseData2 = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/alldrivers`);
        const allDrivers = responseData2.users;

        setDrivers(allDrivers);
        const driveroptions = allDrivers
          .filter((user) => user.usertype === "50")
          .map((user) => {
            return { value: user.id, label: user.name };
          });
        setDriverOptions(driveroptions);
        setStoreReady(true);
      } catch (err) {}
    };
    fetchUsers();
  }, [sendRequest]);

  const UpdateVehicles = useCallback(async () => {
    const fetchVehicles = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/vehicles`);

        setVehicles(responseData.vehicles);
      } catch (err) {}
    };
    fetchVehicles();
  }, [sendRequest]);

  const UpdateCompanies = useCallback(async () => {
    const fetchCompanies = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/companies`);

        setCompanies(responseData.companies);

        const companyoptions = responseData.companies.map((company) => {
          return { value: company.id, label: company.name };
        });
        setCompanyOptions(companyoptions);
      } catch (err) {}
    };
    fetchCompanies();
  }, [sendRequest]);

  const UpdateSchools = useCallback(async () => {
    const fetchSchools = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/schools`);

        let allSchools = responseData.schools;
        allSchools.sort((a, b) => a.name.localeCompare(b.name));

        if (school.current != "All") {
          if (loggedInUserType.current.toString() === "100") {
            allSchools = responseData.schools.filter((item) => item.id == school.current);
          } else {
            allSchools = responseData.schools;
          }
        }
        schoolsREF.current = allSchools;
        setSchools(allSchools);

        const schooloptions = allSchools.map((schoolitem) => {
          return { value: schoolitem.id, label: schoolitem.name };
        });
        setSchoolOptions(schooloptions);
      } catch (err) {}
    };
    await fetchSchools();
  }, [sendRequest]);

  const UpdateShifts = useCallback(async () => {
    const fetchShifts = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/shifts`);

        setShifts(responseData.shifts);

        const shiftoptions = responseData.shifts.map((shiftitem) => {
          return {
            value: shiftitem.id,
            label: days.find((day) => day.value === shiftitem.day).label + " - " + shiftitem.name,
          };
        });
        setShiftOptions(shiftoptions);
      } catch (err) {}
    };
    fetchShifts();
  }, [sendRequest]);

  const UpdateStops = useCallback(async () => {
    const fetchStops = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/stops`);
        setStops(responseData.stops);

        const stopoptions = responseData.stops.map((item) => {
          return { value: item.id, label: item.name };
        });
        setStopOptions(stopoptions);
      } catch (err) {}
    };
    fetchStops();
  }, [sendRequest]);

  const UpdatePersonelServices = useCallback(async () => {
    const fetchPersonelServices = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/personelservices`);

        setStandartPersonelServices(responseData.personelservices.filter((item) => !item?.isshift));
        setShiftPersonelServices(responseData.personelservices.filter((item) => item?.isshift));

        setStandartPersonelServiceOptions(
          responseData.personelservices
            .filter((item) => !item?.isshift)
            .map((item) => {
              return { value: item.id, label: item.name };
            })
        );
        setShiftPersonelServiceOptions(
          responseData.personelservices
            .filter((item) => item?.isshift)
            .map((item) => {
              return { value: item.id, label: item.name };
            })
        );
      } catch (err) {}
    };
    fetchPersonelServices();
  }, [sendRequest]);

  const InsertPersonelService = (service) => {
    if (service?.isshift) {
      setShiftPersonelServices([service, ...shiftPersonelServices]);
      setShiftPersonelServiceOptions([...shiftPersonelServiceOptions, { value: service.id, label: service.name }]);
    } else {
      setStandartPersonelServices([service, ...standartPersonelServices]);
      setStandartPersonelServiceOptions([
        ...standartPersonelServiceOptions,
        { value: service.id, label: service.name },
      ]);
    }
  };

  const UpdatePersonelService = (service) => {
    if (service?.isshift) {
      const index = shiftPersonelServices.findIndex((x) => x.id === service.id);
      const newShiftPersonelServices = [...shiftPersonelServices];
      newShiftPersonelServices[index] = service;
      setShiftPersonelServices([...newShiftPersonelServices]);
      setShiftPersonelServiceOptions(
        shiftPersonelServiceOptions.map((item) => {
          if (item.value === service.id) {
            return { value: service.id, label: service.name };
          }
          return item;
        })
      );
    } else {
      const index = standartPersonelServices.findIndex((x) => x.id === service.id);
      const newStandartPersonelServices = [...standartPersonelServices];
      newStandartPersonelServices[index] = service;
      setStandartPersonelServices([...newStandartPersonelServices]);
      setStandartPersonelServiceOptions(
        standartPersonelServiceOptions.map((item) => {
          if (item.value === service.id) {
            return { value: service.id, label: service.name };
          }
          return item;
        })
      );
    }
  };

  const DeletePersonelService = (serviceId) => {
    if (shiftPersonelServices.map((item) => item.id).includes(serviceId)) {
      const newShiftPersonelServices = shiftPersonelServices.filter((service) => service.id !== serviceId);
      setShiftPersonelServices([...newShiftPersonelServices]);
      setShiftPersonelServiceOptions(shiftPersonelServiceOptions.filter((item) => item.value !== serviceId));
    }
    if (standartPersonelServices.map((item) => item.id).includes(serviceId)) {
      const newStandartPersonelServices = standartPersonelServices.filter((service) => service.id !== serviceId);
      setStandartPersonelServices([...newStandartPersonelServices]);
      setStandartPersonelServiceOptions(standartPersonelServiceOptions.filter((item) => item.value !== serviceId));
    }
  };

  const UpdatePLocations = useCallback(async () => {
    const fetchPLocations = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/plocations`);

        let allPLocations = responseData.pLocations;

        // if (school.current != 'All') {
        //   allPLocations = responseData.schools.filter(
        //     (item) => item.id == school.current
        //   );
        // }
        // schoolsREF.current = allSchools;
        setPLocations(allPLocations);

        const plocationoptions = allPLocations.map((pLocationItem) => {
          return { value: pLocationItem.id, label: pLocationItem.name };
        });

        setPLocationsOptions(plocationoptions);
      } catch (err) {}
    };
    await fetchPLocations();
  }, [sendRequest]);

  const UpdateVehicleRoutes = useCallback(async () => {
    const fetchVehicleRoutes = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/vehicleroutes`);

        let allVehicleRoutes = responseData.vehicleRoutes;

        await new Promise((resolve) => setTimeout(resolve, 2000));

        if (school.current != "All") {
          allVehicleRoutes = responseData.vehicleRoutes.filter((route) => route.school == school.current);
        }

        const vehiclerouteoptions = allVehicleRoutes
          .filter((route) => route.type == 100)
          .map((vehicleroute) => {
            return {
              value: vehicleroute.id,
              label:
                schoolsREF.current.find((school) => school.id.toString() === vehicleroute.school.toString()).name +
                " - " +
                vehicleroute.name,
            };
          });

        setVehicleRouteOptions(vehiclerouteoptions.sort((a, b) => a.label.localeCompare(b.label)));

        let tempStudentRoutes = [];
        current_student_ids_for_student_routes.current.forEach((element) => {
          const currentStudentMorningRoute = allVehicleRoutes.filter(
            (route) => route.type == 100 && route.student.map((student) => student.id).includes(element.toString())
          );
          const currentStudentEveningRoute = allVehicleRoutes.filter(
            (route) => route.type == 200 && route.student.map((student) => student.id).includes(element.toString())
          );

          tempStudentRoutes.push({
            id: element,
            routemorning: currentStudentMorningRoute.length == 0 ? "-" : currentStudentMorningRoute[0].name,
            routeevening: currentStudentEveningRoute.length == 0 ? "-" : currentStudentEveningRoute[0].name,
          });
        });

        setStudentRoutes(tempStudentRoutes);
        setVehicleRoutes(allVehicleRoutes);
      } catch (err) {
        console.log(err);
      }
    };
    fetchVehicleRoutes();
  }, [sendRequest]);

  const UpdateContracts = useCallback(async () => {
    const fetchContracts = async () => {
      const getContractYearLabel = (year) => {
        if (year.toString() === "625c94645810394434e7ed79") {
          return "2022-2023";
        } else if (year.toString() === "647d789024676b00145f0d30") {
          return "2023-2024";
        } else if (year.toString() === "661c8d1e862b3f2c307b1276") {
          return "2024-2025";
        }
      };

      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/contracts`);
        setContracts(responseData.contracts);
        const allContracts = responseData.contracts.map((contract) => {
          if (contract.student) {
            return {
              value: contract.id,
              label:
                contract.student.name +
                " - " +
                contract.payerName +
                " - " +
                getContractYearLabel(contract.year.toString()),
            };
          } else {
            return {
              value: contract.id,
              label: contract.id + " - " + contract.payerName,
            };
          }
        });
        setContractsOptions(allContracts);
      } catch (err) {
        console.log(err);
      }
    };

    fetchContracts();
  }, [sendRequest]);

  const UpdateTransfers = useCallback(async () => {
    const fetchTransfers = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/transfers`);
        setTransfers(responseData.transfers);
      } catch (err) {}
    };

    fetchTransfers();
  }, [sendRequest]);

  const UpdateTransferregions = useCallback(async () => {
    const fetchTransferregions = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/transferregions`);
        setTransferRegions(responseData.transferregions);

        const transferOptions = responseData.transferregions.map((regionitem) => {
          return { value: regionitem.id, label: regionitem.name };
        });
        setTransferRegionOptions(transferOptions);
      } catch (err) {}
    };

    fetchTransferregions();
  }, [sendRequest]);

  const UpdateIkarusTransfers = useCallback(async () => {
    const fetchIkarusTransfers = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/ikarustransfers`);
        setIkarusTransfers(responseData.transfers);
      } catch (err) {}
    };

    fetchIkarusTransfers();
  }, [sendRequest]);

  const UpdateIkarusTransferRegions = useCallback(async () => {
    const fetchIkarusTransferregions = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/ikarustransferregions`);
        setIkarusTransferRegions(responseData.regions);

        const transferOptions = responseData.regions.map((regionitem) => {
          return { value: regionitem.id, label: regionitem.name };
        });
        setIkarusTransferRegionOptions(transferOptions);
      } catch (err) {}
    };

    fetchIkarusTransferregions();
  }, [sendRequest]);

  const UpdateIkarusTransferPassengers = useCallback(async () => {
    const fetchIkarusTransferPassengers = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/ikarustransferpassengers`);
        setIkarusTransferPassengers(responseData.passengers);

        const transferOptions = responseData.passengers.map((passenger) => {
          return { value: passenger.id, label: passenger.name };
        });
        setIkarusTransferPassengerOptions(transferOptions);
      } catch (err) {}
    };

    fetchIkarusTransferPassengers();
  }, [sendRequest]);

  const UpdateReceipts = useCallback(async () => {
    const fetchReceipts = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/receipts`);
        setReceipts(responseData.receipts);
      } catch (err) {}
    };

    fetchReceipts();
  }, [sendRequest]);

  const UpdateWithdraws = useCallback(async () => {
    const fetchWithdraws = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/withdraws`);
        setWithdraws(responseData.withdraws);
      } catch (err) {}
    };

    fetchWithdraws();
  }, [sendRequest]);

  const UpdateVehiclePayments = useCallback(async () => {
    const fetchVehiclePayments = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/vehiclepayments`);
        setVehiclePayments(responseData.vehiclePayments);
      } catch (err) {}
    };

    fetchVehiclePayments();
  }, [sendRequest]);

  const UpdateExpenses = useCallback(async () => {
    const fetchExpenses = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/expenses`);
        setExpenses(responseData.expenses);
      } catch (err) {}
    };

    fetchExpenses();
  }, [sendRequest]);

  const UpdateAccountingUtils = useCallback(async () => {
    const fetchAccountingUtils = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/educationyears`);
        setEducationYears(responseData.educationYears);

        const allEducationYears = responseData.educationYears.map((educationyear) => {
          return {
            value: educationyear.id,
            label: educationyear.name,
          };
        });
        setEducationYearsOptions(allEducationYears);
      } catch (err) {}

      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/discounts`);
        setDiscounts(responseData.discounts);
        const allDiscounts = responseData.discounts.map((discount) => {
          return {
            value: discount.id,
            label: discount.name,
          };
        });
        setDiscountOptions(allDiscounts);
      } catch (err) {}

      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/servicetemplates`);
        setServiceTemplates(responseData.serviceTemplates);

        const allserviceTemplates = responseData.serviceTemplates.map((serviceTemplate) => {
          return {
            value: serviceTemplate.id,
            label: serviceTemplate.name,
          };
        });
        setServiceTemplateOptions(allserviceTemplates);
      } catch (err) {}
    };

    fetchAccountingUtils();
  }, [sendRequest]);

  const UpdateEverything = useCallback(
    async (currentschool, usertype) => {
      const UpdateAll = async () => {
        school.current = currentschool;
        loggedInUserType.current = usertype;
        if (currentschool == "All") {
          setIsAdmin(true);
        }
        UpdatePLocations();
        UpdateStudents();

        UpdateSchools();
        UpdateRelations();
        UpdateTransferregions();
        UpdateTransfers();
        UpdateTransferregions();
        UpdateIkarusTransfers();
        UpdateIkarusTransferRegions();
        UpdateIkarusTransferPassengers();
        UpdateCompanies();
        //UpdateVehicles(); BU ZATEN YOKTU
        UpdateAccountingUtils();
        UpdateContracts();
        UpdateReceipts();

        UpdateWithdraws();
        UpdateExpenses();
        UpdateVehiclePayments();
        UpdateShifts();
        UpdateStops();
        UpdatePersonelServices();
        UpdateVehicleRoutes(); //HERE
        UpdateUsers();
      };
      UpdateAll();
    },
    [
      UpdateAccountingUtils,
      UpdateCompanies,
      UpdateContracts,
      UpdateExpenses,
      UpdateReceipts,
      UpdateRelations,
      UpdateSchools,
      UpdateStudents,
      UpdateTransferregions,
      UpdateTransfers,
      UpdateUsers,
      UpdateVehiclePayments,
      UpdateVehicleRoutes,
      UpdateWithdraws,
      UpdateIkarusTransfers,
      UpdateIkarusTransferPassengers,
      UpdateShifts,
      UpdateStops,
      UpdateIkarusTransferRegions,
      UpdatePersonelServices,
    ]
  );

  return {
    isAdmin,
    users,
    vehicles,
    schools,
    schoolOptions,
    stopOptions,
    companies,
    relations,
    students,
    studentOptions,
    parentOptions,
    personelOptions,
    companyOptions,
    vehicleRoutes,
    drivers,
    driverOptions,
    storeReady,
    studentRoutes,
    contracts,
    educationYears,
    discounts,
    serviceTemplates,
    educationYearsOptions,
    discountOptions,
    serviceTemplateOptions,
    receipts,
    withdraws,
    contractsOptions,
    expenses,
    vehicleRouteOptions,
    vehiclePayments,
    transfers,
    pLocations,
    pLocationsOptions,
    transferregions,
    transferregionOptions,
    ikarusTransfers,
    ikarusTransferRegions,
    ikarusTransferRegionOptions,
    ikarusTransferPassengers,
    ikarusTransferPassengerOptions,
    isProcessing,
    shifts,
    stops,
    standartPersonelServices,
    standartPersonelServiceOptions,
    shiftPersonelServices,
    shiftPersonelServiceOptions,
    userOptions,
    shiftOptions,
    shiftUsers,
    shiftUserOptions,
    InsertUser,
    UpdateUser,
    DeleteUser,
    InsertShift,
    UpdateShift,
    DeleteShift,
    InsertStop,
    UpdateStop,
    DeleteStop,
    UpdatePersonelServices,
    InsertPersonelService,
    UpdatePersonelService,
    DeletePersonelService,
    InsertVehicleRoute,
    UpdateVehicleRoute,
    DeleteVehicleRoute,
    UpdateVehicles,
    UpdateRelations,
    UpdateVehicleRoutes,
    UpdateCompanies,
    UpdateSchools,
    UpdateUsers,
    UpdateStudents,
    UpdateEverything,
    UpdateContracts,
    UpdateAccountingUtils,
    UpdateReceipts,
    UpdateWithdraws,
    UpdateExpenses,
    UpdateVehiclePayments,
    UpdateTransfers,
    UpdatePLocations,
    UpdateTransferregions,
    UpdateIkarusTransfers,
    UpdateIkarusTransferRegions,
    UpdateIkarusTransferPassengers,
    UpdateShifts,
    UpdateStops,
  };
};
