import React, { useCallback, useState, useContext } from "react";

import TransferRegionList from "./TransferRegionList";
import TransferRegionItem from "./TransferRegionItem";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
import "./Admin.css";

const TransferRegion = (props) => {
  const store = useContext(StoreContext);
  const { isLoading, error, clearError } = useHttpClient();
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemShow, setSelectedItemShow] = useState(false);

  let pageItems = store.ikarusTransferRegions;

  const switchItemView = useCallback(() => {
    setSelectedItemShow(true);
  }, [setSelectedItemShow]);

  const ItemSelectHandler = useCallback(
    (itemid) => {
      switchItemView();
      setSelectedItemId(itemid);
    },
    [switchItemView, setSelectedItemId]
  );

  const ItemUpdateHandler = useCallback(
    (itemid) => {
      setSelectedItemId(itemid);
    },
    [setSelectedItemId]
  );

  const switchListView = useCallback(() => {
    setSelectedItemShow(false);
  }, [setSelectedItemShow]);

  if (isLoading || !store.vehicleRoutes) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div className="adminpageContainer">
        {!isLoading && pageItems && (
          <div className="liveListContainer">
            <TransferRegionList items={pageItems} onItemSelect={ItemSelectHandler} />
          </div>
        )}
        {selectedItemId && selectedItemShow && (
          <div className="liveUpdateContainer">
            <TransferRegionItem
              itemid={selectedItemId}
              currentTime={Date.now()}
              onItemUpdate={ItemUpdateHandler}
              // onClose={ () => setSelectedItemShow(false) }
              onClose={() => switchListView()}
            ></TransferRegionItem>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default TransferRegion;
