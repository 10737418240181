import React, { useContext } from "react";
import {
  DataGrid,
  trTR,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import "./List.css";
import { transfertypes, transferStatus } from "shared/util/types";
import { trTR as coretrTR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StoreContext } from "shared/context/store-context";

const TransferList = (props) => {
  const store = useContext(StoreContext);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    trTR,
    coretrTR,
    {
      GridCsvExportOptions: { fileName: "gh" },
    }
  );

  const columns = [
    {
      field: "school",
      headerName: "Firma",
      width: 200,
      valueGetter: (params) => {
        try {
          return store.schoolOptions.find((school) => school.value === params.row.school).label;
        } catch {
          return "";
        }
      },
    },
    {
      field: "transferdate",
      headerName: "Tarih",
      width: 100,
    },
    { field: "name", headerName: "İsim", width: 300 },
    {
      field: "transfertime",
      headerName: "Havaalanı",
      width: 100,
      valueGetter: (params) => {
        try {
          return params.row.transfertime.split(" ")[4].substring(0, 5);
        } catch {
          return "";
        }
      },
    },
    {
      field: "type",
      headerName: "Türü",
      width: 200,
      valueGetter: (params) => transfertypes.find((item) => item.value === params.row.type).label,
    },
    {
      field: "done",
      headerName: "Durum",
      width: 150,
      valueGetter: (params) => transferStatus.find((item) => item.value === params.row.done).label,
      cellClassName: (params) => (params.row.done ? "processdone" : "processongoing"),
    },
  ];

  const OnSelectionChangeHandler = (event) => {
    props.onItemSelect(event[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="itemListContainer">
      <ThemeProvider theme={theme}>
        <DataGrid
          key={props.items}
          density="compact"
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={props.items}
          columns={columns}
          pageSize={100}
          getRowId={(r) => r.id}
          onSelectionModelChange={OnSelectionChangeHandler}
        />
      </ThemeProvider>
    </div>
  );
};

export default TransferList;
