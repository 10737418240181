import React, { useContext, useState } from "react";
import {
  DataGrid,
  trTR,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import "./AccountingList.css";
import { trTR as coretrTR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StoreContext } from "shared/context/store-context";

const ReceiptList = (props) => {
  const visibilityJSON = localStorage.getItem("invoice_visibility");
  const visibilityModel = visibilityJSON ? JSON.parse(visibilityJSON) : {};
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(visibilityModel);
  const filterJSON = localStorage.getItem("invoice_filter");
  const initialFilterModel = filterJSON ? JSON.parse(filterJSON) : { items: [] };
  const [filterModel, setFilterModel] = useState(initialFilterModel);

  const store = useContext(StoreContext);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    trTR,
    coretrTR,
    {
      GridCsvExportOptions: { fileName: "gh" },
    }
  );

  const columns = [
    {
      field: "school",
      headerName: "Okul",
      width: 300,
      valueGetter: (params) => {
        try {
          const contractId = store.contractsOptions.find(
            (item) => item.value.toString() === params.row.id.toString()
          ).value;

          const schoolid = store.contracts
            .find((contract) => contract.id.toString() === contractId.toString())
            .student.school.toString();
          return store.schoolOptions.find((school) => school.value.toString() === schoolid.toString()).label;
        } catch (e) {
          return "";
        }
      },
    },

    {
      field: "contract",
      headerName: "Veli - Öğrenci",
      width: 500,
      valueGetter: (params) => {
        try {
          return store.contractsOptions.find((item) => item.value.toString() === params.row.id.toString()).label;
        } catch (e) {
          return "";
        }
      },
    },

    { field: "finalAmount", headerName: "Meblağ", width: 150 },

    // { field: 'name', headerName: 'Hizmet Adı', width: 300 },
    // { field: 'serviceStartDate', headerName: 'Hizmet Başlangıç Tarihi', width: 200 },
    // { field: 'serviceEndingDate', headerName: 'Hizmet Bitiş Tarihi', width: 200 },
    // { field: 'amount', headerName: 'Meblağ', width: 200 },
  ];

  const OnSelectionChangeHandler = (event) => {
    props.onItemSelect(event[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="itemListContainer">
      <ThemeProvider theme={theme}>
        <DataGrid
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            localStorage.setItem("invoice_visibility", JSON.stringify(newModel));
            setColumnVisibilityModel(newModel);
          }}
          filterModel={filterModel}
          onFilterModelChange={(newModel) => {
            localStorage.setItem("invoice_filter", JSON.stringify(newModel));
            setFilterModel(newModel);
          }}
          key={props.items}
          density="compact"
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={props.items}
          columns={columns}
          pageSize={100}
          getRowId={(r) => r.id}
          onSelectionModelChange={OnSelectionChangeHandler}
        />
      </ThemeProvider>
    </div>
  );
};

export default ReceiptList;
