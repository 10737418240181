import React from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { CgClose } from "react-icons/cg";

import "./GenericError.css";

const GenericError = (props) => {
  const [show, setShow] = React.useState(true);

  return (
    <React.Fragment>
      {show && (
        <div className="generic-error" style={props.style}>
          <RiErrorWarningFill className="warning-icon" />
          <span className="text">{props.text}</span>
          <div className="close-icon-container" onClick={() => setShow(false)}>
            <CgClose className="close-icon" />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default GenericError;
