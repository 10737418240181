import React, { useContext, useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "shared/components/UIElements/TabPanel";
import PropTypes from "prop-types";

// #region Components
import ExcelUpload from "shared/components/FormElements/ExcelUpload";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import PersonelTab from "./tabs/Personel";
import ServiceTab from "./tabs/Service";
import AutomatedServicesTab from "./tabs/AutomatedServices";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE } from "shared/util/validators";

// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "../Item.css";
import { CheckTimeString } from "shared/util/time";

const ShiftAnalyzer = (props) => {
  const {
    schools,
    users,
    shiftOptions,
    shifts,
    driverOptions,
    schoolOptions,
    UpdateShift,
    DeleteShift,
    shiftPersonelServiceOptions,
    shiftPersonelServices,
    userOptions,
    UpdatePersonelService,
    InsertPersonelService,
    DeletePersonelService,
  } = useContext(StoreContext);

  const auth = useContext(AuthContext);
  const { error, sendRequest, clearError } = useHttpClient();

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `shiftanalyzer-tab-${index}`,
      "aria-controls": `horizontal-tabpanel-${index}`,
    };
  }

  const school = schools.find((school) => school.id === props.shift.school);

  const [usersInTheShift, setUsersInTheShift] = useState(
    users.filter((user) => props.shift.users?.includes(user.id)) || []
  );

  const [servicesIntheShift, setServicesInTheShift] = useState(
    shiftPersonelServices.filter((service) => service.shift === props.shift.id && service.type === props.type)
  );

  const addPersonel = async (personelId) => {
    if (!usersInTheShift.map((user) => user.id).includes(personelId)) {
      setUsersInTheShift([...usersInTheShift, users.find((user) => user.id === personelId)]);
      await updateUsersInTheShift([...usersInTheShift.map((user) => user.id), personelId]);
    }
  };

  const removePersonel = async (personelId) => {
    await changeUserService(personelId, null);
    await updateUsersInTheShift(usersInTheShift.map((user) => user.id).filter((id) => id !== personelId));
    setUsersInTheShift(usersInTheShift.filter((user) => user.id !== personelId));
  };

  const updateUsersInTheShift = async (users) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/shifts/${props.shift.id}`,
        "PATCH",
        JSON.stringify({ users }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      UpdatePersonelService(responseData.personelservice);
    } catch (err) {}
  };

  const updateUsersInTheService = async (serviceId, users) => {
    if (!serviceId || !users || !users.length) {
      return;
    }
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/personelservices/${serviceId}`,
        "PATCH",
        JSON.stringify({ users }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      UpdatePersonelService(responseData.personelservice);
    } catch (err) {}
  };

  const updateUsersDetailsInTheService = async (serviceId, usersdetails) => {
    if (!serviceId || !usersdetails || !usersdetails.length) {
      return;
    }
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/personelservices/${serviceId}`,
        "PATCH",
        JSON.stringify({ usersdetails }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      UpdatePersonelService(responseData.personelservice);
    } catch (err) {}
  };

  const changeUserService = async (userId, serviceId) => {
    const user = usersInTheShift.find((user) => user.id === userId);
    const newServicesIntheShift = [...servicesIntheShift];
    if (user) {
      const currentUserService = newServicesIntheShift.find((service) => service.users?.includes(userId));
      if (currentUserService) {
        if (currentUserService?.id === serviceId) {
          return;
        }
        currentUserService.users = currentUserService.users.filter((id) => id !== userId);
        await updateUsersInTheService(currentUserService.id, currentUserService.users);
      }
      if (serviceId) {
        const service = newServicesIntheShift.find((service) => service.id === serviceId);
        if (service) {
          service.users.push(userId);
          await updateUsersInTheService(service.id, service.users);
        }
      }
      setServicesInTheShift([...newServicesIntheShift]);
    }
  };

  const changeUserTransferTime = async (userId, transferTime) => {
    if (transferTime === null || transferTime === undefined) {
      return;
    }
    if (transferTime && transferTime.length) {
      if (!CheckTimeString(transferTime)) {
        alert("Lütfen geçerli bir saat giriniz. Örnek: 21:30");
        return;
      }
    }

    const user = usersInTheShift.find((user) => user.id === userId);
    const newServicesIntheShift = [...servicesIntheShift];
    if (user) {
      const currentUserService = newServicesIntheShift.find((service) => service.users?.includes(userId));
      if (currentUserService) {
        const currentUsersDetails = currentUserService.usersdetails;
        const currentUserDetails = currentUsersDetails.find((userdetail) => userdetail?.user === userId);
        const currentUserTransferTime = currentUserDetails?.transfertime;
        if (currentUserTransferTime && currentUserTransferTime === transferTime) {
          return;
        }
        if (transferTime) {
          if (!currentUserDetails) {
            currentUserService.usersdetails.push({ user: userId, transfertime: transferTime });
          } else if (currentUserDetails) {
            currentUserDetails.transfertime = transferTime;
          }
        } else if (
          (transferTime === null || transferTime === undefined || transferTime === "") &&
          currentUserTransferTime
        ) {
          if (!currentUserDetails) {
            currentUserService.usersdetails.push({ user: userId, transfertime: "" });
          } else if (currentUserDetails) {
            currentUserDetails.transfertime = "";
          }
        } else {
          return;
        }

        await updateUsersDetailsInTheService(currentUserService.id, currentUsersDetails);
        setServicesInTheShift([...newServicesIntheShift]);
      }
    }
  };

  const addService = async (name, drivers, plate, starttime, stoptime) => {
    try {
      const infoToBeSent = {
        school: school.id,
        type: props.type,
        date: props.shiftDate,
        shift: props.shift.id,
        users: [],
        isshift: true,
        status: 1,
        name: name,
        drivers: drivers,
        plate: plate,
        starttime: starttime,
        stoptime: stoptime,
      };
      const newServiceData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/personelservices`,
        "POST",
        JSON.stringify(infoToBeSent),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      InsertPersonelService(newServiceData.personelservice);
      setServicesInTheShift([newServiceData.personelservice, ...servicesIntheShift]);
    } catch (err) {}
  };

  const deleteService = async (serviceId) => {
    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/personelservices/${serviceId}`, "DELETE", null, {
        "Content-Type": "application/json",
      });
      DeletePersonelService(serviceId);
      setServicesInTheShift(servicesIntheShift.filter((service) => service.id !== serviceId));
    } catch (err) {}
  };

  // if (isLoading) {
  //   return (
  //     <div className="center">
  //       <LoadingSpinner />
  //     </div>
  //   );
  // }

  // const handleAlertClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setShowAlert(false);
  // };

  // const clearErrorMessage = () => {
  //   setErrorMessage(null);
  // };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab
            label={props.type === "100" ? "Vardiya Girişi Personelleri" : "Vardiya Çıkışı Personelleri"}
            {...a11yProps(0)}
          />
          <Tab
            label={props.type === "100" ? "Vardiya Girişi Servisleri" : "Vardiya Çıkışı Servisleri"}
            {...a11yProps(1)}
          />
          <Tab label={"Otomatik Servis İşlemleri"} {...a11yProps(2)} />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <PersonelTab
          users={usersInTheShift}
          services={servicesIntheShift}
          shift={props.shift}
          shiftDate={props.shiftDate}
          shiftDisplayName={props.shiftDisplayName}
          changeUserService={changeUserService}
          changeUserTransferTime={changeUserTransferTime}
          addPersonel={addPersonel}
          removePersonel={removePersonel}
        ></PersonelTab>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <ServiceTab
          school={school}
          type={props.type}
          users={usersInTheShift}
          services={servicesIntheShift}
          shift={props.shift}
          shiftDate={props.shiftDate}
          shiftDisplayName={props.shiftDisplayName}
          changeUserService={changeUserService}
          addPersonel={addPersonel}
          removePersonel={removePersonel}
          addService={addService}
          deleteService={deleteService}
        ></ServiceTab>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <AutomatedServicesTab
          school={school}
          type={props.type}
          users={usersInTheShift}
          shift={props.shift}
          shiftDate={props.shiftDate}
        />
      </TabPanel>

      {/* <ErrorModal error={errorMessage} onClear={clearErrorMessage} /> */}

      {/* <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          {alertMessageRef.current}
        </MuiAlert>
      </Snackbar> */}
      {/* 
      <div className="new-transfer-item">
        <DndProvider backend={HTML5Backend}>
          <ShiftDragger
            shift={props.shift}
            type={props.type}
            services={shiftPersonelServices.filter(
              (service) => service.shift === props.shift && service.type === props.type
            )}
            users={usersIntheShift}
            // school={formState.inputs.school.value}
            // transfers={plane.transfers}
            // onTransferCreated={() => handleTransferCreated(plane.planeName, plane.planeDepartureTime)}
          />
        </DndProvider>
      </div> */}
    </React.Fragment>
  );
};

export default ShiftAnalyzer;
