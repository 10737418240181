import React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

import ReportSummary from "./ReportSummary";
import ReportVehicles from "./ReportVehicles";
import ReportTransfers from "./ReportTransfers";

import "./AccountingItem.css";

import "react-datepicker/dist/react-datepicker.css";
import TabPanel from "shared/components/UIElements/TabPanel";

const Report = (props) => {
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `Report-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  return (
    <React.Fragment>
      <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Genel Özet" {...a11yProps(0)} />
          <Tab label="Hakediş Analizleri" {...a11yProps(1)} />
          <Tab label="Transfer Raporları" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <ReportSummary></ReportSummary>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <ReportVehicles></ReportVehicles>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <ReportTransfers></ReportTransfers>
      </TabPanel>
    </React.Fragment>
  );
};

export default Report;
