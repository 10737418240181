import React, { useContext, useEffect, useCallback } from "react";

import Button from "shared/components/FormElements/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";

import { formatDate } from "shared/util/time";

//users: {id, name, phonenumber}
//currentSmses: {user: {id}, text, date}
const ServiceSms = ({ users, serviceId }) => {
  const { isLoading, sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);

  const [currentSmses, setCurrentSmses] = React.useState([]);

  const [isSmsSending, setIsSmsSending] = React.useState(false);

  const loadSMS = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/xrytutdvsdfds/${serviceId}`, "POST");
      setCurrentSmses(responseData.smses);
    } catch (err) {}
  }, [sendRequest, serviceId]);

  const fetchItem = useCallback(async () => {
    try {
      await loadSMS();
    } catch (err) {}
  }, [loadSMS]);

  useEffect(() => {
    fetchItem();
  }, [fetchItem]);

  const SendSingleSMS = async (userId) => {
    setIsSmsSending(true);
    await SendSMS(userId);
    await loadSMS();
    setIsSmsSending(true);
  };

  const SendSMSToAll = async () => {
    setIsSmsSending(true);
    const usersToSend = users.map((user) => user.id);
    for (let i = 0; i < usersToSend.length; i++) {
      await SendSMS(usersToSend[i], true);
    }
    await loadSMS();
    setIsSmsSending(false);
  };

  const SendSMS = async (userId, isMultiple) => {
    const message = document.getElementById("text" + (isMultiple ? "All" : userId)).value.trim();
    if (message.length === 0) {
      alert("Mesaj boş olamaz!");
      setIsSmsSending(false);
      return;
    }
    const foundUser = users.find((user) => user.id.toString() === userId.toString());

    const foundPhone = foundUser.phonenumber;
    const vehicleRoute = serviceId.toString();
    const phone = foundPhone;
    //const phone = "5355802151";
    const text = message;
    const date = formatDate(new Date());
    const status = 1;
    const smsData = { user: userId.toString(), student: userId.toString(), phone, text, date, status, vehicleRoute };
    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/xrytutdvsdfds`, "POST", JSON.stringify(smsData), {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      });
    } catch (err) {}
  };

  return (
    <div>
      <div>
        <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
          <textarea
            style={{ margin: "16px" }}
            key={"SMSAll"}
            id={"textAll"}
            cols="30"
            rows="7"
            maxLength="155"
          ></textarea>
          <div width="60px" style={{ margin: "16px", marginTop: "0px" }}>
            <Button type="button" disabled={isSmsSending} danger onClick={() => SendSMSToAll()}>
              Toplu SMS Gönder!
            </Button>
          </div>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table sx={{ width: "100%", justifyContent: "center", margin: "auto" }} stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="10%">
                <b>Kullanıcı</b>
              </TableCell>
              <TableCell width="45%">
                <b>Giden Mesajlar</b>
              </TableCell>
              <TableCell width="45%" align="right">
                <b>Yeni Mesaj (HER MESAJ EN FAZLA 155 KARAKTER)</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {user.name} <br /> {user.phonenumber}
                </TableCell>

                <TableCell component="th" scope="row">
                  {currentSmses.filter((sms) => sms?.user?.id?.toString() === user?.id?.toString()).length > 0 ? (
                    <div>
                      {currentSmses
                        .filter((sms) => sms?.user?.id?.toString() === user.id.toString())
                        .map((sms) => (
                          <div key={sms.id}>
                            {sms.text}
                            <br />
                            <i>{sms.date}</i>
                            <br />
                            <br />
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div>Mesaj Yok</div>
                  )}
                </TableCell>

                <TableCell scope="row" align="right">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <textarea
                      style={{ margin: "16px" }}
                      key={"SMS" + user.id}
                      id={"text" + user.id.toString().trim()}
                      cols="30"
                      rows="7"
                      maxLength="155"
                    ></textarea>
                    <div width="60px">
                      <Button
                        type="button"
                        disabled={isSmsSending}
                        danger
                        onClick={() => SendSingleSMS(user.id.toString().trim())}
                      >
                        SMS Gönder
                      </Button>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ServiceSms;
