import React, { useEffect } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { RiLockPasswordLine } from "react-icons/ri";

import Card from "shared/components/UIElements/Card";
import GenericError from "shared/components/UIElements/GenericError";
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import Link from "shared/components/FormElements/Link";
import { VALIDATOR_EMAIL } from "shared/util/validators";
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import "./Auth.css";

const Auth = () => {
  const { t } = useTranslation();
  const [genericError, setGenericError] = React.useState(null);

  const { isLoading, sendRequest } = useHttpClient();
  const [showResetSuccess, setShowResetSuccess] = React.useState(false);
  const [showResetFailed, setShowResetFailed] = React.useState(false);
  const [failedMessage1, setFailedMessage1] = React.useState("Error");
  const [failedMessage2, setFailedMessage2] = React.useState("");

  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    if (localStorage.getItem("GenericError")) {
      setGenericError(localStorage.getItem("GenericError"));
      localStorage.removeItem("GenericError");
    }
  }, []);

  const onHandleInput = () => {
    setShowResetSuccess(false);
    setShowResetFailed(false);
  };

  const resetSubmitHandler = async (event) => {
    event.preventDefault();
    let language = (i18next?.language || window?.localStorage?.i18nextLng || "en").substring(0, 2);
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/resetpassword`,
        "POST",
        JSON.stringify({
          email: formState.inputs.email.value,
          language: language,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (responseData.error) {
        setShowResetFailed(true);
        setShowResetSuccess(false);

        const firstDotIndex = responseData.error?.indexOf(".");
        if (firstDotIndex !== -1) {
          setFailedMessage1(responseData.error?.slice(0, firstDotIndex + 1));
          setFailedMessage2(responseData.error?.slice(firstDotIndex + 1).trim());
        } else {
          setFailedMessage1(t("anErrorOccurred"));
          setFailedMessage2("");
        }
      } else {
        setShowResetFailed(false);
        setShowResetSuccess(true);
      }
    } catch (err) {
      const firstDotIndex = err?.message?.indexOf(".");
      if (firstDotIndex !== -1 && err.code === 403) {
        setFailedMessage1(err?.message?.slice(0, firstDotIndex + 1));
        setFailedMessage2(err?.message?.slice(firstDotIndex + 1).trim());
      } else {
        setFailedMessage1(err?.message);
        setFailedMessage2("");
      }

      setShowResetFailed(true);
      setShowResetSuccess(false);
    }
  };

  return (
    <React.Fragment>
      {genericError && <GenericError text={genericError} />}
      <Card className="authentication">
        <RiLockPasswordLine className="auth-main-icon" />
        <h2>{t("resetPassword")}</h2>
        <hr />
        <form onSubmit={resetSubmitHandler}>
          <Input
            element="input"
            id="email"
            type="email"
            label={t("email")}
            validators={[VALIDATOR_EMAIL()]}
            onInput={inputHandler}
            onChange={onHandleInput}
            disabled={isLoading || showResetSuccess || showResetFailed}
          />

          <Button
            type="submit"
            isLoading={isLoading}
            disabled={!formState.isValid || isLoading || showResetSuccess || showResetFailed}
          >
            {t("send")}
          </Button>

          <div className="auth-bottom-container">
            <div className="auth-messages-container">
              {showResetSuccess && (
                <div className="login-failed">
                  <p>{t("passwordRecoveryText")}</p>
                </div>
              )}
              {showResetFailed && (
                <div className="login-failed">
                  <p>{failedMessage1}</p>
                  <p>{failedMessage2}</p>
                </div>
              )}
            </div>
            <div className="auth-links-container">
              <Link to="/login" styles={{ float: "left", fontWeight: "bold" }}>
                {t("tryLogin")}
              </Link>
              <Link to="/sign-up" styles={{ float: "right", fontWeight: "bold" }}>
                {t("signup")}
              </Link>
            </div>
          </div>
        </form>
      </Card>
    </React.Fragment>
  );
};

export default Auth;
