import React, { useEffect, useState, useContext, useCallback, useRef } from "react";

import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import AirportIcon from "@mui/icons-material/Flight";

import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import Marker from "shared/components/UIElements/Marker";

import { AIRPORT_ID, AIRPORT_LAT, AIRPORT_LNG } from "shared/util/location";
import { VALIDATOR_REQUIRE } from "shared/util/validators";
import { GetStartStopTime } from "shared/util/time";

import "./Item.css";

const TransferRouteItem = (props) => {
  const auth = useContext(AuthContext);
  const { storeReady, transferregionOptions, driverOptions, students, UpdateVehicleRoute, DeleteVehicleRoute } =
    useContext(StoreContext);

  const [airportTime, setAirportTime] = useState("");

  const [currentStudents, setCurrentStudents] = useState();
  const [showMap, setShowMap] = useState(false);

  const [loadedItem, setLoadedItem] = useState();

  const initialTransferRegion = useRef();

  const itemId = props.itemid;

  const ItemTypeTR = "Servis";
  const ItemType = "vehicleRoute";
  const ItemEndPointType = "vehicleroutes";

  // #region Standard Interface functions
  const { error, sendRequest, clearError } = useHttpClient();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  // #endregion Standard Interface functions

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: true },
      type: { value: "", isValid: true },
      name: { value: null, isValid: true },
      driver: { value: [], isValid: true },
      transferregion: { value: null, isValid: true },
      plate: { value: "", isValid: true },
      starttime: { value: null, isValid: true },
      stoptime: { value: null, isValid: true },
      student: { value: [], isValid: true },
    },
    true
  );

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);

      try {
        initialTransferRegion.current = transferregionOptions.find(
          (option) => responseData[ItemType].routelocations[0].transferregion.toString() === option.value
        );
      } catch {
        initialTransferRegion.current = null;
      }

      let currentAirportTime = "";
      try {
        currentAirportTime = responseData[ItemType].name.split(" ")[0];
        setAirportTime(currentAirportTime);
      } catch {}

      // setDrivers(
      //   users
      //     .filter((item) => responseData[ItemType].driver.includes(item.id))
      //     .map((item) => {
      //       return {
      //         id: item.id,
      //         name: item.name,
      //         phonenumber: item.phonenumber,
      //       };
      //     })
      // );

      setCurrentStudents(
        students
          .filter((item) => responseData[ItemType].student.includes(item.id))
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
              location: responseData[ItemType].type === 100 ? item.locationgeton : item.locationgetoff,
              identifier: item.identifier,
              phonenumber: item.phonenumber,
              transfertime: responseData[ItemType].routelocations.find(
                (routelocation) => routelocation.student === item.id
              ).transfertime,
            };
          })
      );
      setLoadedItem(responseData[ItemType]);
    } catch (err) {}
  }, [sendRequest, itemId, transferregionOptions, students]);

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`, "DELETE", null, {
        "Content-Type": "application/json",
      });
      DeleteVehicleRoute(itemId);
      props.onDelete();
    } catch (err) {}
  };
  // #endregion Standard API calls

  useEffect(() => {
    fetchItem();
  }, [sendRequest, itemId, fetchItem]);

  const itemUpdateSubmitHandler = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();

      let currentRouteLocations = loadedItem.routelocations;

      currentRouteLocations[0].transferregion = formState.inputs.transferregion.value;

      let responseData;
      try {
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
          "PATCH",
          JSON.stringify({
            name: formState.inputs.name.value,
            type: loadedItem.type,
            school: loadedItem.school,
            driver: formState.inputs.driver.value,
            plate: formState.inputs.plate.value,
            starttime: formState.inputs.starttime.value,
            stoptime: formState.inputs.stoptime.value,
            student: loadedItem.student,
            status: loadedItem.status,
            location: loadedItem.location,
            routelocations: currentRouteLocations,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        UpdateVehicleRoute(responseData[ItemType]);
        setLoadedItem(responseData[ItemType]);
      } catch (err) {}
    },
    [sendRequest, itemId, formState, auth.token, loadedItem, UpdateVehicleRoute]
  );

  const updateCurrentStudents = () => {
    setCurrentStudents(
      students
        .filter((item) => loadedItem.student.includes(item.id))
        .map((item) => {
          return {
            id: item.id,
            name: item.name,
            location: loadedItem.type === 100 ? item.locationgeton : item.locationgetoff,
            identifier: item.identifier,
            phonenumber: item.phonenumber,
          };
        })
    );
  };

  const toggleShowMap = () => {
    setShowMap(!showMap);
  };

  if (!storeReady || !loadedItem) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Emin misiniz?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              Hayır
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>{ItemTypeTR} silme işlemini onaylıyor musunuz? Bu işlem geri alınamaz.</p>
      </Modal>

      {loadedItem && currentStudents && (
        <form className="item-form-route" onSubmit={itemUpdateSubmitHandler}>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div className="transferRouteItemStyle transferRouteItemButton" style={{ width: "5%" }}>
                <Button type="button" danger onClick={showDeleteWarningHandler}>
                  SİL
                </Button>
              </div>
              <div className="transferRouteItemStyle">
                <Input
                  id="name"
                  element="input"
                  type="text"
                  label="Rota ismi"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="İsim giriniz."
                  initialValue={loadedItem.name}
                  initialValid={true}
                  onInput={inputHandler}
                />
              </div>

              <div className="transferRouteItemStyle">
                <CustomSelect
                  options={driverOptions}
                  id="driver"
                  isMulti={true}
                  onInput={inputHandler}
                  label="Rotaya yetkisi olan sürücüler"
                  initialValue={driverOptions.filter((option) => loadedItem.driver.includes(option.value))}
                  initialValid={true}
                ></CustomSelect>
              </div>

              <div className="transferRouteItemStyle">
                <CustomSelect
                  options={transferregionOptions}
                  id="transferregion"
                  isMulti={false}
                  onInput={inputHandler}
                  label="Bölge"
                  initialValue={initialTransferRegion.current}
                  initialValid={true}
                ></CustomSelect>
              </div>

              <div className="transferRouteItemStyle" style={{ width: "5%" }}>
                <Input
                  id="starttime"
                  element="input"
                  type="text"
                  label="Başlama"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                  onInput={inputHandler}
                  initialValue={GetStartStopTime(loadedItem.starttime)}
                  initialValid={true}
                />
              </div>
              <div className="transferRouteItemStyle" style={{ width: "5%" }}>
                <Input
                  id="stoptime"
                  element="input"
                  type="text"
                  label="Bitiş"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                  onInput={inputHandler}
                  initialValue={GetStartStopTime(loadedItem.stoptime)}
                  initialValid={true}
                />
              </div>

              <div className="transferRouteItemStyle" style={{ width: "5%" }}>
                <Input
                  id="plate"
                  element="input"
                  type="text"
                  label="Plaka"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="Zorunlu alan"
                  onInput={inputHandler}
                  initialValue={loadedItem.plate}
                  initialValid={true}
                />
              </div>

              <div className="transferRouteItemStyle transferRouteItemButton" style={{ width: "10%" }}>
                <Button type="button" danger o onClick={toggleShowMap}>
                  {showMap ? "Haritayı Gizle" : "Haritayı Göster"}
                </Button>
              </div>
              <div className="transferRouteItemStyle transferRouteItemButton" style={{ width: "10%" }}>
                <Button type="submit" disabled={!formState.isValid}>
                  Güncelle
                </Button>
              </div>
            </div>

            {showMap && (
              <React.Fragment>
                <Button type="button" danger o onClick={updateCurrentStudents}>
                  Haritayı Güncelle
                </Button>

                <div className="map-container">
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
                      language: "tr",
                      region: "tr",
                      libraries: ["drawing"],
                    }}
                    options={function (maps) {
                      return {
                        mapTypeControl: true,
                        mapTypeId: "roadmap",
                        mapTypeControlOptions: {
                          mapTypeIds: ["roadmap", "satellite"],
                          style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                          position: maps.ControlPosition.TOP_LEFT,
                        },
                      };
                    }}
                    //defaultCenter={markerLocationMorning}
                    center={currentStudents[0] ? currentStudents[0].location : { lat: 36.8969, lng: 30.7133 }}
                    defaultZoom={12}
                  >
                    {currentStudents.map((student) => {
                      return (
                        <Marker
                          Icon={RoomIcon}
                          lat={student.location.lat}
                          lng={student.location.lng}
                          key={student.id}
                          info={student.name + " - " + student.transfertime}
                          initialShow={true}
                        />
                      );
                    })}
                    <Marker
                      Icon={AirportIcon}
                      lat={AIRPORT_LAT}
                      lng={AIRPORT_LNG}
                      key={AIRPORT_ID}
                      info={"Havaalanı - " + airportTime}
                      alwaysShowMarker={true}
                    />
                  </GoogleMapReact>
                </div>
              </React.Fragment>
            )}
          </div>
        </form>
      )}
    </React.Fragment>
  );
};

export default TransferRouteItem;
