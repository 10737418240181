import React, { useState, useContext } from "react";
import Service from "./Service";
import Button from "shared/components/FormElements/Button";
import { AIRPORT_ID, tsp, calculateDistance } from "shared/util/location";
import { CheckTimeString } from "shared/util/time";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import Modal from "shared/components/UIElements/Modal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import CustomSelect from "shared/components/FormElements/Select";
import { useForm } from "shared/hooks/form-hook";
import { GenerateString } from "shared/util/util";

const ShiftDragger = (props) => {
  //eslint-disable-next-line
  const [people, setPeople] = useState(props.users);
  const [services, setServices] = useState(props.services);
  const [servicesCreated, setServicesCreated] = useState(false);
  const [isServicesCreating, setIsServicesCreating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    users,
    shiftOptions,
    shifts,
    drivers,
    driverOptions,
    schoolOptions,
    UpdateShift,
    DeleteShift,
    shiftPersonelServiceOptions,
    shiftPersonelServices,
    userOptions,
    UpdatePersonelService,
    onServicesCreated,
  } = useContext(StoreContext);

  const store = useContext(StoreContext);
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);

  const [results, setResults] = useState([]);

  const [formStatePersonel, inputHandlerPersonel] = useForm(
    {
      personelUnique: { value: "", isValid: true },
    },
    true
  );

  const handleDrop = (userId, serviceId) => {
    const usersCurrenctService = services.find((service) => service.users.map((user) => user.id).includes(userId));
    const selectedUser = usersCurrenctService.users.find((user) => user.id === userId);

    const updatedServices = services.map((service) => {
      if (service.id === serviceId) {
        if (service.users.find((user) => user.id === userId)) {
          return service;
        }
        return {
          ...service,
          users: [...service.users, selectedUser],
        };
      } else {
        return {
          ...service,
          users: [...service.users.filter((user) => user.id !== userId)],
        };
      }
    });
    setServices(updatedServices);
    copyServicesToResults(updatedServices);
  };

  const userDownHandler = (serviceId, userId) => {
    const updatedServices = services.map((service) => {
      if (service.id === serviceId) {
        const pos = service.users.map((user) => user.id).indexOf(userId);
        let newPeople = [];

        for (let i = 0; i < service.users.length; i++) {
          if (i === pos && i !== service.users.length - 1) {
            newPeople.push(service.users[i + 1]);
            newPeople.push(service.users[i]);
            i++;
            continue;
          } else {
            newPeople.push(service.users[i]);
          }
        }
        return {
          ...service,
          users: [...newPeople],
        };
      } else {
        return {
          ...service,
        };
      }
    });
    setServices(updatedServices);
    copyServicesToResults(updatedServices);
  };

  const userUpHandler = (serviceId, userId) => {
    const updatedServices = services.map((service) => {
      if (service.id === serviceId) {
        const pos = service.users.map((user) => user.id).indexOf(userId);
        let newPeople = [];

        for (let i = 0; i < service.users.length; i++) {
          if (i === pos && i !== 0) {
            const prev = newPeople.pop();
            newPeople.push(service.users[i]);
            newPeople.push(prev);
            continue;
          } else {
            newPeople.push(service.users[i]);
          }
        }
        return {
          ...service,
          users: [...newPeople],
        };
      } else {
        return {
          ...service,
        };
      }
    });
    setServices(updatedServices);
    copyServicesToResults(updatedServices);
  };

  const deletePersonHandler = (serviceId, userId) => {
    const updatedServices = services.map((service) => {
      if (service.id === serviceId) {
        return {
          ...service,
          users: [...service.users.filter((user) => user.id !== userId)],
        };
      }
      return service;
    });

    setServices(updatedServices);
    copyServicesToResults(updatedServices);
  };

  const changeServiceName = (serviceId, newName) => {
    const updatedServices = services.map((service) => {
      if (service.id === serviceId) {
        return {
          ...service,
          name: newName,
        };
      }
      return service;
    });
    setServices(updatedServices);
    copyServicesToResults(updatedServices);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizeHandler = (serviceId) => {
    const updatedServices1 = services.map((service) => {
      if (service.id === serviceId) {
        const currentUsers = service.users;
        const currentUserIds = currentUsers.map((user) => user.id);

        let calculatedDistances = [];
        for (let i = 0; i < currentUserIds.length; i++) {
          {
            calculatedDistances.push({
              distance: calculateDistance([currentUserIds[i], props.school.id], props.distances, props.type),
              userId: currentUserIds[i],
            });
          }
        }

        calculatedDistances.sort((a, b) => b.distance - a.distance);

        let newPeople = [];

        for (let i = 0; i < calculatedDistances.length; i++) {
          const personId = calculatedDistances[i].userId;
          const person = currentUsers.find((person) => person.id === personId);
          if (person) {
            newPeople.push(person);
          }
        }
        return {
          ...service,
          users: [...newPeople],
        };
      }
      return service;
    });
    setServices(updatedServices1);
    copyServicesToResults(updatedServices1);

    return;

    const updatedServices = services.map((service) => {
      if (service.id === serviceId) {
        const currentUsers = service.users;
        const currentUserIds = currentUsers.map((user) => user.id);

        const result = tsp(
          props.school.id,
          currentUserIds,
          props.distances.filter(
            (distance) => currentUserIds.includes(distance.from) && currentUserIds.includes(distance.to)
          ),
          props.type,
          props.school.id
        );
        let newPeople = [];

        for (let i = 0; i < result.route.length; i++) {
          const personId = result.route[i];
          const person = currentUsers.find((person) => person.id === personId);
          if (person) {
            newPeople.push(person);
          }
        }
        return {
          ...service,
          users: [...newPeople],
        };
      }
      return service;
    });
    setServices(updatedServices);
    copyServicesToResults(updatedServices);
  };

  const copyServicesToResults = (currentServices) => {
    const updatedResults = currentServices.map((service) => {
      return {
        id: service.id,
        users: [...service.users],
        drivers: service.drivers,
        name: service.name,
      };
    });
    setResults(updatedResults);
  };

  const saveResultHandler = (serviceId, calculatedPeople, drivers, name) => {
    const updatedResults = results.map((result) => {
      if (result.id === serviceId) {
        return {
          ...result,
          users: [...calculatedPeople],
          drivers: drivers,
          name: name,
        };
      }
      return result;
    });
    setResults(updatedResults);
  };

  const createTransfer = async () => {
    setIsServicesCreating(true);
    let problems = "";

    if (!results.length) {
      setErrorMessage("Vardiya servis onayı gerçekleştiremezsiniz.");
      setShowModal(true);
      setIsServicesCreating(false);
      return;
    }
    for (let i = 0; i < results.length; i++) {
      const result = results[i];

      const serviceName = document.getElementById("servicename" + result.id)?.value;
      const serviceDriverName = document.getElementById("newdriver" + result.id)?.textContent;
      const serviceDriver = drivers.find((driver) => driver.name === serviceDriverName)?.id;

      if (!serviceName?.length) {
        problems += "Girilmemiş transfer ismi bulunuyor.\n";
      }

      if (!serviceDriver) {
        problems += serviceName + " için geçerli sürücü seçilmemiş.\n";
      }

      if (!result?.users?.length) {
        problems += serviceName + " için yolcu bulunmuyor. Bu aracı siliniz ya da tekrar düzenleyiniz.\n";
      }

      for (let j = 0; j < result.users.length; j++) {
        const person = result.users[j];
        if (!person?.lat || !person?.lng) {
          problems += serviceName + " için " + person.name + " isimli yolcunun konum bilgileri bulunmuyor.\n";
        }

        if (props.type === "100" && (!person?.pickupTime || !CheckTimeString(person.pickupTime))) {
          problems += serviceName + " için " + person.name + " isimli yolcunun pickup saati hatalı.\n";
        }
      }
    }

    if (problems.length) {
      setErrorMessage(problems);
      setShowModal(true);
      setIsServicesCreating(false);
      return;
    }

    let serviceRouteIds = [];

    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      const serviceName = document.getElementById("servicename" + result.id)?.value;
      const serviceDriverName = document.getElementById("newdriver" + result.id)?.textContent;
      const serviceDriver = drivers.find((driver) => driver.name === serviceDriverName)?.id;

      const type = props.type;
      const userIds = result.users.map((person) => person.id);

      const usersdetails = result.users.map((user) => {
        return {
          user: user.id,
          transfertime: type === "100" ? user.pickupTime : "",
        };
      });

      try {
        const personelServiceData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/personelservices`,
          "POST",
          JSON.stringify({
            name: serviceName,
            type: type,
            school: props.school.id,
            drivers: [serviceDriver],
            plate: store.drivers.find((driver) => driver.id === serviceDriver)?.email || "",
            date: props.shiftDate,
            starttime: "00:01",
            stoptime: "23:59",
            useUsersDetails: true,
            isshift: true,
            day: props.shift.day,
            shift: props.shift.id,
            status: 1,
            location: { lat: -1.1, lng: -1.1 },
            users: userIds,
            usersdetails: usersdetails,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );

        store.InsertPersonelService(personelServiceData.personelservice);
        const newPersonelServiceId = personelServiceData.personelservice._id.toString();
        serviceRouteIds.push(newPersonelServiceId);
      } catch (err) {
        problems += result.name + " için transfer aracı oluşturulamadı.\n";
      }

      if (problems.length) {
        setErrorMessage(problems);
        setShowModal(true);
        setIsServicesCreating(false);
        return;
      }
    }

    //store.UpdateTransfers();

    await new Promise((resolve) => setTimeout(resolve, 7000));

    setServicesCreated(true);
    setIsServicesCreating(false);
    // onServicesCreated();
  };

  const getServiceName = () => {
    const transfertime = props.type === "100" ? props.shift.starttime : props.shift.stoptime;
    const strings_transferdate_month = props.shiftDate.split("-")[1];
    const strings_transferdate_day = props.shiftDate.split("-")[2];
    return (
      strings_transferdate_day + "." + strings_transferdate_month + "-" + transfertime + " --- " + (services.length + 1)
    );
  };

  const addServiceHandler = () => {
    const currentServiceId = Math.random().toString(36).substring(7);

    const newService = {
      name: getServiceName(),
      date: props.shiftDate,
      users: [],
      id: GenerateString(5),
      isShift: true,
      people: null,
      school: props.school.id,
      starttime: null,
      stoptime: null,
      type: props.type,
      drivers: ["61aee5393958380016e7db4b"],
    };
    setServices((prevServices) => {
      return [...prevServices, newService];
    });

    setResults((prevResults) => {
      return [...prevResults, { id: currentServiceId, ...newService }];
    });
  };

  const deleteServiceHandler = (serviceId) => {
    const selectedService = services.find((service) => service.id === serviceId);

    if (selectedService?.users.length > 0) {
      alert("Bu serviste yolcu bulunmaktadır. Silmeden önce yolcuları kaldırmanız gerekmektedir.");
    } else {
      setServices((prevServices) => {
        return prevServices.filter((service) => service.id !== serviceId);
      });

      setResults((prevResults) => {
        return prevResults.filter((result) => result.id !== serviceId);
      });
    }
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <Modal
        show={showModal}
        onCancel={() => setShowModal(false)}
        header="Vardiya servisi."
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={() => setShowModal(false)}>
              Tamam
            </Button>
          </React.Fragment>
        }
      >
        <p>{errorMessage}</p>
      </Modal>

      <div style={{ width: "100%" }}>
        <div style={{ marginBottom: "20px" }}>
          {servicesCreated && (
            <div style={{ color: "red", fontWeight: "bold", marginBottom: "20px" }}>
              Bu vardiyanın servisleri oluşturulmuştur. Bu vardiya için değişikliklerinizi vardiya servisleri
              sekmesinden yapabilirsiniz.
            </div>
          )}
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div>
              <Button
                danger
                onClick={createTransfer}
                xs={{ alignSelf: "right" }}
                variant="contained"
                disabled={servicesCreated || isServicesCreating}
              >
                Servisleri Onayla
              </Button>
            </div>
            <div>{isServicesCreating && <LoadingSpinner />}</div>
          </div>
        </div>
        {services.map((service) => (
          <Service
            key={props.shift.id + "-" + service.name}
            school={props.school}
            id={service.id}
            name={service.name}
            destinationTime={props.shiftDate + " " + props.shift.starttime}
            users={service.users}
            driver={drivers.find((driver) => driver.id === service.drivers[0])}
            distances={props.distances}
            otherServices={services.filter((s) => s.id !== service.id)}
            onDrop={handleDrop}
            transferType={props.type}
            onDeletePerson={(userId) => deletePersonHandler(service.id, userId)}
            onChangeName={(newName) => changeServiceName(service.id, newName)}
            onPersonDown={(userId) => userDownHandler(service.id, userId)}
            onPersonUp={(userId) => userUpHandler(service.id, userId)}
            onOptimize={() => optimizeHandler(service.id)}
            onDeleteService={() => deleteServiceHandler(service.id)}
            onSaveResult={(calculatedPeople, driver, serviceName) =>
              saveResultHandler(service.id, calculatedPeople, driver, serviceName)
            }
          />
        ))}
        <div style={{ marginBottom: "20px" }}>
          <Button danger onClick={addServiceHandler} xs={{ alignSelf: "right" }} variant="contained">
            Vardiyaya yeni servis ekle
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ShiftDragger;
