import React, { useState } from "react";

import "./Marker.css";
import { yellow, blue } from "@mui/material/colors";

const Marker = (props) => {
  const MarkerIcon = props.Icon;

  const [showMarkerInfo, setShowMarkerInfo] = useState(props.initialShow);

  let markerColor = { color: yellow[900] };

  if (props?.alwaysBlue) {
    markerColor = { color: blue[500] };
  }

  if (!props.alwaysShowMarker) {
    if (showMarkerInfo) {
      markerColor = { color: yellow[700] };
    } else {
      markerColor = { color: blue[500] };
    }
  }

  return (
    <React.Fragment>
      <MarkerIcon
        style={{
          textAlign: "center",
          transform: "translate(-50%, -50%)",
          position: "absolute",
        }}
        sx={markerColor}
        fontSize="large"
        // color= {markerColor}
        onClick={() => setShowMarkerInfo(!showMarkerInfo)}
      />
      {(showMarkerInfo || props.alwaysShowMarker) && (
        <div className="markerinfo">
          <p>{props.info}</p>
        </div>
      )}
    </React.Fragment>
  );
};

export default Marker;
