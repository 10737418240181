import React, { useEffect } from "react";
import ReactSlider from "react-slider";

import "./slider2.css";

const Slider2 = (props) => {
  const [value, setValue] = React.useState();

  useEffect(() => {
    setValue(props.value);
    const sliderElement = document.getElementById("timeslider");

    if (sliderElement) {
      sliderElement.innerHTML = 30;
    }
  }, [props.value]);

  const onInputChangeHandler = (newIndex) => {
    // const newIndex = event.target.value;
    setValue(newIndex);
    props.onSliderValueChange(newIndex);
  };

  return (
    <ReactSlider
      onSliderClick={onInputChangeHandler}
      min={props.min}
      max={props.max}
      value={value}
      className="horizontal-slider"
      thumbClassName="example-thumb"
      trackClassName="example-track"
      renderTrack={(props, state) => <div {...props} />} //custom track
    />
  );
};

export default Slider2;
