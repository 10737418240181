import React, { useContext } from "react";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import DatePicker from "shared/components/FormElements/DatePicker";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";

// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./AccountingItem.css";

const ServiceTemplateNew = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const updateStoreItems = store.UpdateAccountingUtils;
  const ItemTypeTR = "Hizmet Şablonu";
  const APIEndPoint = "servicetemplates";

  const itemSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify({
          year: formState.inputs.year.value,
          name: formState.inputs.name.value,
          serviceStartDate: formState.inputs.serviceStartDate.value,
          serviceEndingDate: formState.inputs.serviceEndingDate.value,
          amount: formState.inputs.amount.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      updateStoreItems();
    } catch (err) {
      console.log(err);
    }
  };

  const [formState, inputHandler] = useForm(
    {
      year: { year: "", isValid: false },
      name: { value: "", isValid: false },
      serviceStartDate: { value: "", isValid: false },
      serviceEndingDate: { value: "", isValid: false },
      amount: { value: null, isValid: false },
    },
    false
  );

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <form className="new-item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <h3>{ItemTypeTR} Ekle</h3>

        <CustomSelect
          options={store.educationYearsOptions}
          id="year"
          onInput={inputHandler}
          label="Eğitim-Öğretim Yılı"
          initialValid={false}
        ></CustomSelect>

        <Input
          id="name"
          element="input"
          type="text"
          label="Şablon İsmi"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Şablon ismi gitiniz."
          initialValid={false}
          onInput={inputHandler}
        />

        <DatePicker
          id="serviceStartDate"
          onInput={inputHandler}
          label="Hizmet Başlangıç Tarihi"
          initialValid={false}
        ></DatePicker>

        <DatePicker
          id="serviceEndingDate"
          onInput={inputHandler}
          label="Hizmet Bitiş Tarihi"
          validators={[VALIDATOR_REQUIRE()]}
          initialValid={false}
        ></DatePicker>

        <Input
          id="amount"
          element="input"
          type="text"
          label="Toplam Ücret"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Hizmet bedelinin standart ücretini giriniz."
          initialValid={false}
          onInput={inputHandler}
        />

        <Button type="submit" disabled={!formState.isValid}>
          Yeni {ItemTypeTR} Yarat
        </Button>
      </form>
    </React.Fragment>
  );
};

export default ServiceTemplateNew;
