import React, { useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
//import { styled } from "@mui/material/styles";

// #region Components
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./AccountingItem.css";

import "react-datepicker/dist/react-datepicker.css";

import ReportVehicleList from "./ReportVehicleList";

const ReportVehicles = (props) => {
  const store = useContext(StoreContext);
  const [reportCreated, setReportCreated] = useState(false);
  const [vehicleResults, setVehicleResults] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {});

  const [formState, inputHandler] = useForm(
    {
      year: { value: null, isValid: false },
      school: { value: "", isValid: false },
    },
    false
  );

  const createReport = async () => {
    setIsLoading(true);
    let contracts = [];
    let vehiclePayments = [];
    let currentSchoolId = formState.inputs.school.value.toString();
    let currentYearId = formState.inputs.year.value.toString();

    if (currentSchoolId === "619c62eb5d123200169418a8") {
      contracts = store.contracts;
      vehiclePayments = store.vehiclePayments;
    } else {
      contracts = store.contracts;
      vehiclePayments = store.vehiclePayments.filter(
        (vehiclePayment) => vehiclePayment.school.toString() === currentSchoolId
      );
    }

    let vehicleAnalysis = [];

    vehiclePayments.forEach((vehiclePayment) => {
      try {
        const vehicleRoute = store.vehicleRoutes.find(
          (route) => route.id.toString() === vehiclePayment.vehicleRoute.toString()
        );
        const routeStudentIds = vehicleRoute.student.map((item) => item.id.toString());

        let currentVehicleTotalAmount = 0;

        for (let i = 0; i < routeStudentIds.length; i++) {
          const studentId = routeStudentIds[i];

          const currentContract = contracts.find(
            (contract) =>
              contract?.student?._id.toString() === studentId.toString() &&
              contract?.year.toString() === currentYearId.toString() &&
              contract.contractEnabled === true
          );
          let currentMonthlyPayment = 0;

          if (currentContract) {
            currentMonthlyPayment = currentContract.finalAmount / 10;
          }

          currentVehicleTotalAmount = currentVehicleTotalAmount + currentMonthlyPayment;
        }

        vehicleAnalysis.push({
          id: vehicleRoute.id,
          route: vehicleRoute,
          vehiclePayment: vehiclePayment,
          studentCount: routeStudentIds.length,
          totalAmount: parseInt(currentVehicleTotalAmount),
        });
      } catch {}
    });

    setVehicleResults(vehicleAnalysis);
    setReportCreated(true);
    setIsLoading(false);

    // const totalReceipt = currentReceipts
    //   .filter(
    //     (receipt) =>
    //       new Date(receipt.date) >= startDate &&
    //       new Date(receipt.date) <= endDate
    //   )
    //   .reduce((acc, receipt) => {
    //     return acc + receipt.amount;
    //   }, 0);
    // setTotalReceipt(totalReceipt);
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  // const Item = styled(Paper)(({ theme }) => ({
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   minHeight: 80,
  //   alignItems: "center",
  //   justifyContent: "center",
  //   color: theme.palette.text.secondary,
  // }));

  return (
    <React.Fragment>
      <form>
        <Grid
          sx={{
            fontsize: 16,
            flexGrow: 1,
            display: "flex",
            flexDirection: { xs: "row", md: "row" },
            alignItems: "center",
            justifyContent: "flex-start",
            textAlign: "left",
            width: "100%",
            id: "report-receipts-container",
          }}
          container
          spacing={1}
        >
          <Grid item xs={4}>
            <CustomSelect
              options={store.educationYearsOptions}
              id="year"
              onInput={inputHandler}
              label="Yıl"
              errorText="Yıl seçimi yapınız."
              initialValid={true}
              initialValue={store.educationYearsOptions[2]}
              validators={[VALIDATOR_REQUIRE()]}
            ></CustomSelect>
          </Grid>

          <Grid item xs={4}>
            <CustomSelect
              options={store.schoolOptions}
              id="school"
              onInput={inputHandler}
              label="Okul"
              errorText="Okul seçimini yapınız."
              initialValid={false}
              validators={[VALIDATOR_REQUIRE()]}
            ></CustomSelect>
          </Grid>

          <Grid item xs={3}>
            <Button type="button" disabled={!formState.isValid} onClick={createReport}>
              Rapor Oluştur
            </Button>
          </Grid>
        </Grid>
      </form>
      {reportCreated && (
        <div>
          <br />
          <div className="adminpageContainer">
            {vehicleResults && (
              <div className="adminListContainer">
                <ReportVehicleList
                  items={vehicleResults}
                  // onItemSelect={ItemSelectHandler}
                />
              </div>
            )}
            {/* {selectedItemId && selectedItemShow && (
            <div className="adminUpdateContainer">
              <ReportVehicleList
                itemid={selectedItemId}
                onItemUpdate={ItemUpdateHandler}
                onClose={() => setSelectedItemShow(false)}
              ></ReportVehicleList>
            </div>
          )} */}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ReportVehicles;
