import React, { useContext } from "react";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import DatePicker from "shared/components/FormElements/DatePicker";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";

// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";
import { paymentMethods, banks, isOfficial, paymentTypes, expenseTypes } from "shared/util/types";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./AccountingItem.css";

const ExpenseNew = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const updateStoreItems = store.UpdateExpenses;
  const ItemTypeTR = "Gider";
  const APIEndPoint = "expenses";

  const itemSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify({
          school: formState.inputs.school.value,
          date: formState.inputs.date.value,
          type: formState.inputs.type.value,
          description: formState.inputs.description.value,
          vehicle: formState.inputs.vehicle.value,
          person: formState.inputs.person.value,
          paymentMethod: formState.inputs.paymentMethod.value,
          paymentType: formState.inputs.paymentType.value,
          amount: formState.inputs.amount.value,
          bank: formState.inputs.bank.value,
          receiptNo: formState.inputs.receiptNo.value,
          official: formState.inputs.official.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      updateStoreItems();
      // history.push(`/${ItemEndPointType}`);
    } catch (err) {
      console.log(err);
    }
  };

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: false },
      date: { value: "", isValid: false },
      type: { value: "", isValid: false },
      description: { value: "", isValid: false },
      vehicle: { value: "", isValid: false },
      person: { value: "", isValid: false },
      paymentMethod: { value: "", isValid: false },
      paymentType: { value: "", isValid: false },
      amount: { value: null, isValid: false },
      bank: { value: null, isValid: false },
      receiptNo: { value: null, isValid: false },
      official: { value: null, isValid: false },
    },
    false
  );

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <form className="new-item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <h3>{ItemTypeTR} Ekle</h3>

        <CustomSelect
          options={store.schoolOptions}
          id="school"
          onInput={inputHandler}
          label="Kurum*"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Zorunlu alan"
        ></CustomSelect>

        <DatePicker
          id="date"
          onInput={inputHandler}
          label="Fatura Tarihi"
          initialValid={false}
          errorText="Gider tarihini giriniz."
          validators={[VALIDATOR_REQUIRE()]}
        ></DatePicker>

        <CustomSelect
          options={expenseTypes}
          id="type"
          onInput={inputHandler}
          label="Gider Türü"
          errorText="Gider türünü giriniz."
          initialValid={false}
          validators={[VALIDATOR_REQUIRE()]}
        ></CustomSelect>

        <Input
          id="description"
          element="input"
          type="text"
          label="Açıklama"
          validators={[VALIDATOR_NONE()]}
          errorText="Açıklama giriniz."
          initialValid={true}
          onInput={inputHandler}
        />

        <Input
          id="vehicle"
          element="input"
          type="text"
          label="Araç plakası (Araca yönelik bir ödeme ise)"
          validators={[VALIDATOR_NONE()]}
          initialValid={true}
          onInput={inputHandler}
        />

        <Input
          id="person"
          element="input"
          type="text"
          label="Kurum-Kişi Adı"
          validators={[VALIDATOR_NONE()]}
          initialValid={true}
          onInput={inputHandler}
        />

        <CustomSelect
          options={paymentMethods}
          id="paymentMethod"
          onInput={inputHandler}
          label="Ödeme Yöntemi"
          validators={[VALIDATOR_REQUIRE()]}
          initialValid={false}
        ></CustomSelect>

        <CustomSelect
          options={paymentTypes}
          id="paymentType"
          onInput={inputHandler}
          label="Ödeme Şekli"
          validators={[VALIDATOR_REQUIRE()]}
          initialValid={false}
        ></CustomSelect>

        <CustomSelect
          options={banks}
          id="bank"
          onInput={inputHandler}
          label="Ödemeyi hanga banka hesabınızdan yaptınız. (Banka ödemesi)"
          validators={[VALIDATOR_NONE()]}
          initialValid={true}
        ></CustomSelect>

        <Input
          id="receiptNo"
          element="input"
          type="text"
          label="Fatura Numarası"
          validators={[VALIDATOR_NONE()]}
          errorText="Gider karşılık alınan faturasını giriniz."
          initialValid={true}
          onInput={inputHandler}
        />

        <CustomSelect
          options={isOfficial}
          id="official"
          onInput={inputHandler}
          label="Fatura yazılımına (Logo) işlensin mi?"
          initialValid={false}
          validators={[VALIDATOR_REQUIRE()]}
          initialValue={isOfficial[0]}
        ></CustomSelect>

        <Input
          id="amount"
          element="input"
          type="text"
          label="Ödeme Tutarı"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Ödeme tutarını giriniz."
          initialValid={false}
          onInput={inputHandler}
        />

        <Button type="submit" disabled={!formState.isValid}>
          Yeni {ItemTypeTR} Yarat
        </Button>
      </form>
    </React.Fragment>
  );
};

export default ExpenseNew;
