import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
// #endregion Components

// #region Utils
import { generatePhoneNumber } from "shared/util/util";
import { VALIDATOR_REQUIRE, VALIDATOR_PHONENUMBER } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const NewCompanyItem = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();

  const updateStoreItems = store.UpdateCompanies;
  const ItemTypeTR = "Servis Firması";
  const ItemEndPointType = "companies";
  const APIEndPoint = "companies";

  const itemSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify({
          name: formState.inputs.name.value,
          phonenumber: formState.inputs.phonenumber.value,
          address: formState.inputs.address.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      updateStoreItems();
      navigate(`/${ItemEndPointType}`);
    } catch (err) {}
  };

  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: false },
      phonenumber: { value: "", isValid: false },
      address: { value: null, isValid: false },
    },
    false
  );

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <form className="new-item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <h3>{ItemTypeTR} Ekle</h3>
        <Input
          id="name"
          element="input"
          type="text"
          label="İsim"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="İsim giriniz."
          onInput={inputHandler}
        />
        <Input
          id="phonenumber"
          element="input"
          type="text"
          label="Telefon"
          validators={[VALIDATOR_PHONENUMBER(10)]}
          initialValue={generatePhoneNumber()}
          initialValid={true}
          errorText="Telefon giriniz."
          onInput={inputHandler}
        />
        <Input
          id="address"
          element="input"
          type="text"
          label="Adres"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Adres giriniz."
          onInput={inputHandler}
        />
        <Button type="submit" disabled={!formState.isValid}>
          Yeni {ItemTypeTR} Yarat
        </Button>
      </form>
    </React.Fragment>
  );
};

export default NewCompanyItem;
