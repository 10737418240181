import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { ThemeProvider } from "@mui/material/styles";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

const ShiftsList = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { schoolOptions } = useContext(StoreContext);

  const visibilityJSON = localStorage.getItem("stops_visibility");
  const visibilityModel = visibilityJSON ? JSON.parse(visibilityJSON) : {};
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(visibilityModel);
  const filterJSON = localStorage.getItem("stops_filter");
  const initialFilterModel = filterJSON ? JSON.parse(filterJSON) : { items: [] };
  const [filterModel, setFilterModel] = useState(initialFilterModel);

  const columns = [
    { field: "name", headerName: t("name"), width: 300 },
    { field: "address", headerName: t("address"), width: 600 },
    {
      field: "school",
      headerName: t("client"),
      width: 300,
      valueGetter: (params) =>
        schoolOptions.find((school) => school?.value?.toString() === params.row?.school?.toString())?.label?.toString(),
    },
  ];

  const OnSelectionChangeHandler = (event) => {
    props.onItemSelect(event[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="itemListContainer">
      <ThemeProvider theme={auth.theme}>
        <DataGrid
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            localStorage.setItem("stops_visibility", JSON.stringify(newModel));
            setColumnVisibilityModel(newModel);
          }}
          filterModel={filterModel}
          onFilterModelChange={(newModel) => {
            localStorage.setItem("stops_filter", JSON.stringify(newModel));
            setFilterModel(newModel);
          }}
          key={props.items}
          density="compact"
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={props.items}
          columns={columns}
          pageSize={100}
          getRowId={(r) => r.id}
          onSelectionModelChange={OnSelectionChangeHandler}
          selectionModel={props?.selectedId ? [props.selectedId] : []}
          paginationModel={"server"}
          keepNonExistentRowsSelected
        />
      </ThemeProvider>
    </div>
  );
};

export default ShiftsList;
