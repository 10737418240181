import React from "react";
import Card from "./Card";
import { useTranslation } from "react-i18next";

const NotFound = (props) => {
  const { t } = useTranslation();
  return (
    <div className="center">
      <Card>
        {props.item && (
          <h2>
            {t(`${props.item}.title`)} {t("notFound")}.
          </h2>
        )}
        {!props.item && <h2>{` ${t("itemNotFound")}.`}</h2>}
      </Card>
    </div>
  );
};

export default NotFound;
