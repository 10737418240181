import React, { useState, useContext, useEffect, useRef } from "react";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";

import Grid from "@mui/material/Grid";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import { useForm } from "shared/hooks/form-hook";
import { AuthContext } from "shared/context/auth-context";
import { VALIDATOR_REQUIRE } from "shared/util/validators";
import "./Admin.css";
import { getStudentsWithLocationProblems } from "./utils";
const DistributeStudents = (props) => {
  const store = useContext(StoreContext);
  const auth = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();
  const [reportAsked, setReportAsked] = useState(false);
  const [hasProblematicLocations, setHasProblematicLocations] = useState(false);
  const processIdRef = useRef(null);
  const [processId, setProcessId] = useState(null);
  const isProcessingRef = useRef(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const isFetchingRef = useRef(false);

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: false },
    },
    false
  );

  useEffect(() => {
    const fetchProcessState = async () => {
      if (!processIdRef.current || !isProcessingRef.current || isFetchingRef.current) {
        return;
      }
      isFetchingRef.current = true;
      try {
        const resp = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/process/${processIdRef.current}`,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );
        isProcessingRef.current = resp.process.processing;
        setIsProcessing(resp.process.processing);
      } catch (err) {}
      isFetchingRef.current = false;
    };
    fetchProcessState();

    const intervalId = setInterval(fetchProcessState, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [processId, sendRequest, auth.token]);

  const createReport = async () => {
    const isAdminMode = true;

    let allActiveStudents = store.students
      .filter((s) => !s.hasOwnProperty("isActive") || s?.isActive)
      .sort((a, b) => (a.name > b.name ? 1 : -1));

    let activeStudentsInSchool = allActiveStudents.filter(
      (s) => s.school.toString() === formState.inputs.school.value.toString()
    );

    const problematicStudents = getStudentsWithLocationProblems(activeStudentsInSchool);

    if (isAdminMode) {
      const problematicStudentsIds = problematicStudents.map((s) => s.id);
      activeStudentsInSchool = activeStudentsInSchool.filter((s) => !problematicStudentsIds.includes(s.id));
    } else {
      if (problematicStudents.length > 0) {
        setHasProblematicLocations(true);
        setReportAsked(true);
        return;
      }
    }

    let distributionProcessId = null;
    try {
      const resp = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/distributioninit`,
        "POST",
        JSON.stringify({
          school: formState.inputs.school.value,
          students: activeStudentsInSchool.map((s) => s.id),
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      distributionProcessId = resp?.process?.id;
    } catch (err) {}

    if (!distributionProcessId) {
      return;
    }

    isProcessingRef.current = true;
    setIsProcessing(true);
    processIdRef.current = distributionProcessId;
    setProcessId(distributionProcessId);
    setReportAsked(true);
  };

  // if(isLoading || !store.vehicleRoutes) //HERE
  if (isLoading && false) {
    //HERE
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <form>
        <Grid
          sx={{
            fontsize: 16,
            flexGrow: 1,
            display: "flex",
            flexDirection: { xs: "row", md: "row" },
            alignItems: "center",
            justifyContent: "flex-start",
            textAlign: "left",
            width: "100%",
            id: "report-receipts-container",
          }}
          container
          spacing={1}
        >
          <Grid item xs={4}>
            <CustomSelect
              options={store.schoolOptions}
              id="school"
              onInput={inputHandler}
              label="Kurum"
              errorText="Kurum seçimini yapınız."
              initialValid={false}
              validators={[VALIDATOR_REQUIRE()]}
            ></CustomSelect>
          </Grid>

          <Grid item xs={3}>
            <div style={{ left: "10px", position: "relative", top: "15px" }}>
              <Button type="button" disabled={!formState.isValid || isProcessing} onClick={createReport}>
                Öğrencileri servislere dağıt
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
      {reportAsked && (
        <div>
          <br />
          <div className="adminpageContainer">
            <div className="adminListContainer">
              {hasProblematicLocations && (
                <div>
                  <div style={{ display: "flex" }}>
                    Öğrenci konumlarınızda eksikler bulunuyor. Lütfen eksik veriler sekmesini kontrol ediniz.
                  </div>
                </div>
              )}

              {!hasProblematicLocations && isProcessing && (
                <div style={{ flexDirection: "column", display: "flex", alignItems: "center" }}>
                  <LoadingSpinner />
                  <br /> İşleminiz başlatılmıştır ve 1 saatten fazla sürecektir.
                  <br /> Bu pencereyi kapatabilirsiniz, hesaplama işlemi sunucular üzerinde devam edecektir.
                </div>
              )}

              {!hasProblematicLocations && !isProcessing && (
                <div>
                  <div className="center">İşleminiz sonuçlandı.</div>
                </div>
              )}

              {/* {!hasProblematicLocations && (
                <div>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {studentsWithLocationProblems.length > 0 && (
                        <div>
                          Konumu bulunmayan öğrenciler - <strong> {studentsWithLocationProblems.length} hata </strong>
                        </div>
                      )}

                      {studentsWithLocationProblems.length === 0 && (
                        <div style={{ display: "flex" }}>
                          <strong style={{ marginRight: "20px" }}>
                            {" "}
                            <ThumbUp />
                          </strong>{" "}
                          Tebrikler. Tüm öğrencilerinizin konumu işaretlenmiş durumda.
                        </div>
                      )}
                    </AccordionSummary>
                    {studentsWithLocationProblems.length > 0 && (
                      <AccordionDetails display="flex">
                        <Students students={studentsWithLocationProblems} />
                      </AccordionDetails>
                    )}
                  </Accordion>

              
                </div>
              )} */}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DistributeStudents;
