import React, { useEffect, useState, useContext, useCallback, useRef } from "react";

import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import SchoolIcon from "@mui/icons-material/School";

import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";

import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import Marker from "shared/components/UIElements/Marker";

import { AIRPORT_ID, AIRPORT_LAT, AIRPORT_LNG } from "shared/util/location";
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";
import { GetStartStopTime } from "shared/util/time";

const ServiceItem = (props) => {
  const auth = useContext(AuthContext);
  const { storeReady, driverOptions, UpdatePersonelService, DeletePersonelService } = useContext(StoreContext);

  const [airportTime, setAirportTime] = useState("");

  const [showMap, setShowMap] = useState(false);

  const ItemTypeTR = "Servis";
  const ItemType = "personelservice";
  const ItemEndPointType = "personelservices";

  // #region Standard Interface functions
  const { error, sendRequest, clearError } = useHttpClient();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  // #endregion Standard Interface functions

  // #region Standard API calls

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    props.deleteService(props.service.id);
  };
  // #endregion Standard API calls

  useEffect(() => {
    // fetchItem();
  }, []);

  const [formStateItem, inputHandlerItem] = useForm(
    {
      name: { value: "", isValid: true },
      drivers: { value: [], isValid: true },
      plate: { value: "", isValid: true },
      starttime: { value: "", isValid: true },
      stoptime: { value: "", isValid: true },
    },
    true
  );

  const itemUpdateSubmitHandler = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();

      let responseData;
      try {
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${props.service.id}`,
          "PATCH",
          JSON.stringify({
            name: formStateItem.inputs.name.value,
            drivers: formStateItem.inputs.drivers.value,
            plate: formStateItem.inputs.plate.value,
            starttime: formStateItem.inputs.starttime.value,
            stoptime: formStateItem.inputs.stoptime.value,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        UpdatePersonelService(responseData[ItemType]);
      } catch (err) {}
    },
    [formStateItem, sendRequest, auth.token, UpdatePersonelService, props.service.id]
  );

  const toggleShowMap = () => {
    setShowMap(!showMap);
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Emin misiniz?"
        footerClass="place-item__modal-actions"
        footer={
          <div className="modalbuttonscontainer">
            <Button inverse onClick={cancelDeleteHandler}>
              Hayır
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Evet
            </Button>
          </div>
        }
      >
        <p>{ItemTypeTR} silme işlemini onaylıyor musunuz? Bu işlem geri alınamaz.</p>
      </Modal>

      <form key={props.service.id} className="item-form-route" onSubmit={itemUpdateSubmitHandler}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div className="transferRouteItemStyle transferRouteItemButton" style={{ width: "5%" }}>
              <Button type="button" danger onClick={showDeleteWarningHandler}>
                SİL
              </Button>
            </div>
            <div className="transferRouteItemStyle">
              <Input
                id="name"
                element="input"
                type="text"
                label="Servis ismi"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="İsim giriniz."
                initialValue={props.service.name}
                initialValid={true}
                onInput={inputHandlerItem}
              />
            </div>

            <div className="transferRouteItemStyle">
              <CustomSelect
                options={driverOptions}
                id="drivers"
                isMulti={true}
                onInput={inputHandlerItem}
                label="Servise yetkisi olan sürücüler"
                initialValue={driverOptions.filter((option) => props.service?.drivers?.includes(option.value))}
                initialValid={true}
              ></CustomSelect>
            </div>

            <div className="transferRouteItemStyle" style={{ width: "5%" }}>
              <Input
                id="starttime"
                element="input"
                type="text"
                label="Başlama"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                onInput={inputHandlerItem}
                initialValue={GetStartStopTime(props.service.starttime)}
                initialValid={true}
              />
            </div>
            <div className="transferRouteItemStyle" style={{ width: "5%" }}>
              <Input
                id="stoptime"
                element="input"
                type="text"
                label="Bitiş"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                onInput={inputHandlerItem}
                initialValue={GetStartStopTime(props.service.stoptime)}
                initialValid={true}
              />
            </div>

            <div className="transferRouteItemStyle" style={{ width: "5%" }}>
              <Input
                id="plate"
                element="input"
                type="text"
                label="Plaka"
                validators={[VALIDATOR_NONE()]}
                errorText="Zorunlu alan"
                onInput={inputHandlerItem}
                initialValue={props.service.plate}
                initialValid={true}
              />
            </div>

            <div className="transferRouteItemStyle transferRouteItemButton" style={{ width: "10%" }}>
              <Button type="button" danger o onClick={toggleShowMap}>
                {showMap ? "Haritayı Gizle" : "Haritayı Göster"}
              </Button>
            </div>
            <div className="transferRouteItemStyle transferRouteItemButton" style={{ width: "10%" }}>
              <Button type="submit" disabled={!formStateItem.isValid}>
                Güncelle
              </Button>
            </div>
          </div>

          {showMap && (
            <React.Fragment>
              {/* <Button type="button" danger o onClick={updateCurrentStudents}>
                  Haritayı Güncelle
                </Button> */}

              <div className="map-container">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
                    language: "tr",
                    region: "tr",
                    libraries: ["drawing"],
                  }}
                  options={function (maps) {
                    return {
                      mapTypeControl: true,
                      mapTypeId: "roadmap",
                      mapTypeControlOptions: {
                        mapTypeIds: ["satellite", "roadmap"],
                        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: maps.ControlPosition.TOP_LEFT,
                      },
                    };
                  }}
                  //defaultCenter={markerLocationMorning}
                  center={
                    props.users[0]
                      ? props.users[0].location
                      : { lat: props.school.location.lat, lng: props.school.location.lng }
                  }
                  defaultZoom={14}
                >
                  {props.users.map((user) => {
                    return (
                      <Marker
                        Icon={RoomIcon}
                        lat={user.location.lat}
                        lng={user.location.lng}
                        key={user.id}
                        // info={user.name}
                        info={
                          user.name +
                          " - " +
                          (props.service?.usersdetails?.find((userdetail) => userdetail?.user == user.id)
                            ?.transfertime || "")
                        }
                        initialShow={true}
                      />
                    );
                  })}
                  <Marker
                    Icon={SchoolIcon}
                    lat={props.school.location.lat}
                    lng={props.school.location.lng}
                    info={props.school.name}
                    alwaysShowMarker={true}
                  />
                </GoogleMapReact>
              </div>
            </React.Fragment>
          )}
        </div>
      </form>
    </React.Fragment>
  );
};

export default ServiceItem;
