import React, { useCallback, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import ErrorModal from "shared/components/UIElements/ErrorModal";
import Help from "./Help";
import List from "./List";
import Button from "shared/components/FormElements/Button";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { StoreContext } from "shared/context/store-context";
import "./Layout.css";

const Layout = ({
  itemType,
  SelectedItemComponent,
  NewItemComponent,
  items,
  listColumns,
  standardHelpItems,
  highlightedHelpItems,
}) => {
  const { t } = useTranslation();
  const { storeReady } = useContext(StoreContext);
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemShow, setSelectedItemShow] = useState(false);
  const [newItemShow, setNewItemShow] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const ItemSelectHandler = useCallback((itemid) => {
    setSelectedItemShow(true);
    setNewItemShow(false);
    setSelectedItemId(itemid);
  }, []);

  const ItemUpdateHandler = useCallback((itemid) => {
    setNewItemShow(false);
    setSelectedItemId(itemid);
  }, []);

  const newItemHandler = useCallback(() => {
    setSelectedItemShow(false);
    setNewItemShow(true);
  }, []);

  const closeNewItemHandler = useCallback(() => {
    setNewItemShow(false);
    setSelectedItemShow(false);
    setSelectedItemId(null);
    setIsFullScreen(false);
  }, []);

  const closeSelectedItemHandler = useCallback(() => {
    setSelectedItemShow(false);
    setSelectedItemId(null);
    setIsFullScreen(false);
  }, []);

  if (!storeReady) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <React.Fragment>
      {/* <ErrorModal error={error} onClear={clearError} /> */}
      <div className="layout-navigation">
        <Button danger size="small" onClick={newItemHandler}>
          {t(`${itemType}.createNewItem`)}
        </Button>
        {(standardHelpItems || highlightedHelpItems) && (
          <Help standardHelpItems={standardHelpItems} highlightedHelpItems={highlightedHelpItems} />
        )}
      </div>
      <div className="layout-container">
        {items && (
          <div className="layout-list-container" style={{ display: isFullScreen ? "none" : "flex" }}>
            <List
              name={itemType}
              items={items}
              columns={listColumns}
              onItemSelect={ItemSelectHandler}
              selectedId={selectedItemId}
            />
          </div>
        )}
        {((selectedItemId && selectedItemShow) || newItemShow) && (
          <div className={"layout-selected-item-container"}>
            {selectedItemId && selectedItemShow && (
              <SelectedItemComponent
                itemid={selectedItemId}
                onItemUpdate={ItemUpdateHandler}
                onClose={closeSelectedItemHandler}
                onFullScreen={() => setIsFullScreen(true)}
                onHalfScreen={() => setIsFullScreen(false)}
                isFullScreen={isFullScreen}
              ></SelectedItemComponent>
            )}
            {newItemShow && (
              <NewItemComponent
                gotoItem={ItemSelectHandler}
                onClose={closeNewItemHandler}
                onFullScreen={() => setIsFullScreen(true)}
                onHalfScreen={() => setIsFullScreen(false)}
              ></NewItemComponent>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Layout;
